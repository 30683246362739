import { createAction } from '@reduxjs/toolkit';

import {
  CLOSE_TICKETLIST_TAB,
  SET_TICKETLIST_ACCORDION,
  CLEAR_TICKETLIST_FILTER,
  SET_TICKETLIST_FILTER,
  CLOSE_INFOPAGELIST_TAB,
  SET_TICKETLIST_SORTING
} from '.';
import type { IFilterItem } from '../types/Filter';
import type { Direction, SortBy } from '../types/Sorting';
import type { ContentTypesFields } from '../types/Ticket';

export const closeTicketlistTab = createAction<string>(CLOSE_TICKETLIST_TAB);

export const closeInfopagelistTab = createAction<string>(CLOSE_INFOPAGELIST_TAB);

export const setTicketlistAccordion = createAction<{
  id: string;
  accordionIndex: number | null;
}>(SET_TICKETLIST_ACCORDION);

export const clearTicketlistFilter = createAction<string>(CLEAR_TICKETLIST_FILTER);

export const setTicketlistFilter = createAction<IFilterItem>(SET_TICKETLIST_FILTER);

export function closeContentListTab(id: string, type: ContentTypesFields) {
  return { tickets: closeTicketlistTab(id), infopages: closeInfopagelistTab(id) }[type] ?? closeTicketlistTab(id);
}

const prepareSortingPayload = (sorting: SortBy, direction: Direction) => ({ payload: { sorting, direction } });

export const setTicketlistSorting = createAction(SET_TICKETLIST_SORTING, prepareSortingPayload);

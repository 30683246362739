import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SET_SETTINGS_TAB } from '../actions/index';

export const setActiveSettingsTabIndex = (key: string) => {
  return { type: SET_SETTINGS_TAB, payload: { key: key } };
};

export const setActiveSettingsTab: ActionCreator<ThunkAction<any, any, any, Action<any>>> = (key: string) => {
  return (dispatch: Dispatch) => {
    return dispatch(setActiveSettingsTabIndex(key));
  };
};

import { apiClient } from 'src/Utilities/httpClients';
import type { Tag } from 'src/types/Tag';

export default class TagsApi {
  static getTags = async (): Promise<Tag[]> => {
    return apiClient.get('/tags').then((response) => {
      return response.data;
    });
  };
  static patchTag = async (data: { id: string; data: Partial<Tag> }): Promise<any> => {
    return apiClient.patch('/tag/' + data.id, data.data).then((response) => {
      return response.data;
    });
  };
  static deleteTag = async (id: string): Promise<any> => {
    return apiClient
      .delete('/tag/' + id, {
        data: {
          _id: id
        }
      })
      .then((response) => response.data);
  };
  static createTag = async (data: any): Promise<any> => {
    return apiClient.post('/tags/', data).then((response) => {
      return response.data;
    });
  };
}

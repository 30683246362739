import React from 'react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import type { FC } from 'react';

import UserAvatar from './UserAvatar';
import UserPopup from './UserPopup';
import { getProfileName, getUserById } from './utils';
import type { UserAvatarSize } from './UserAvatar';
import type { State } from 'src/types/initialState';

import './UserLabel.css';

const DEFAULT_SIZE = '26';

interface UserLabelProps {
  UID: number | string;
  showTitle?: boolean;
  style?: object;
  size?: UserAvatarSize;
}

const UserLabel: FC<UserLabelProps> = ({ UID, showTitle = true, size = DEFAULT_SIZE, children }) => {
  const users = useSelector((state: State) => state.usersList.usersList);
  const user = getUserById(users, UID);
  const name = getProfileName(user);

  if (!user) {
    return <span>{t('ERROR_HAPPENED')}</span>;
  }

  return (
    <UserPopup UID={UID}>
      <div
        className={`user-label ${showTitle ? 'ui label' : 'user-label__rounded'}`}
        data-test-id={name + '_label'}
        key={UID}
      >
        <UserAvatar user={user} size={size} />
        {showTitle && <span>{name}</span>}
        {children}
      </div>
    </UserPopup>
  );
};

export default UserLabel;

import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import type { VFC } from 'react';

import FoldedWindow from './FoldedWindow';
import SuggestedArticleWindow from './SuggestedArticleWindow';
import type { State } from 'src/types/initialState';
import type { FloatingWindowsState } from 'src/reducers/floatingWindowsReducer';

import * as styles from './FloatingWindows.style';
import TicketWindow from './TicketWindow/TicketWindow';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FloatingWindowsProps {}

const FloatingWindows: VFC<FloatingWindowsProps> = () => {
  const windows = useSelector<State, FloatingWindowsState>((state) => state.windows);
  const unfolded = useMemo(() => windows.filter((w) => !w.fold), [windows]);

  return (
    <>
      {unfolded
        .filter((w) => w.type === 'infopage')
        .map((win, index) => (
          <SuggestedArticleWindow key={`suggested-article-window-${win.id}`} index={index} window={win} />
        ))}
      {unfolded
        .filter((w) => w.type === 'task')
        .map((win, index) => (
          <TicketWindow key={`ticket-window-${win.id}`} index={index} window={win} />
        ))}
      <div style={styles.foldedWrapper}>
        {windows
          .filter((w) => w.fold)
          .reverse()
          .map((win) => (
            <FoldedWindow key={`folded-floating-window-${win.id}`} window={win} />
          ))}
      </div>
    </>
  );
};

export default React.memo(FloatingWindows);

import iziToast from 'izitoast';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Header, Icon, List, Message } from 'semantic-ui-react';

import AccordionHeader from '../../AccordionHeader';
import SingleEIdentification from './SingleEIdentification';
import { addComment } from 'src/actions/commentsActions';
import { switchDraftTabIndex, updateTicketTabState } from 'src/actions/draftActions';
import {
  fetchEIdentifications,
  generateEIdentificationLink,
  getEIdentificationLink,
  updateEIdentification as updateEIdentificationAction
} from 'src/actions/eIdentificationActions';
import { useAppThunkDispatch } from 'src/store';
import { getChannelTypeKey } from 'src/types/Channel';
import { onResponseTemplatePrompt } from 'src/Utilities/replyContent';

import type ChannelType from 'src/Components/CommentIconContent/ChannelType';
import type { State } from 'src/types/initialState';
import type { Ticket } from 'src/types/Ticket';

interface EIdentificationProps {
  task: Ticket;
  contentDetails: { [key: string]: any };
}

export default ({ task, contentDetails }: EIdentificationProps) => {
  const { t } = useTranslation();

  const contentId = parseInt(task.id.substring(3), 10);
  /**
   * Default dispatch without typing
   * Should be here before typing refactoring for commentActions.ts
   */
  const defaultDispatch = useDispatch();
  const dispatch = useAppThunkDispatch();
  const [loading, setLoading] = React.useState<boolean>();
  const currentUserProfile = useSelector((state: State) => state.userData.profile);
  const eIdentifications = useSelector((state: State) => state.eIdentifications[task.id.substring(3)]);
  const drafts = useSelector((state: State) => state.drafts[task.id]);
  const eIdentificationUpdateInProgress = useSelector((state: State) =>
    state.ajaxCallsInProgress.find(
      (ajaxCall) => ajaxCall.name === 'UPDATE_EIDENTIFICATIONS' && Number(ajaxCall.id) === contentId
    )
  );
  const eIdentificationFetchInProgress = useSelector((state: State) =>
    state.ajaxCallsInProgress.find(
      (ajaxCall) => ajaxCall.name === 'FETCH_EIDENTIFICATIONS' && Number(ajaxCall.id) === contentId
    )
  );

  const generateAndCopyLink = async (contentId: number) => {
    try {
      const { payload } = await dispatch(generateEIdentificationLink({ contentId }));
      navigator.clipboard.writeText((payload as { link: string }).link);
      iziToast.info({
        message: t('widgets.eIdentification.notifications.copy_link')
      });
    } catch (error) {
      iziToast.error({
        title: t('ERROR_HAPPENED'),
        message: t('widgets.eIdentification.notifications.failed_copy_link'),
        position: 'bottomRight',
        timeout: 2500,
        icon: 'delete'
      });
    }
  };

  const getLinkByHash = async (hash: string) => {
    try {
      const { payload } = await dispatch(getEIdentificationLink({ hash }));
      navigator.clipboard.writeText((payload as { link: string }).link);
      iziToast.info({
        message: t('widgets.eIdentification.notifications.copy_link')
      });
    } catch (error) {
      iziToast.error({
        title: t('ERROR_HAPPENED'),
        message: t('widgets.eIdentification.notifications.failed_copy_link_existing'),
        position: 'bottomRight',
        timeout: 2500,
        icon: 'delete'
      });
    }
  };

  const getActionByTicketId = (channel: ChannelType): 'sendComment' | 'addToEditor' => {
    switch (channel) {
      case 5:
      case 21:
        return 'sendComment';
      default:
        return 'addToEditor';
    }
  };

  const getChatPayload = ({ content }: { content: string }) => {
    return {
      sendAsGiosgMessage: true,
      channel: task.channel,
      direction: 'out',
      room_id: contentDetails.room_id,
      chat_id: contentDetails.giosg_chat_id,
      closeChat: false,
      content,
      agentName: `${currentUserProfile.firstName} ${currentUserProfile.lastName}`
    };
  };

  const generateLinkAndSendChatMessage = async ({ contentId }: { contentId: number }) => {
    try {
      const { payload } = await dispatch(generateEIdentificationLink({ contentId }));
      const action = getActionByTicketId(task.channel);

      if (action === 'sendComment') {
        defaultDispatch(addComment(`TSK${contentId}`, getChatPayload({ content: (payload as { link: string }).link })));
      } else {
        let content = (payload as { link: string }).link;
        const draftsForCurrentTicket = drafts;
        const currentChannelAsString = getChannelTypeKey(task.channel)?.toLowerCase();
        if (!currentChannelAsString) {
          return;
        }
        const draftForCurrentChannel = draftsForCurrentTicket?.[currentChannelAsString];
        if (draftForCurrentChannel?.content !== undefined && draftForCurrentChannel?.content !== '') {
          const update = await onResponseTemplatePrompt({
            update: { content },
            originalContent: draftForCurrentChannel?.content,
            insertAtCursor: false,
            tr: t
          });

          if (update?.content) {
            content = update.content;
          }
        }

        dispatch(updateTicketTabState(task.id, { [currentChannelAsString]: { content } }));
        defaultDispatch(switchDraftTabIndex(task.id, task.channel));
      }
    } catch (error) {
      console.error('[EID]: failed to send directly', error);
      iziToast.error({
        title: t('ERROR_HAPPENED'),
        message: t('widgets.eIdentification.notifications.failed_copy_and_send'),
        position: 'bottomRight',
        timeout: 2500,
        icon: 'delete'
      });
    }
  };

  const updateEIdentification = async (contentId: number, hash: string, type: 'accept' | 'reject') => {
    try {
      await dispatch(
        updateEIdentificationAction({
          contentId,
          hash,
          type
        })
      );

      iziToast.success({
        title: t('OK'),
        icon: 'icon check',
        message: t('widgets.eIdentification.notifications.update_success'),
        timeout: 2500
      });
    } catch (error) {
      iziToast.error({
        title: t('ERROR_HAPPENED'),
        message: t('widgets.eIdentification.notifications.update_failed'),
        position: 'bottomRight',
        timeout: 2500,
        icon: 'delete'
      });
    }
  };

  useEffect(() => {
    if (!eIdentifications) {
      setLoading(true);
      dispatch(fetchEIdentifications({ contentId })).then(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (eIdentificationFetchInProgress && !loading) {
      setLoading(true);
    }
    if (!eIdentificationFetchInProgress && loading) {
      setLoading(false);
    }
  }, [eIdentificationFetchInProgress]);

  const renderContent = () => {
    if (loading) {
      return <Header as="h4" content={t('LOADING')} icon={<Icon name="circle notch" loading />}></Header>;
    }

    if (!eIdentifications || eIdentifications.length === 0) {
      return (
        <>
          <Message
            icon="info circle"
            info
            size="tiny"
            header={t('widgets.eIdentification.labels.not_found')}
            content={t('widgets.eIdentification.labels.send_request')}
          />
        </>
      );
    }

    return (
      <>
        <Message
          icon="info circle"
          positive
          size="tiny"
          header={t('widgets.eIdentification.labels.found_title', { amount: eIdentifications.length })}
          content={t('widgets.eIdentification.labels.found_description')}
        />
        <Divider />
        <List divided relaxed>
          {eIdentifications.map((singleEIdentification) => (
            <SingleEIdentification
              key={singleEIdentification.id}
              getLinkByHash={getLinkByHash}
              contentId={contentId}
              eIdentification={singleEIdentification}
              eIdentificationUpdateInProgress={!!eIdentificationUpdateInProgress}
              updateEIdentification={updateEIdentification}
            />
          ))}
        </List>
      </>
    );
  };

  return (
    <AccordionHeader
      key="eIdentification-widget"
      as="h4"
      active={true}
      title={t('widgets.eIdentification.title')}
      icon="handshake outline"
    >
      <>
        {renderContent()}
        <Divider />
        <div style={{ display: 'flex', justifyContent: 'start' }}>
          <Button disabled={loading} onClick={() => generateAndCopyLink(contentId)} primary icon labelPosition="left">
            <Icon name="copy" />
            {t('widgets.eIdentification.buttons.copy_link')}
          </Button>

          <Button
            disabled={loading || getChannelTypeKey(task.channel) === undefined}
            onClick={() =>
              generateLinkAndSendChatMessage({
                contentId
              })
            }
            basic
            icon
            labelPosition="left"
          >
            <Icon name="send" />
            {t('widgets.eIdentification.buttons.send_link_as_message')}{' '}
            {t('CHANNEL_' + getChannelTypeKey(task.channel)?.toUpperCase(), {
              defaultValue: t('CHANNEL_UNKNOWN_CURRENT')
            })}
          </Button>
        </div>
      </>
    </AccordionHeader>
  );
};

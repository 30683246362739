import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { TicketType } from 'src/types/TicketType';

export type TicketTypesState = TicketType[];

const initialState: TicketTypesState = [];

const ticketTypesSlice = createSlice({
  name: 'ticketTypes',
  initialState,
  reducers: {
    fetchTicketTypesSuccess: (_state, action: PayloadAction<TicketType[]>) =>
      action.payload.map((ticketType) => ({
        ...ticketType,
        id: typeof ticketType.id === 'string' ? parseInt(ticketType.id) : ticketType.id
      }))
  }
});

export const { fetchTicketTypesSuccess } = ticketTypesSlice.actions;

export default ticketTypesSlice.reducer;

import React, { useCallback, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Card } from 'semantic-ui-react';
import { Resizable } from 'react-resizable';
import type { CSSProperties } from 'react';
import type { ResizeCallbackData } from 'react-resizable';
import type { DraggableEventHandler } from 'react-draggable';

import FloatingWindowHeader from './FloatingWindowHeader';
import { DEFAULT_WIDTH, DEFAULT_HEIGHT, WINDOW_OFFSET } from './constants';
import type { FloatingWindowState } from 'src/types/FloatingWindow';

import '../../../../node_modules/react-resizable/css/styles.css';
import * as styles from './FloatingWindow.style';

interface FloatingWindowProps {
  children: React.ReactNode;
  index: number;
  window: FloatingWindowState;
  style?: CSSProperties;
  extraContent?: React.ReactNode;
  minConstraints?: [number, number];

  onClose(): void;
  foldToggle(): void;
  onDragStart(...args: Parameters<DraggableEventHandler>): void;
  onDragStop(...args: Parameters<DraggableEventHandler>): void;
  onResizeStop(e: React.SyntheticEvent, data: ResizeCallbackData): void;
}

const FloatingWindow = ({
  children,
  window: win,
  index,
  style,
  extraContent,

  onClose,
  foldToggle,
  onDragStart,
  onDragStop,
  onResizeStop,
  minConstraints = [150, 50]
}: FloatingWindowProps) => {
  const currentWidth = win.width ?? DEFAULT_WIDTH;
  const currentHeight = win.height ?? DEFAULT_HEIGHT;
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: currentWidth,
    height: currentHeight
  });
  const [fullScreen, setFullScreen] = useState(false);
  const fullScreenToggle = useCallback(() => {
    setFullScreen(!fullScreen);
  }, [fullScreen]);

  useEffect(() => {
    setSize({ width: currentWidth, height: currentHeight });
  }, [win.width, win.height]);

  const { innerWidth, innerHeight } = window;
  const x = fullScreen ? WINDOW_OFFSET / 2 : win.x ?? 0;
  const y = fullScreen ? WINDOW_OFFSET / 2 : win.y ?? 0;
  const width = fullScreen ? innerWidth - WINDOW_OFFSET : size.width;
  const height = fullScreen ? innerHeight - WINDOW_OFFSET : size.height;

  const card = (
    <Card
      style={{
        ...styles.card({ fullScreen, width: `${width}px`, height: `${height}px`, index }),
        ...style
      }}
    >
      <FloatingWindowHeader
        window={win}
        fullScreen={fullScreen}
        onClose={onClose}
        foldToggle={foldToggle}
        fullScreenToggle={fullScreenToggle}
      />
      <Card.Content style={styles.content}>{children}</Card.Content>
      {!!extraContent && <Card.Content>{extraContent}</Card.Content>}
    </Card>
  );

  return fullScreen ? (
    card
  ) : (
    <Draggable
      cancel=".react-resizable-handle"
      onStart={onDragStart}
      onStop={onDragStop}
      bounds={{ top: 0, left: 0, right: window.innerWidth - width, bottom: window.innerHeight - height }}
      position={{ x, y }}
    >
      <Resizable
        width={width}
        height={height}
        minConstraints={minConstraints}
        onResize={(_e, { size }) => {
          setSize(size);
        }}
        onResizeStop={onResizeStop}
      >
        {card}
      </Resizable>
    </Draggable>
  );
};

export default React.memo(FloatingWindow);

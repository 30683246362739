import moment from 'moment';
import React from 'react';
import { getI18n, Translation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Dropdown, Icon, List, Popup } from 'semantic-ui-react';
import type { DropdownProps } from 'semantic-ui-react';

import EnvSettings from './api/EnvSettings';
import { getLanguages, setLanguage } from './api/Languages';
import { setHtmlLangAttribute } from './Utilities/helper';
import UserAvatar from './Components/User/UserAvatar';
import type { PersonalData, User } from 'src/types/User';

import './ProfileDropdown.css';

interface ProfileDropdownProps {
  userData: PersonalData;
  usersList: User[];

  onLogout: () => void;
}

interface ProfileDropdownState {
  language: string;
  showProfileModal: boolean;
  isOpen: boolean;
}

class ProfileDropdown extends React.Component<ProfileDropdownProps, ProfileDropdownState> {
  constructor(props: ProfileDropdownProps) {
    super(props);

    let lang = 'fi';
    if (this.props.userData && this.props.userData.userPreferences) {
      lang = this.props.userData.userPreferences.defaultLanguage;
    }
    this.state = {
      language: lang,
      showProfileModal: false,
      isOpen: false
    };
    getI18n().changeLanguage(lang);
    setHtmlLangAttribute(lang);
  }

  private changeLanguage = (i18nInstance: any, data: DropdownProps) => {
    const lang = `${data.value}` || 'en';
    this.setState({ language: lang });
    getLanguages().forEach((l) => {
      if (l.id === lang) {
        i18nInstance.changeLanguage(l.id);
        moment.locale(l.momentLocale);
        setHtmlLangAttribute(l.momentLocale);
        setLanguage(l.id);
      }
    });
  };

  private getProfileTrigger = () => {
    return (
      <div style={{ float: 'right', cursor: 'pointer' }}>
        <UserAvatar
          UID={this.props.userData.UID}
          className="navBarProfileAvatar"
          round
          size="50"
          imgClassName="ProfileDropdown-Image"
        />
      </div>
    );
  };

  private handleOpen = () => {
    this.setState({ isOpen: true });
  };

  private handleClose = () => {
    this.setState({ isOpen: false });
  };

  private getLanguages = () => {
    const langs = getLanguages();
    return langs.map((l) => ({
      key: l.id,
      value: l.id,
      flag: l.flag,
      text: l.name
    }));
  };

  render() {
    return (
      <Translation ns="translations">
        {(t, { i18n }) => (
          <Popup
            open={this.state.isOpen}
            onOpen={this.handleOpen}
            onClose={this.handleClose}
            style={{ minWidth: '200px' }}
            on="click"
            position="bottom right"
            trigger={this.getProfileTrigger()}
          >
            <Popup.Content>
              <List size="medium">
                <List.Item as="a">
                  <Dropdown
                    selectOnBlur={false}
                    value={this.state.language}
                    onChange={(e, data) => this.changeLanguage(i18n, data)}
                    options={this.getLanguages()}
                  />
                </List.Item>

                <List.Item href={EnvSettings.getSettings().GUIDE_URL} target={'_blank'}>
                  <Icon name="question" /> {t('INSTRUCTIONS')}
                </List.Item>

                <List.Item>
                  <Icon name="hashtag" /> {t('VERSION')} {import.meta.env.VITE_CONTAINER_IMAGE}
                </List.Item>

                <List.Item>
                  <Icon name="settings" className="profileDropdownSettings" />
                  <Link to="/settings" className="profileDropdownSettings">
                    {t('PREFERENCES_EDIT_SETTINGS')}
                  </Link>
                </List.Item>

                <List.Item className="logOutLink" as="a" onClick={() => this.props.onLogout()}>
                  <Icon name="log out" /> {t('MAIN_TOPBAR_LOGOUT')}
                </List.Item>
              </List>
            </Popup.Content>
          </Popup>
        )}
      </Translation>
    );
  }
}

export default ProfileDropdown;

import { t } from 'i18next';
import iziToast from 'izitoast';
import { get, isEmpty } from 'lodash';
import React from 'react';
import Avatar from 'react-avatar';
import { Accordion, Comment, Icon, Popup } from 'semantic-ui-react';

import AttachmentCard from '../Attachments/AttachmentCard';
import ChannelType from '../CommentIconContent/ChannelType';
import UserAvatar from '../User/UserAvatar';
import UserPopup from '../User/UserPopup';
import { ModalWindow } from '../Versions/DisplayWindow';
import AIFunctionButtons from './AIPromptButton';
import CommentMetadata from './CommentMetadata';
import CommentText from './CommentText';
import HelperComment from './HelperComment';
import FeatureFlags from 'src/api/FeatureFlags';
import { getCommentAttachmentsIds, getCommentSender } from 'src/Components/Utilities/comments';
import CommentEditorWidgetContainer from 'src/containers/CommentEditorWidgetContainer';
import ErrorBoundary from 'src/ErrorBoundary';
import { hasImageExtension } from 'src/Utilities/images';

import type { Channel } from 'src/types/Channel';
import type { Attachment, Ticket, Comment as TicketComment } from 'src/types/Ticket';
import type { SenderEmail, TicketType } from 'src/types/TicketType';
import type { User } from 'src/types/User';

import './UserComment.css';

interface UserCommentProps extends Partial<TicketComment> {
  user: User;
  task: Ticket;
  taskId: string;
  channels: Channel[];
  title: string | null;
  senderEmails: SenderEmail[];
  ticketTypes: TicketType[];
  botButtonClickedState: undefined | TicketComment;
  isLastComment?: boolean;
  isLastExternalComment?: boolean;
}

interface CommentState {
  isCommentExpanded: boolean;
  isIntegratorUserReply: boolean;
  integratorUserReplyName: string | null;
  isMetadataSourceOpen: boolean;
  isImageAccordionOpen: boolean;
}

class UserComment extends React.PureComponent<UserCommentProps, CommentState> {
  private chatComment: boolean;
  private commentSentReceivedClass: 'sentComment' | 'receivedComment' | 'suggestionComment' | '';
  private channelTypeName: string;
  private suggestionComment: boolean;

  constructor(props: UserCommentProps) {
    super(props);
    this.state = {
      isCommentExpanded: false,
      isIntegratorUserReply: false,
      integratorUserReplyName: null,
      isMetadataSourceOpen: false,
      isImageAccordionOpen: false
    };

    if (this.props.type === 'suggestion') {
      this.commentSentReceivedClass = '';
      this.suggestionComment = true;
    } else {
      switch (this.props.direction) {
        case 'out':
          this.commentSentReceivedClass = 'sentComment';
          break;
        case 'in':
          this.commentSentReceivedClass = 'receivedComment';
          break;
        default:
          this.commentSentReceivedClass = '';
          break;
      }
    }

    switch (this.props.channel) {
      case ChannelType.Email:
        this.channelTypeName = 'email';
        break;
      case ChannelType.Sms:
        this.channelTypeName = 'sms';
        break;
      case ChannelType.Internal:
        this.channelTypeName = 'internal';
        break;
      case ChannelType.Chat:
        this.channelTypeName = 'chat';
        break;
      case ChannelType.Webform:
        this.channelTypeName = 'webform';
        break;
      default:
        this.channelTypeName = 'other';
        break;
    }

    this.chatComment =
      props.content?.substring(0, 22) === '[customer_closed_chat]' ||
      props.content?.substring(0, 19) === '[agent_closed_chat]' ||
      props.content?.substring(0, 31) === '[chat_was_closed_automatically]' ||
      props.content?.substring(0, 18) === '[user_closed_chat]';
  }

  componentWillReceiveProps(newProps: UserCommentProps) {
    /**
     * Check if incoming comment comes from integrator
     */
    if ([5, 20, 21, 22].includes(newProps.channel!) && newProps.direction === 'in' && newProps.task.entities.length) {
      const targetEntity = newProps.task.entities[0].data;

      if (targetEntity?.firstName || targetEntity?.lastName) {
        const entityName = `${targetEntity?.firstName || ''} ${targetEntity?.lastName || ''}`;
        this.setState({
          isIntegratorUserReply: true,
          integratorUserReplyName: entityName
        });
      }
    }
  }

  private getAttachmentsList = () => {
    const source: Attachment[] = !isEmpty(get(this.props, ['metaData', 'originalTicketData', 'data', 'attachments']))
      ? get(this.props, ['metaData', 'originalTicketData', 'data', 'attachments'], [])
      : get(this.props, ['metaData', 'attachments'], []);
    const taskAttachments = get(this.props, ['task', 'attachments']);
    const commentAttachmentsFilenames: string[] = source.map((att: any) => att.fileName || att.filename);
    const commentAttachmentsCIDs: string[] = source.map((att) => String(att.cId || att['cid']).replace(/\s/g, ''));

    return taskAttachments.filter((taskAttachment) =>
      taskAttachment.cId
        ? commentAttachmentsCIDs.includes(String(taskAttachment.cId))
        : commentAttachmentsFilenames.includes(taskAttachment.fileName)
    );
  };

  private attachmentView = () => {
    const attachmentFiles = this.getAttachmentsList().filter((attachment) => !hasImageExtension(attachment.fileName));
    if (attachmentFiles.length) {
      return (
        <div className="userComment__attachmentCardWrapper">
          {attachmentFiles.map((attachment) => (
            <AttachmentCard attachment={attachment} attachments={attachmentFiles} />
          ))}
        </div>
      );
    }
    return null;
  };

  private attachmentImagesView = () => {
    const attachmentImages = this.getAttachmentsList().filter((attachment) => hasImageExtension(attachment.fileName));
    if (attachmentImages.length) {
      return (
        <Accordion>
          <Accordion.Title
            active={this.state.isImageAccordionOpen}
            onClick={() => this.setState({ isImageAccordionOpen: !this.state.isImageAccordionOpen })}
          >
            <Icon name="dropdown" />
            {t('IMAGES')}
          </Accordion.Title>
          <Accordion.Content active={this.state.isImageAccordionOpen} className="userComment__attachmentCardWrapper">
            {attachmentImages.map((attachment) => (
              <AttachmentCard attachment={attachment} attachments={attachmentImages} />
            ))}
          </Accordion.Content>
        </Accordion>
      );
    }

    return null;
  };

  private expandComment = (expandable: boolean) => {
    if (expandable) {
      this.setState({ isCommentExpanded: true });
    }
  };

  private toggleExpand = () => {
    this.setState((prevState: CommentState) => ({
      isCommentExpanded: !prevState.isCommentExpanded
    }));
  };

  private getChannelData = (channelId: number) => {
    return this.props.channels.find((channel) => channel.id === channelId);
  };

  private copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    iziToast.info({
      message: t('COPIED')
    });
  };

  private renderEmailAddresses = () => {
    const { metaData } = this.props;

    return (
      <>
        <div className="flexBreak"></div>
        <div className="userComment__emailAddressesWrapper">
          {metaData?.to?.length ? (
            <div>
              <strong>{t('COMMENT_MAIL_ORIGINAL_RECIPIENTS')}</strong>:{' '}
              {metaData.to.map((to, index, toArray) => (
                <Popup
                  key={to}
                  position="top center"
                  trigger={
                    <span onClick={() => this.copyToClipboard(to)}>
                      {to} {toArray.length - 1 === index || '; '}
                    </span>
                  }
                  content={'Copy email'}
                  on="hover"
                  basic
                  size="small"
                />
              ))}
            </div>
          ) : null}

          {metaData?.cc?.length ? (
            <div>
              <strong>{t('COMMENT_MAIL_CC')}</strong>:{' '}
              {metaData.cc.map((cc, index, ccArray) => (
                <Popup
                  position="top center"
                  trigger={
                    <span onClick={() => this.copyToClipboard(cc)}>
                      {cc} {ccArray.length - 1 === index || '; '}
                    </span>
                  }
                  content={'Copy email'}
                  on="hover"
                  basic
                  size="small"
                />
              ))}
            </div>
          ) : null}
        </div>
      </>
    );
  };

  render() {
    const { user } = this.props;
    const channelData = this.getChannelData(this.props.channel!);
    const entityData = get(this.props, ['metaData', 'originalEntity']);
    const htmlMetaData = get(this.props, ['metaData', 'html']);

    const hasAttachments =
      !isEmpty(get(this.props, ['metaData', 'originalTicketData', 'data', 'attachments'])) ||
      !isEmpty(get(this.props, ['metaData', 'attachments']));

    const helperComments = this.props.task.comments.filter(
      (comment) =>
        comment.type === 'helper' && comment.metaData?.parentCommentId === parseInt(this.props.id!.substring(3), 10)
    );

    return (
      <Comment
        className={
          (this.props.isLastExternalComment ? 'lightGrey' : '') +
          ' ' +
          (this.suggestionComment ? 'suggestionComment' : '')
        }
      >
        {this.state.isMetadataSourceOpen && (
          <ModalWindow
            title={t('comments.titles.source_preview')}
            onClickClose={() => this.setState({ isMetadataSourceOpen: false })}
          >
            <pre>{JSON.stringify(this.props.metaData, undefined, 4)}</pre>
          </ModalWindow>
        )}

        <UserPopup UID={user.UID} botName={this.props.metaData?.bot?.name} disabled={this.state.isIntegratorUserReply}>
          <div style={{ float: 'left', marginRight: '7px' }}>
            {this.state.isIntegratorUserReply && this.state.integratorUserReplyName ? (
              <Avatar name={this.state.integratorUserReplyName} size="35" />
            ) : (
              <UserAvatar user={user} size="35" />
            )}
          </div>
        </UserPopup>

        <Comment.Content
          style={{ color: (channelData && channelData.color) || '', marginLeft: '3rem' }}
          className={`${this.commentSentReceivedClass} ${this.channelTypeName}CommentIcon`}
        >
          <div style={{ display: 'flex' }}>
            <div className="userComment__metadataWrapper">
              <Comment.Author
                as="a"
                onClick={() =>
                  this.copyToClipboard(
                    getCommentSender({
                      entityData,
                      channelData,
                      user,
                      metaData: this.props.metaData,
                      direction: this.props.direction
                    })
                  )
                }
              >
                {this.state.isIntegratorUserReply ? (
                  <>
                    <i>
                      <Icon name={'user'} />
                    </i>
                    {this.state.integratorUserReplyName}
                  </>
                ) : (
                  getCommentSender({
                    entityData,
                    channelData,
                    user,
                    metaData: this.props.metaData,
                    direction: this.props.direction
                  })
                )}
              </Comment.Author>

              <CommentMetadata
                task={this.props.task}
                ticketTypes={this.props.ticketTypes}
                comment={{
                  id: this.props.id,
                  channel: this.props.channel,
                  title: this.props.title,
                  metaData: this.props.metaData,
                  created: this.props.created,
                  content: this.props.content,
                  direction: this.props.direction,
                  type: this.props.type
                }}
                hasHTMLMetadata={!isEmpty(htmlMetaData)}
                isIntegratorUserReply={this.state.isIntegratorUserReply}
                channelData={channelData}
                htmlMetaData={htmlMetaData}
                channels={this.props.channels}
                channelTypeName={this.channelTypeName}
                senderEmails={this.props.senderEmails}
                setMetadataSourceOpen={() => this.setState({ isMetadataSourceOpen: true })}
                replyToEmailEnabled
              />

              {this.props.channel === ChannelType.Email && this.renderEmailAddresses()}
            </div>
            <div
              className="chatGptButtons__wrapper"
              style={{
                marginLeft: 'auto',
                ...(this.props.channel === ChannelType.Email ? { marginRight: '3px' } : {})
              }}
            >
              {FeatureFlags.isFlagOn('ENABLE_OPENAI') === true && (
                <AIFunctionButtons commentId={this.props.id!} tinyButton basicButton />
              )}
            </div>
            {this.props.channel === ChannelType.Email && (
              <div style={{ marginRight: '5px' }}>
                <ErrorBoundary>
                  <CommentEditorWidgetContainer
                    senderEmails={this.props.senderEmails}
                    created={this.props.created!}
                    title={this.props.title || ''}
                    comment={this.props.parsedContent ?? ''}
                    taskId={this.props.taskId}
                    metaData={this.props.metaData}
                    isHTML={htmlMetaData}
                    attachmentIds={getCommentAttachmentsIds(this.props.metaData, this.props.task)}
                    buttonsType={this.props.isLastExternalComment ? 'primary' : 'basic'}
                  />
                </ErrorBoundary>
              </div>
            )}
          </div>

          {hasAttachments && (
            <div style={{ margin: '10px 0' }}>
              <ErrorBoundary>{this.attachmentView()}</ErrorBoundary>
              <ErrorBoundary>{this.attachmentImagesView()}</ErrorBoundary>
            </div>
          )}

          <CommentText
            parsedContent={this.props.parsedContent}
            isChatComment={this.chatComment}
            isCommentExpanded={this.state.isCommentExpanded}
            isLastComment={!!this.props.isLastComment}
            botButtonClickedState={this.props.botButtonClickedState}
            metaData={this.props.metaData}
            type={this.props.type!}
            attachments={this.props.task.attachments}
            expandComment={this.expandComment}
            toggleExpand={this.toggleExpand}
          />
        </Comment.Content>

        {helperComments.length ? (
          <Comment.Group className="discussionPanel__helpers">
            {helperComments.map((comment) => (
              <HelperComment comment={comment} />
            ))}
          </Comment.Group>
        ) : null}
      </Comment>
    );
  }
}

export default UserComment;

import { StringMap, t as tr } from 'i18next';
import React from 'react';
import { List } from 'semantic-ui-react';

import type { PasswordPolicy } from 'src/types/User';

const userFormPrefixedT = (
  key: string,
  options?: { amount: number } | { minimum: number; maximum: number }
): string => {
  return tr(`settings.add_user_form.${key}`, options as StringMap);
};

type ValidationErrors = 'password_too_short' | 'password_too_long' | 'password_needs_more_requirements';

export const validatePassword = (
  password: string,
  policy: PasswordPolicy
): undefined | { error: ValidationErrors; required: number } => {
  const { passwordLength, requirements } = policy;
  const [minimumLength, maximumLength] = passwordLength;

  if (password.length < minimumLength) {
    return { error: 'password_too_short', required: minimumLength };
  }

  if (password.length > maximumLength) {
    return { error: 'password_too_long', required: maximumLength };
  }

  let fulfilled = 0;
  if (requirements.smallLetters && /[a-zäöå]/.test(password)) {
    fulfilled++;
  }

  if (requirements.bigLetters && /[A-ZÄÖÅ]/.test(password)) {
    fulfilled++;
  }

  // I think the rule here is misguided, unescaping them changed the meaning of the regex.
  // eslint-disable-next-line no-useless-escape
  if (requirements.specialChars && /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
    fulfilled++;
  }

  if (requirements.numbers && /\d/.test(password)) {
    fulfilled++;
  }

  if (fulfilled < requirements.howManyRequirementsMustBeFulfilled) {
    return {
      error: 'password_needs_more_requirements',
      required: requirements.howManyRequirementsMustBeFulfilled
    };
  }

  return undefined;
};

export const passwordTooltips = (policy: PasswordPolicy) => {
  let passwordTooltips: JSX.Element[] | undefined;
  if (policy) {
    passwordTooltips = [
      <List.Item key={'passwordLength'}>
        <List.Icon name="info" />
        <List.Content>
          {userFormPrefixedT('password.requirements.length_between', {
            minimum: policy.passwordLength[0],
            maximum: policy.passwordLength[1]
          })}
        </List.Content>
      </List.Item>
    ];

    const requirements = Object.entries(policy.requirements) as [
      keyof PasswordPolicy['requirements'],
      number | boolean
    ][];

    passwordTooltips.push(
      ...requirements
        .sort((a, b) => {
          if (a[0] === 'howManyRequirementsMustBeFulfilled') {
            return -1;
          }

          if (b[0] === 'howManyRequirementsMustBeFulfilled') {
            return 1;
          }

          return 0;
        })
        .map((entry) => {
          const key = entry[0] as keyof PasswordPolicy['requirements'];
          const value = entry[1];

          if (typeof value === 'boolean' && !value) {
            return [key, ''];
          }

          let str: string;
          switch (key) {
            case 'smallLetters':
              str = userFormPrefixedT('password.requirements.small_letters');
              break;
            case 'bigLetters':
              str = userFormPrefixedT('password.requirements.big_letters');
              break;
            case 'specialChars':
              str = userFormPrefixedT('password.requirements.special_characters');
              break;
            case 'numbers':
              str = userFormPrefixedT('password.requirements.numbers');
              break;
            case 'howManyRequirementsMustBeFulfilled':
              str = userFormPrefixedT('password.requirements.amount', { amount: value as number });
              break;
            default:
              console.error(`Unknown password policy requirements key: ${key}`);
              str = '';
              break;
          }

          return [key, str];
        })
        .map((tuple) => {
          const [key, str] = tuple;
          if (str.length === 0) {
            return <></>;
          }

          return (
            <List.Item key={key}>
              <List.Icon name="info" />
              <List.Content>{str}</List.Content>
            </List.Item>
          );
        })
    );
  }

  return passwordTooltips;
};

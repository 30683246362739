import { t as tr } from 'i18next';
import React from 'react';
import { Form, Icon, Input, Label, Popup, TextArea } from 'semantic-ui-react';

import DateTimePicker from '../../DateTimePicker';
import { replaceObjectParameters } from '../../Utilities';
import SalesforceMemoSelect from './SalesforceMemoSelect';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';

import type { ReplySalesforceState } from '../ReplySalesforce';
import type { ISalesforceSettingsData } from 'src/api/EnvSettings';
import type { IGeneralSalesforceData, SalesforceFormPayload, SalesforceObjectType } from 'src/types/Salesforce';

interface IProps {
  validationErrors: { [key: string]: any };
  salesforceSettings: ISalesforceSettingsData;
  payload: SalesforceFormPayload;
  accounts: IGeneralSalesforceData[];
  users: IGeneralSalesforceData[];

  handleSetState: (fields: Partial<ReplySalesforceState['payload']>) => void;
  handleSelectChange: (property: string, value: string) => void;
  setSearchableSelectFields: (type: SalesforceObjectType, stringToSearch: string) => void;
}

class SalesforceOpportunityForm extends React.Component<IProps> {
  render() {
    return (
      <>
        {!this.props.salesforceSettings.hiddenFields.includes('opportunity.Name') && (
          <Form.Field
            required
            error={
              this.props.validationErrors.Name && {
                content: this.props.validationErrors.Name,
                pointing: 'above'
              }
            }
            control={Input}
            value={this.props.payload.Name}
            label={tr('GENERAL_NAME')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              this.props.handleSetState({
                Name: event.target.value
              })
            }
          />
        )}

        {!this.props.salesforceSettings.hiddenFields.includes('opportunity.Description') && (
          <Form.Field
            error={
              this.props.validationErrors.Description && {
                content: this.props.validationErrors.Description,
                pointing: 'above'
              }
            }
            control={TextArea}
            value={this.props.payload.Description}
            label={tr('GENERAL_DESCRIPTION')}
            onChange={(event: React.SyntheticEvent, data: { [key: string]: any }) =>
              this.props.handleSetState({
                Description: data.value
              })
            }
          />
        )}

        {!this.props.salesforceSettings.hiddenFields.includes('opportunity.StageName') && (
          <SalesforceMemoSelect
            value={this.props.payload.StageName}
            fieldName="StageName"
            items={this.props.salesforceSettings.opportunityStages.map((statusName: string) => ({
              text: statusName,
              value: statusName
            }))}
            errorMessage={this.props.validationErrors.StageName}
            handleState={this.props.handleSelectChange}
            label={tr('salesforce_reply.stage_name')}
          />
        )}

        {!this.props.salesforceSettings.hiddenFields.includes('opportunity.Amount') && (
          <Form.Field
            error={
              this.props.validationErrors.Amount && {
                content: this.props.validationErrors.Amount,
                pointing: 'above'
              }
            }
            control={Input}
            value={this.props.payload.Amount}
            label={tr('GENERAL_AMOUNT')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              this.props.handleSetState({
                Amount: parseInt(event.target.value)
              })
            }
          />
        )}

        {!this.props.salesforceSettings.hiddenFields.includes('opportunity.CloseDate') && (
          <Form.Field>
            <label>{tr('GENERAL_CLOSE_DATE')}</label>

            <Popup
              position="top center"
              wide={true}
              hoverable={true}
              flowing={true}
              trigger={
                <DateTimePicker
                  onChange={(value: any) =>
                    this.props.handleSetState({
                      CloseDate: value
                    })
                  }
                  trigger={
                    <Label>
                      <Icon name="time" />
                      <span>{getPrettyDate(this.props.payload.CloseDate, { format: DATE_TIME_FORMAT })}</span>
                    </Label>
                  }
                  value={this.props.payload.CloseDate}
                />
              }
              content={tr('TOOLTIP_DUEDATE')}
            />
          </Form.Field>
        )}

        {!this.props.salesforceSettings.hiddenFields.includes('opportunity.Account') && (
          <SalesforceMemoSelect
            value={this.props.payload.AccountId}
            fieldName="AccountId"
            items={this.props.accounts
              .sort((a, b) => (a.Name > b.Name ? 1 : -1))
              .map((singleItem) => ({
                text: this.props.salesforceSettings.accountFieldView
                  ? replaceObjectParameters(this.props.salesforceSettings.accountFieldView, singleItem)
                  : singleItem.Name,
                value: singleItem.Id
              }))}
            errorMessage={this.props.validationErrors.AccountId}
            handleState={this.props.handleSelectChange}
            onSearchChange={(event: React.SyntheticEvent, data: { [key: string]: any }) =>
              this.props.setSearchableSelectFields('Account', data.searchQuery)
            }
            label={tr('GENERAL_ACCOUNT')}
            search
          />
        )}

        {!this.props.salesforceSettings.hiddenFields.includes('opportunity.Owner') && (
          <SalesforceMemoSelect
            value={this.props.payload.OwnerId || this.props.salesforceSettings.opportunityDefaultOwnerId}
            fieldName="OwnerId"
            items={this.props.users
              .sort((a, b) => (a.Name > b.Name ? 1 : -1))
              .map((singleItem) => ({
                text: this.props.salesforceSettings.userFieldView
                  ? replaceObjectParameters(this.props.salesforceSettings.userFieldView, singleItem)
                  : singleItem.Name,
                value: singleItem.Id
              }))}
            errorMessage={this.props.validationErrors.OwnerId}
            handleState={this.props.handleSelectChange}
            onSearchChange={(event: React.SyntheticEvent, data: { [key: string]: any }) =>
              this.props.setSearchableSelectFields('User', data.searchQuery)
            }
            label={tr('GENERAL_OWNER')}
            search
          />
        )}
      </>
    );
  }
}

export default SalesforceOpportunityForm;

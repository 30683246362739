import type { VFC } from 'react';
import React, { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Popup } from 'semantic-ui-react';
import type { OnInputChange } from 'src/types/Campaign';
import type { Field } from 'src/types/Info';

import * as styles from './EntityNewField.style';

interface EntityNewFieldProps {
  disabled: boolean;

  onChange(field: Field): void;
}

const EntityNewField: VFC<EntityNewFieldProps> = ({ disabled, onChange }) => {
  const { t } = useTranslation();
  const [newField, setNewField] = useState<Field | undefined>();

  const onAddClick = useCallback(() => {
    setNewField({ name: '', value: '' });
  }, []);
  const onNameChange = useCallback<OnInputChange>(
    (_e, { value }) => {
      setNewField({ ...newField, name: value } as Field);
    },
    [newField]
  );
  const onValueChange = useCallback<OnInputChange>(
    (_e, { value }) => {
      setNewField({ ...newField, value });
    },
    [newField]
  );
  const onSave = useCallback(() => {
    onChange(newField as Field);
    setNewField(undefined);
  }, [newField]);

  return (
    <>
      {newField && (
        <Form>
          <Form.Group>
            <Form.Field width={7}>
              <label>{t('entityViewer.create_entity_new_field_label')}</label>
              <Input value={newField?.name} onChange={onNameChange} />
            </Form.Field>
            <Form.Field width={7}>
              <label>{t('entityViewer.create_entity_new_field_key')}</label>
              <Input value={newField?.value} onChange={onValueChange} />
            </Form.Field>
            <Form.Field width={2} style={styles.saveFieldWrapper}>
              <Popup
                trigger={<Button icon="save" primary onClick={onSave} />}
                content={t('entityViewer.create_entity_save_field')}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      )}
      {!newField && (
        <div style={styles.addFieldWrapper}>
          <Popup
            trigger={<Button icon="plus" positive disabled={disabled} onClick={onAddClick} />}
            content={t('entityViewer.create_entity_add_field')}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(EntityNewField);

import { orderBy } from 'lodash';
import type { ComponentProps, VFC } from 'react';
import React, { useMemo } from 'react';
import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';

import type { State } from 'src/types/initialState';

interface ChannelDropdownProps extends ConnectedProps<typeof connector> {
  id?: string;
  value?: number;
  placeholder?: string;
  error?: ComponentProps<typeof Dropdown>['error'];

  onChange?: ComponentProps<typeof Dropdown>['onChange'];
}

const ChannelDropdown: VFC<ChannelDropdownProps> = ({ value, error, placeholder, channels, onChange }) => {
  const channelTypes = useMemo(
    () =>
      orderBy(
        channels
          .filter((channel) => channel.active)
          .map((channelType) => ({
            text: channelType.channel,
            value: channelType.id,
            icon: channelType.icon
          })),
        ['text', 'value']
      ),
    [channels]
  );

  return (
    <Dropdown
      fluid
      selection
      error={error}
      placeholder={placeholder}
      value={value}
      options={channelTypes}
      onChange={onChange}
    />
  );
};

const connector = connect((state: State) => ({
  channels: state.channels
}));

export default connector(ChannelDropdown);

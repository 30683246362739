import throttle from 'lodash/throttle';
import React from 'react';
import { Translation } from 'react-i18next';
import sanitize from 'sanitize-html';
import { Form } from 'semantic-ui-react';

import ChannelType from '../CommentIconContent/ChannelType';
import ReplyControlButtons from './components/ReplyControlButtons';
import ReplyVCDrafts from './components/ReplyVCDrafts';
import { KnowledgeBank } from './KnowledgeBank';
import { ReplyMethod } from './ReplyMethod';
import ReplyTemplates from './ReplyTemplates';
import ReplyTextArea from './ReplyTextArea';
import FeatureFlags from 'src/api/FeatureFlags';
import { Channels } from 'src/types/Channel';
import { Roles } from 'src/types/User';
import { insertAtCursor } from 'src/Utilities/insertAtCursor';
import { decodeHtml } from 'src/Utilities/sanitize';

import type { ReplyMethodProps } from './ReplyMethod';
import type { KeyDownEvent } from 'src/Components/Case/ReplyEditor';
import type { ResponseTemplate } from 'src/types/ResponseTemplate';
import type { TicketStatusAfterCommentSubmit } from 'src/types/Salesforce';
import type { Entity, Ticket } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';
import type { PersonalData, User } from 'src/types/User';

interface ReplyGiosgProps extends ReplyMethodProps<ReplyGiosgState> {
  userData: PersonalData;
  ticketType: TicketType;
  entities: Entity[];
  templates: Array<ResponseTemplate>;
  drafts?: ReplyGiosgState;
  task: Ticket;
  users: User[];
  ticketTypes: TicketType[];
  canCloseAsDone: boolean;

  // TODO: typing
  switchChatAnchor: (...args: any[]) => any;
  onSubmit: (...args: any[]) => any;
  updateGiosgWritingStatus: (...args: any[]) => any;
  changeTicketStatus: (ticketId: string, uid: string, status: TicketStatusAfterCommentSubmit) => Promise<void>;
}

interface ReplyGiosgState {
  content: string;
  isLoading?: boolean;
  endChatAfterSubmit?: boolean;
  selectedReplyTemplate?: string;
}

class ReplyGiosg extends ReplyMethod<ReplyGiosgProps, ReplyGiosgState> {
  private channel = ChannelType.Giosg;
  private editor: HTMLTextAreaElement | null;

  constructor(props: ReplyGiosgProps) {
    super(props);
    this.state = this.getInitialState(this.props.drafts);
    this.updateWritingStatus = throttle(this.updateWritingStatus, 7500).bind(this);
  }

  getDraftChannel(): Channels {
    return Channels.giosg;
  }

  getDraftState(state: ReplyGiosgState): Partial<ReplyGiosgState> {
    return {
      content: state.content,
      selectedReplyTemplate: state.selectedReplyTemplate
    };
  }

  private getInitialState = (drafts?: ReplyGiosgState): ReplyGiosgState => ({
    isLoading: false,
    content: drafts?.content || '',
    endChatAfterSubmit: drafts?.endChatAfterSubmit || false,
    selectedReplyTemplate: drafts?.selectedReplyTemplate || undefined
  });

  public submitComment = async (ticketStatus?: TicketStatusAfterCommentSubmit) => {
    if (this.state.isLoading || !this.state.content.length) {
      return;
    }

    this.setState({ isLoading: true });
    try {
      const content = decodeHtml(this.state.content);

      let finalContent = content.replaceAll('<br>', '\n');
      finalContent = sanitize(finalContent, {
        allowedTags: [],
        allowedAttributes: {}
      });

      const bodyOfRequest = {
        content: finalContent,
        channel: this.channel,
        direction: 'out',
        closeChat: this.state.endChatAfterSubmit,
        sendAsGiosgMessage: true,
        chat_id: this.props.task.case.giosg_chat_id || undefined,
        room_id: this.props.task.case.room_id || undefined,
        agentName: `${this.props.userData.profile.firstName} ${this.props.userData.profile.lastName}`
      };

      const response = await this.props.onSubmit(bodyOfRequest, false);
      if (response !== false) {
        if (typeof ticketStatus !== 'undefined') {
          this.props.changeTicketStatus(this.props.taskId, this.props.userData.UID, ticketStatus);
        }
        this.clearFields();
      }
    } catch (error) {
      console.error('Failed to submit giosg message');
    } finally {
      this.setState({ isLoading: false });
    }
  };

  private clearFields = () => {
    this.setState({ content: '', selectedReplyTemplate: undefined }, () => {
      this.saveDraft(this.state);
    });
  };

  componentWillReceiveProps(nextProps: ReplyGiosgProps) {
    if (this.props.taskId !== nextProps.taskId) {
      this.setState(this.getInitialState(nextProps.drafts));
    }
    if (this.props.drafts?.content !== nextProps.drafts?.content) {
      this.setState({ content: nextProps.drafts?.content || '' });
    }
  }

  private handleChangeContent = (content: string) => {
    this.setState({ content }, () => {
      this.saveDraft(this.state);
    });
  };

  private updateWritingStatus = () => {
    this.props.updateGiosgWritingStatus(
      this.props.task.case.giosg_chat_id,
      `${this.props.userData.profile.firstName} ${this.props.userData.profile.lastName}`,
      this.props.task.case.room_id
    );
  };

  render() {
    return (
      <Translation ns="translations">
        {(t) => (
          <Form reply={true} style={{ marginTop: '20px' }}>
            <Form.Field>
              <label>{t('ADD_COMMENT_CANNED_RESPONSE')}</label>
              <ReplyTemplates
                channel={this.channel}
                userData={this.props.userData}
                ticketType={this.props.ticketType}
                entities={this.props.entities}
                templates={this.props.templates}
                task={this.props.task}
                selectedOption={this.state.selectedReplyTemplate}
                setSelectedOption={(value) => this.setState({ selectedReplyTemplate: value })}
                setContent={(value) => this.updateState({ ...value })}
                insertAtCursor={(value, content) => {
                  value.content = insertAtCursor(this.editor, content);
                  this.updateState({ ...value });
                }}
                content={this.state.content}
                users={this.props.users}
                discardHtml={true}
              />
            </Form.Field>
            {FeatureFlags.isFlagOn('ENABLE_EGAIN') && (
              <Form.Field>
                <KnowledgeBank
                  title={this.state.content}
                  id={this.props.taskId}
                  extraArguments={this.props.ticketType.knowledgeBank}
                  content={this.state.content}
                  updateState={(value: string) => {
                    this.updateState({ content: value });
                  }}
                />
              </Form.Field>
            )}
            <ReplyVCDrafts taskId={this.props.taskId} channelId={this.channel} onClickLoad={this.loadVCDraft} />
            <Form.Field id="commentContentField">
              <label>{t('ADD_COMMENT_CONTENT')}</label>
              <ReplyTextArea
                ref={(ref) => (this.editor = ref)}
                onKeyDown={(event: KeyDownEvent) => {
                  this.handleHotKeys(event);
                  this.updateWritingStatus();
                }}
                content={this.state.content}
                onChange={this.handleChangeContent}
              />
            </Form.Field>
            <Form.Group inline>
              <Form.Checkbox
                toggle={true}
                onClick={(event, data) => this.updateState({ endChatAfterSubmit: data.checked })}
                checked={this.state.endChatAfterSubmit}
                label={t('ADD_COMMENT_CLOSE_CHAT')}
              />
              <Form.Checkbox
                style={{ paddingLeft: '27px' }}
                toggle={true}
                onClick={() => {
                  this.props.switchChatAnchor(!Roles.isChatAnchored(this.props.userData));
                }}
                checked={Roles.isChatAnchored(this.props.userData)}
                label={t('ANCHOR_CHAT')}
              />
            </Form.Group>
            <ReplyControlButtons
              small={this.props.smallButtons}
              disabled={this.state.isLoading || !this.state.content.length}
              disabledCloseAsDone={!this.props.canCloseAsDone}
              loading={this.state.isLoading}
              onClear={this.clearFields}
              onSaveDraft={() => this.saveVCDraft(false)}
              onSubmit={() => this.submitComment()}
              onSubmitAndTicketStatusChange={(status?: TicketStatusAfterCommentSubmit) => this.submitComment(status)}
            />
          </Form>
        )}
      </Translation>
    );
  }
}

export default ReplyGiosg;

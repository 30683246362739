import type { Priority } from 'src/types/Priority';
import { apiClient } from 'src/Utilities/httpClients';

export default class PriorityApi {
  static addPriority = (data: any): Promise<any> => {
    return apiClient.put('/priority/', data).then((response) => {
      return response.data;
    });
  };

  static patchPriority = (data: any): Promise<any> => {
    return apiClient.patch('/priority/' + data.id, data.data).then((response) => {
      return response.data;
    });
  };

  static getPriorities = (): Promise<Priority[]> => {
    return apiClient.get('/priority/').then((response) => {
      return response.data;
    });
  };
}

import type { VFC } from 'react';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Icon } from 'semantic-ui-react';
import { activateTab } from 'src/actions/tabActionsRTK';
import { activateTicket } from 'src/actions/ticketsActions';
import type { FloatingWindowState } from 'src/types/FloatingWindow';

import * as styles from './FloatingWindow.style';

interface FloatingWindowHeaderProps {
  fullScreen: boolean;
  window: FloatingWindowState;

  onClose(): void;
  foldToggle(): void;
  fullScreenToggle?(fs: boolean): void;
}

const FloatingWindowHeader: VFC<FloatingWindowHeaderProps> = ({
  window: win,
  fullScreen,
  onClose,
  foldToggle,
  fullScreenToggle
}) => {
  const dispatch = useDispatch();

  const onTitleClick = useCallback(() => {
    dispatch(activateTicket(win.id));
    dispatch(activateTab(win.id));
  }, [win.id]);

  return (
    <Card.Header style={styles.cardHeader}>
      <h4 style={styles.heading} onClick={onTitleClick}>
        {win.title}
      </h4>

      <div style={styles.controlsWrapper}>
        <Icon name={win.fold ? 'window restore' : 'window minimize'} onClick={foldToggle} />
        {typeof fullScreenToggle === 'function' && (
          <Icon name={fullScreen ? 'window restore' : 'window maximize'} onClick={fullScreenToggle} />
        )}
        <Icon name="window close" onClick={onClose} />
      </div>
    </Card.Header>
  );
};

export default React.memo(FloatingWindowHeader);

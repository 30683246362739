import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';

import { hasPhoneIntegrations } from 'src/Utilities/phoneIntegrations';
import styles from './styles.module.css';

interface Shortcut {
  keys: string[];
  description: string;
}

const ShortcutsModal = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(!openModal);
  const { hasElisaOCIntegration, hasElisaRingIntegration, hasMitelIntegration } = hasPhoneIntegrations();

  // It has to be this way because it didn't work on windows with a Finnish keyboard
  useHotkeys('shift+*', ({ key }) => key === '?' && toggleModal(), {}, [toggleModal]);

  if (!openModal) {
    return null;
  }

  let integrationKey = 4;
  const shortcuts = [
    {
      keys: ['⌘', '/', 'ctrl', '+', 'enter', ':'],
      description: t('keyboardShortcuts.in_editor_send')
    },
    {
      keys: ['⌥', '/', 'alt', '+', 'c', ':'],
      description: t('CLOSE_AS_DONE')
    },
    {
      keys: ['⌥', '/', 'alt', '+', 's', ':'],
      description: t('GENERAL_SEND_AND_CLOSE')
    },
    {
      keys: ['⌥', '/', 'alt', '+', 'n', ':'],
      description: t('keyboardShortcuts.new_ticket')
    },
    {
      keys: ['⌥', '/', 'alt', '+', 'a', ':'],
      description: t('keyboardShortcuts.focus_reply_content')
    },
    {
      keys: ['⌥', '/', 'alt', '+', '1', ':'],
      description: t('keyboardShortcuts.toggle_todo')
    },
    {
      keys: ['⌥', '/', 'alt', '+', '2', ':'],
      description: t('keyboardShortcuts.toggle_doing')
    },
    {
      keys: ['⌥', '/', 'alt', '+', '3', ':'],
      description: t('keyboardShortcuts.toggle_done')
    },
    hasElisaOCIntegration && {
      keys: ['⌥', '/', 'alt', '+', integrationKey++, ':'],
      description: t('keyboardShortcuts.toggle_elisa_oc')
    },
    hasElisaRingIntegration && {
      keys: ['⌥', '/', 'alt', '+', integrationKey++, ':'],
      description: t('keyboardShortcuts.toggle_elisa_ring')
    },
    hasMitelIntegration && {
      keys: ['⌥', '/', 'alt', '+', integrationKey++, ':'],
      description: t('keyboardShortcuts.toggle_mitel')
    }
  ].filter(Boolean) as Shortcut[];

  return (
    <Modal
      data-test="ShortcutsModal"
      className={styles.container}
      dimmer={<Modal.Dimmer className={styles.dimmer} />}
      size="small"
      open={openModal}
      onClose={toggleModal}
    >
      <h4>{t('keyboardShortcuts.title')}</h4>
      <hr />

      <table>
        <tbody>
          {shortcuts.map(({ keys, description }, index) => (
            <tr key={index}>
              <th>
                {keys.map((key, index) => (
                  <span key={index}>{key} </span>
                ))}
              </th>
              <td>{description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  );
};

export default ShortcutsModal;

import EmptyContent from './EmptyContent';
import InternalContent from './InternalContent';
import SmsContent from './SmsContent';
import { EmailContent, SecureEmailContent } from './EmailContent';
import { WhatsappContent } from './WhatsappContent';
import { TampuuriContent } from './TampuuriContent';
import PhoneContent from './PhoneContent';
import ChannelType from './ChannelType';
import type { Channel } from 'src/types/Channel';
import type { TicketType } from 'src/types/TicketType';

interface CommentIconContentProps {
  channel?: ChannelType;
  ticketTypes: TicketType[];
  channels: Channel[];
  metaData: null | { [x: string]: any };
  title: string | null;
}

const contents = {
  [ChannelType.Email]: EmailContent,
  [ChannelType.Internal]: InternalContent,
  [ChannelType.Sms]: SmsContent,
  [ChannelType.SecureEmail]: SecureEmailContent,
  [ChannelType.WhatsApp]: WhatsappContent,
  [ChannelType.Tampuuri]: TampuuriContent,
  [ChannelType.Phone]: PhoneContent
};

function CommentIconContent(props: CommentIconContentProps): JSX.Element | null {
  if (!props.channel) {
    return null;
  }

  return (contents[props.channel] ?? EmptyContent)(props);
}

export default CommentIconContent;

import * as React from 'react';
import EmptyContent from './EmptyContent';
import type ChannelType from './ChannelType';

export interface WhatsappContentProps {
  channel: ChannelType;
  metaData?: { [x: string]: any } | null;
}

export function WhatsappContent(props: WhatsappContentProps) {
  if (props?.metaData == null) {
    return EmptyContent(props);
  }
  return (
    <div>
      <b>Whatsapp</b>
      <hr />
      <div>Phone: {props.metaData.phone}</div>
    </div>
  );
}

import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Loader, Comment, Icon } from 'semantic-ui-react';
import AccordionHeader from '../AccordionHeader';
import { t } from 'i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { uniq } from 'lodash';

import { State } from 'src/types/initialState';
import { TIME_SECONDS_FORMAT } from 'src/Utilities/dates';
import GenerateTranscriptionsSummary from './ChatGPT/GenerateTranscriptionsSummary';
import { TranscriptionMessage, TranscriptionsWidgetProps } from './transcriptionsWidgetTypes';
import { apiClient } from 'src/Utilities/httpClients';

export default function TranscriptionsWidget(props: TranscriptionsWidgetProps) {
  const [data, setData] = useState<TranscriptionMessage[]>([]);
  const reduxData = useSelector(
    (state: State) => state.transcriptions.transcriptionsByContentId[props.id.substring(3)] || []
  );
  const [isLoading, setIsLoading] = useState(false);

  const messages = uniq([...(reduxData || []), ...data]);

  useEffect(() => {
    setIsLoading(true);
    const url = `/transcriptions/content/${props.id.substring(3)}`;
    apiClient
      .get(url)
      .then((response: AxiosResponse<TranscriptionMessage[]>) => {
        setData(response.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [props.id]);

  if (isLoading) {
    return <Loader active inline />;
  }

  return (
    <AccordionHeader
      as="h4"
      active={props.widgetOpen}
      title={t('CASE_TRANSCRIPTIONS')}
      icon="inbox"
      key={`case-transcriptions`}
    >
      <GenerateTranscriptionsSummary id={parseInt(props.id.substring(3), 10)} />
      <Comment.Group style={{ paddingLeft: '2px', paddingRight: '10px', maxHeight: '500px', overflowY: 'scroll' }}>
        {messages.map((message: TranscriptionMessage) => {
          const speakerIcon = {
            customer: <Icon name="user" />,
            agent: <Icon name="user secret" />
          }[message.speakerType] || <Icon name="question circle" />;

          return (
            <Comment
              style={{
                width: '90%',
                float: message.speakerType === 'customer' ? 'left' : 'right'
              }}
            >
              <Comment.Content>
                <Comment.Author>
                  {speakerIcon}
                  {t('CASE_TRANSCRIPTION_' + message.speakerType.toUpperCase())} ({message.speakerId})
                  <Comment.Metadata style={{ float: 'right', textDecoration: 'none' }}>
                    {moment(message.startingTimestamp).format(TIME_SECONDS_FORMAT)}
                    {/* {moment(message.endingTimestamp).format(TIME_SECONDS_FORMAT)} */}
                  </Comment.Metadata>
                </Comment.Author>
                <Comment.Text>{message.text}</Comment.Text>
              </Comment.Content>
            </Comment>
          );
        })}
      </Comment.Group>
    </AccordionHeader>
  );
}

import * as React from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';

import type { PersonalData, User } from 'src/types/User';
import type { WorkStatus, WorkStatusItem } from 'src/types/RealTimeService';

export interface OwnProps {
  caseId: string;
  user: User;
  userData: PersonalData;

  onStartWorking: (UID: string) => void;
  onStopWorking: (UID: string) => void;
  onReplaceWorking: (oldUID: string, newUID: string) => void;
}

interface StateProps {
  workStatus: WorkStatus;
  userData: PersonalData;
}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps;

interface OwnAndStateProps extends OwnProps, StateProps {}

interface State {}

function mapStateToProps(state: any, ownProps: OwnProps): OwnAndStateProps {
  return {
    workStatus: state.workStatus,
    userData: state.userData,
    caseId: ownProps.caseId,
    onStartWorking: ownProps.onStartWorking,
    onStopWorking: ownProps.onStopWorking,
    onReplaceWorking: ownProps.onReplaceWorking,
    user: ownProps.user
  };
}

// TODO: refactor to functional component to simplify component and remove unused props
class WorkStatusImage extends React.Component<Props, State> {
  private startWorking = () => {
    this.props.onStartWorking(this.props.userData.UID);
  };

  private stopWorking = () => {
    this.props.onStopWorking(this.props.userData.UID);
  };

  private replaceWorking = () => {
    const currentStatus = this.props.workStatus.status.find((status: WorkStatusItem) => {
      return 'TSK' + status.ticketId === this.props.caseId;
    });
    if (typeof currentStatus !== 'undefined') {
      this.props.onReplaceWorking(currentStatus.UID, this.props.userData.UID);
    }
  };

  render() {
    const currentStatus = this.props.workStatus.status.find((status: WorkStatusItem) => {
      return 'TSK' + status.ticketId === this.props.caseId;
    });
    let currentlyWorkingOn;
    let someoneElseIsWorking;

    // currentlyWorking on if:
    // there is userData available
    // there is someone working for this task
    // that someone is me
    const hasUserData = typeof this.props.userData !== 'undefined' && typeof this.props.userData.UID !== 'undefined';
    if (
      hasUserData &&
      typeof currentStatus !== 'undefined' &&
      parseInt(currentStatus.UID, 10) === parseInt(this.props.userData.UID.substring(3), 10)
    ) {
      currentlyWorkingOn = true;
      someoneElseIsWorking = false;
    } else if (
      hasUserData &&
      typeof currentStatus !== 'undefined' &&
      parseInt(currentStatus.UID, 10) !== parseInt(this.props.userData.UID.substring(3), 10)
    ) {
      currentlyWorkingOn = false;
      someoneElseIsWorking = true;
    } else {
      currentlyWorkingOn = false;
      someoneElseIsWorking = false;
    }

    if (currentlyWorkingOn) {
      return (
        <Translation ns="translations">
          {(tr) => (
            <Button
              disabled={!this.props.userData.permissions.includes('updateContent')}
              onClick={this.stopWorking}
              negative={true}
              icon={true}
              id="stopTicketWorkingBtn"
              labelPosition="left"
              style={{
                width: '100%',
                textAlign: 'center'
              }}
            >
              <Icon name="minus" />
              {tr('LIVE_REMOVE_FROM_UNDER_WORKING')}
            </Button>
          )}
        </Translation>
      );
    } else if (someoneElseIsWorking) {
      return (
        <Translation ns="translations">
          {(tr) => (
            <Button
              disabled={!this.props.userData.permissions.includes('updateContent')}
              onClick={this.replaceWorking}
              color="orange"
              icon={true}
              labelPosition="left"
            >
              <Icon name="exchange" />
              {tr('LIVE_REPLACE_FROM_UNDER_WORKING')}
            </Button>
          )}
        </Translation>
      );
    } else {
      return (
        <Translation ns="translations">
          {(tr) => (
            <Button
              disabled={!this.props.userData.permissions.includes('updateContent')}
              onClick={this.startWorking}
              positive={true}
              icon={true}
              id="startTicketWorking"
              className="topBarStartTicketWorkingBtn"
              labelPosition="left"
              style={{ width: '100%', textAlign: 'center' }}
            >
              <Icon name="plus" />
              {tr('LIVE_ADD_TO_UNDER_WORKING')}
            </Button>
          )}
        </Translation>
      );
    }
  }
}

export default connect<StateProps, DispatchProps, OwnProps>(mapStateToProps)(WorkStatusImage);

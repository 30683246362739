import { TabFilter } from 'src/types/Filter';
import { Direction, SortBy } from 'src/types/Sorting';

export enum FilterPresetType {
  global = 'global',
  personal = 'personal'
}

export enum FilterPresetView {
  main = 'main',
  infopage = 'infopage'
}

export type FilterPresetPayload = {
  name: string;
  filters: TabFilter;
  sorting: SortBy;
  direction: Direction;
  isFavourite: boolean;
  isActive: boolean;
  type: FilterPresetType;
  ticketTypes: number[];
  view: FilterPresetView;
};

export type FilterPreset = FilterPresetPayload & {
  id: number;
  createdBy: number;
  created: number;
  updated: number;
  updatedBy: number;
};

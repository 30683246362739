import { apiClient } from 'src/Utilities/httpClients';
import type { FilterPreset, FilterPresetPayload, FilterPresetView } from './types';

export default class FilterPresetsApi {
  /**
   * Used to get filters for personal filters widget - returns current-user-created filters and global ones
   */
  static getWidgetFilterPresets = (view: FilterPresetView) => {
    try {
      return apiClient.get<FilterPreset[]>(`/filter-presets/widget/${view}`).then((response) => response.data);
    } catch (err: any) {
      console.error(`[getWidgetFilterPresets]: failed ${err?.response?.data ?? err?.message}`);
      throw err;
    }
  };

  /**
   * Used to get filters for management page - returns all current user filters
   */
  static getFilterPresets = () => {
    try {
      return apiClient.get<FilterPreset[]>('/filter-presets').then((response) => response.data);
    } catch (err: any) {
      console.error(`[getUserFilterPresets]: failed ${err?.response?.data ?? err?.message}`);
      throw err;
    }
  };

  static createFilterPreset = (payload: FilterPresetPayload) => {
    try {
      return apiClient.post<FilterPreset>('/filter-presets', payload).then((response) => response.data);
    } catch (err: any) {
      console.error(`[createFilterPreset]: failed ${err?.response?.data ?? err?.message}`);
      throw err;
    }
  };

  static updateFilterPreset = (id: number, payload: FilterPresetPayload) => {
    try {
      return apiClient.patch<FilterPreset>(`/filter-presets/${id}`, payload).then((response) => response.data);
    } catch (err: any) {
      console.error(`[updateFilterPreset]: failed ${err?.response?.data ?? err?.message}`);
      throw err;
    }
  };

  static deleteFilterPreset = async (id: number) => {
    try {
      await apiClient.delete<{ success: boolean }>(`/filter-presets/${id}`).then((response) => response.data);
    } catch (err: any) {
      console.error(`[deleteFilterPreset]: failed ${err?.response?.data ?? err?.message}`);
      throw err;
    }
  };
}

import React, { useEffect, useRef } from 'react';

import type { VFC } from 'react';

import styles from './CommentTextEncapsulated.css?inline';

type CommentTextEncapsulatedProps = {
  setCommentTextHeight?: (height: number) => void;

  comment?: string | null;
};

const styleElement = `<style>${styles}</style>`;

const CommentTextEncapsulated: VFC<CommentTextEncapsulatedProps> = ({ comment, setCommentTextHeight }) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let animationFrameId: number;

    if (divRef.current && comment) {
      let { shadowRoot } = divRef.current;
      if (!shadowRoot) {
        shadowRoot = divRef.current.attachShadow({ mode: 'open' });
      }

      shadowRoot.innerHTML = `${styleElement}<div class="commentTextEncapsulatedContainer">${comment}</div>`;

      animationFrameId = requestAnimationFrame(() => {
        if (typeof setCommentTextHeight === 'function' && divRef.current) {
          setCommentTextHeight(divRef.current.clientHeight);
        }
      });
    }

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [comment]);

  return <div ref={divRef}></div>;
};

export default CommentTextEncapsulated;

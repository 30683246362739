import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Dropdown, Icon } from 'semantic-ui-react';

import type { ComponentProps, VFC } from 'react';
import type { Button } from 'semantic-ui-react';

import FeatureFlags from 'src/api/FeatureFlags';
import ShrinkableButton from 'src/Components/generic/ShrinkableButton';
import { TicketStatusAfterCommentSubmit } from 'src/types/Salesforce';

interface RPBProps {
  small?: boolean;
  disabled?: boolean;
  disabledCloseAsDone?: boolean;
  internal?: boolean;
  loading?: boolean;
  onClear: () => void;
  onSaveDraft?: () => void;
  onSubmit: () => void;
  onSubmitAndTicketStatusChange?: (status: TicketStatusAfterCommentSubmit) => void;
}

const ReplyControlButtons: VFC<RPBProps> = ({
  small = false,
  disabled,
  disabledCloseAsDone,
  internal,
  loading,
  onClear,
  onSaveDraft,
  onSubmit,
  onSubmitAndTicketStatusChange
}) => {
  const { t } = useTranslation();
  const onClickSend = () => {
    if (!disabled) {
      onSubmit();
    }
  };

  const onClickSendAndStatusChange = (status: TicketStatusAfterCommentSubmit) => () => {
    if (
      FeatureFlags.isFlagOn('SHOW_SEND_AND_CLOSE_AS_DONE') &&
      !disabled &&
      !disabledCloseAsDone &&
      onSubmitAndTicketStatusChange
    ) {
      onSubmitAndTicketStatusChange(status);
    }
  };

  useHotkeys('ctrl+enter', onClickSend, undefined, [onClickSend]);
  useHotkeys('alt+s', onClickSendAndStatusChange(TicketStatusAfterCommentSubmit.done), undefined, [
    onClickSendAndStatusChange
  ]);

  const submitButtonText = internal ? t('GENERAL_SAVE') : t('GENERAL_SEND');
  const labelPosition: ComponentProps<typeof Button>['labelPosition'] = !small ? 'left' : undefined;

  return (
    <div style={{ marginBottom: '15px' }}>
      <ShrinkableButton
        negative
        as={'span'}
        shrink={small}
        iconName="eraser"
        labelPosition={labelPosition}
        onClick={onClear}
      >
        {t('GENERAL_CLEAR')}
      </ShrinkableButton>

      <ShrinkableButton
        id="commentReplySaveBtn"
        primary
        shrink={small}
        loading={loading}
        disabled={disabled}
        labelPosition={labelPosition}
        iconName={internal ? 'save' : 'send'}
        onClick={onClickSend}
      >
        {submitButtonText}
      </ShrinkableButton>

      {typeof onSubmitAndTicketStatusChange === 'function' && FeatureFlags.isFlagOn('SHOW_SEND_AND_CLOSE_AS_DONE') && (
        <ButtonGroup color="blue">
          <ShrinkableButton
            onClick={() => onSubmitAndTicketStatusChange(TicketStatusAfterCommentSubmit.done)}
            disabled={disabled || disabledCloseAsDone}
          >
            <span>{t('GENERAL_SEND_AND_CLOSE')}</span>
          </ShrinkableButton>
          <Dropdown
            selectOnNavigation={false}
            selectOnBlur={false}
            trigger={<Icon name="chevron down" />}
            floating
            className="button icon"
            icon={false}
            loading={loading}
            disabled={disabled || disabledCloseAsDone}
          >
            <Dropdown.Menu>
              <Dropdown.Item icon onClick={() => onSubmitAndTicketStatusChange(TicketStatusAfterCommentSubmit.todo)}>
                <Icon name="exclamation" />
                <span>{t('GENERAL_SEND_AND_CLOSE_AS_TODO')}</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onSubmitAndTicketStatusChange(TicketStatusAfterCommentSubmit.doing)}>
                <Icon name="clock" />
                <span>{t('GENERAL_SEND_AND_CLOSE_AS_DOING')}</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onSubmitAndTicketStatusChange(TicketStatusAfterCommentSubmit.done)}>
                <Icon name="check" />
                <span>{t('GENERAL_SEND_AND_CLOSE')}</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </ButtonGroup>
      )}

      {typeof onSaveDraft === 'function' && (
        <ShrinkableButton
          id="descriptionFieldEditSaveDraftBtn"
          shrink={small}
          floated="right"
          iconName="save"
          labelPosition={labelPosition}
          onClick={onSaveDraft}
        >
          {t('GENERAL_SAVE_DRAFT')}
        </ShrinkableButton>
      )}
    </div>
  );
};

export default React.memo(ReplyControlButtons);

import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type { Direction } from '../types/Sorting';
import { DefaultCommentsSorting } from '../types/Sorting';
import { getURLParam } from '../Utilities/helper';

export interface SortingState {
  sorting: Direction;
  showEvents: boolean;
  showImages?: boolean;
}

const param = getURLParam<Direction>(window.location.search, 'comments');
export const commentsMenuInitialState: SortingState = {
  sorting: param ?? DefaultCommentsSorting,
  showEvents: false
};

const commentsMenuReducers = createSlice({
  name: 'commentsMenu',
  initialState: commentsMenuInitialState,
  reducers: {
    setCommentsSorting: (draft, action: PayloadAction<Direction>) => {
      draft.sorting = action.payload;
    },
    setShowEvents: (draft, action: PayloadAction<boolean>) => {
      draft.showEvents = action.payload;
    },
    setShowImages: (draft, action: PayloadAction<boolean>) => {
      draft.showImages = action.payload;
    },
    unsetShowImages: (draft) => {
      delete draft.showImages;
    }
  }
});

export const { setCommentsSorting, setShowEvents, setShowImages, unsetShowImages } = commentsMenuReducers.actions;

export default commentsMenuReducers.reducer;

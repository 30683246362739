import sortBy from 'lodash/sortBy';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';

import type { ConnectedProps } from 'react-redux';
import type { AnyAction } from 'redux';
import type { ThunkDispatch } from 'redux-thunk';

import AccordionHeader from '../AccordionHeader';
import { activateContentListTab, addContentListTab } from 'src/actions/CaseListActions';
import {
  fetchTicketsByEntity,
  getTicketEntityById,
  removeEntityFromCase,
  updateEntityDetails
} from 'src/actions/ticketsActions';
import CustomerContainer from 'src/Components/Customer/CustomerContainer';
import ErrorBoundary from 'src/ErrorBoundary';

import type { UpdateEntityDetail } from 'src/api/TicketsApi';
import type { State } from 'src/types/initialState';
import type { Entity, Ticket } from 'src/types/Ticket';
import type { FieldSet } from 'src/types/TicketType';

interface CustomerInfoProps extends ConnectedProps<typeof connector> {
  widgetOpen: boolean;
  itemsClosed?: boolean;
  task: Ticket;

  fireSearch(value: any, type: string): void;
}

const CustomerInfo = ({
  task,
  widgetOpen,
  itemsClosed,
  fireSearch,
  // redux
  userData,
  ticketTypes,
  getEntityById,
  removeEntityFromCase,
  updateEntityDetails,
  onSearchPreviousTickets
}: CustomerInfoProps) => {
  const selectedTicketType = ticketTypes.find((t) => t.name === task.taskType);
  const fieldSets = selectedTicketType?.fieldSets || [];

  const sortedEntities = useMemo(() => {
    return sortBy(task.entities, ['_type', '_id']).reduce((previousValue, currentValue) => {
      const previousArray = previousValue[currentValue._type] !== undefined ? previousValue[currentValue._type] : [];
      previousValue[currentValue._type] = [...previousArray, currentValue];
      return previousValue;
    }, {} as Record<string, Entity[]>);
  }, [JSON.stringify(task.entities)]);

  return (
    <>
      {Object.keys(sortedEntities).map((entityKey, idx) => {
        const fields =
          fieldSets.find(({ entityTypes }: FieldSet) => !!entityTypes?.includes?.(entityKey)) ??
          fieldSets.find(({ id }: FieldSet) => id === entityKey) ??
          fieldSets.find(({ id }: FieldSet) => id === 'customerInfo');

        return (
          <ErrorBoundary key={`case-customer-info-error-boundary-${idx}`}>
            <div className={'customer-container-color'}>
              <AccordionHeader
                as="h4"
                active={widgetOpen}
                title={`${fields?.displayName} (${sortedEntities[entityKey].length})`}
                icon="address card"
                key={`case-customer-info-${idx}`}
                id={`case-customer-info-${idx}`}
              >
                {sortedEntities[entityKey].map((entity, idx) => {
                  return (
                    <CustomerContainer
                      key={`case-customer-container-${idx}`}
                      fireSearch={fireSearch}
                      generalDisable={!userData.permissions.includes('searchEntities')}
                      getEntityById={getEntityById}
                      personalData={userData}
                      collapsed={!!itemsClosed}
                      onDetachEntity={(body: { _id: string; _type: string }) => {
                        removeEntityFromCase(task.id, body);
                      }}
                      onSave={(updateArgs: UpdateEntityDetail) => {
                        updateEntityDetails(task.id, task.taskType, updateArgs);
                      }}
                      onSearchPreviousTickets={onSearchPreviousTickets}
                      fields={(fields && fields[fields.id]) || []}
                      additionalCustomerFieldSets={fields?.additionalFieldSets}
                      values={[entity] || []}
                      task={task}
                      ticketTypes={ticketTypes}
                      entityType={entityKey}
                    />
                  );
                })}
              </AccordionHeader>
            </div>
          </ErrorBoundary>
        );
      })}
    </>
  );
};

const connector = connect(
  (state: State) => ({
    ticketTypes: state.ticketTypes,
    userData: state.userData
  }),
  (dispatch: ThunkDispatch<State, any, AnyAction>) => ({
    getEntityById: (ticketId: string, taskType: string, entityId: string, entityType: string) => {
      dispatch(getTicketEntityById(ticketId, taskType, entityId, entityType));
    },
    removeEntityFromCase: (
      ticketId: string,
      body: {
        _id: string;
        _type: string;
      }
    ) => {
      dispatch(removeEntityFromCase(ticketId, body));
    },
    updateEntityDetails: (ticketId: string, taskType: string, updateArgs: UpdateEntityDetail) => {
      dispatch(updateEntityDetails({ ticketId, taskType, updateArgs }));
    },
    onSearchPreviousTickets: (entityId: string, entityType: string) => {
      dispatch(addContentListTab(entityId, 'TAB_NAME_CUSTOMER #' + entityId.toString().substring(0, 3), 'tickets'));
      dispatch(activateContentListTab(entityId, 'tickets'));
      dispatch(fetchTicketsByEntity(entityId, entityId, entityType));
    }
  })
);

export default connector(CustomerInfo);

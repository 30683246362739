import { Chat } from './../types/Chat';
import { apiClient } from 'src/Utilities/httpClients';

export default class ChatsApi {
  static getChats = (): Promise<Chat[]> => {
    return apiClient.get('/chatStatuses/').then((response) => {
      return response.data;
    });
  };
  static patchChat = (data: any): Promise<any> => {
    return apiClient.patch('/chatStatus/' + data.id, data.data).then((response) => {
      return response.data;
    });
  };
  static patchAllChats = (data: any): Promise<any> => {
    return apiClient.patch('/chatStatus/all', data).then((response) => {
      return response.data;
    });
  };
  static createChat = (data: any): Promise<any> => {
    return apiClient
      .post('/chatStatuses/', data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });
  };
}

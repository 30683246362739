import React, { useState } from 'react';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import type { VFC } from 'react';

import AddRelatedTicketById from 'src/Components/Case/Widget/TicketRelationsWidget/AddRelatedTicketById';
import { addRelatedTicketToTicket, createNewSubTicket } from 'src/actions/ticketsActions';
import { defaultInfoPage } from 'src/api/InfoPage';
import { defaultTask } from 'src/api/Task';
import type { ContentTypes } from 'src/types/ContentTypes';
import type { PersonalData } from 'src/types/User';
import type { RelationOptions } from 'src/types/LinkedTickets';
import type { Ticket } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';

interface Props {
  attachPlaceholderText: string;
  createText: string;
  relationOptions: RelationOptions,
  task: Ticket;
  ticketTypes: TicketType[];
  type: ContentTypes;
  userData: PersonalData;
}

const TicketRelationsForm: VFC<Props> = ({
  attachPlaceholderText,
  createText,
  relationOptions,
  task,
  ticketTypes,
  type,
  userData,
}) => {
  const [relationType, setRelationType] = useState('parentToChild');
  const dispatch = useDispatch();

  const createNewSubContent = async (
    type: ContentTypes,
    defaultTaskType: string | number,
    parentId: string,
    ticketRelationType: string
  ) => {
    switch (type) {
      case 'task': {
        const newTask = defaultTask(defaultTaskType);
        dispatch(createNewSubTicket('task', newTask, true, parentId.substring(3), ticketRelationType));
        break;
      }
      case 'infopage': {
        if (typeof defaultTaskType === 'number') {
          defaultTaskType = defaultTaskType.toString();
        }

        const newPage = defaultInfoPage(defaultTaskType);
        dispatch(createNewSubTicket('infopage', newPage, true, parentId.substring(3), ticketRelationType));
        break;
      }
    }
  }

  return (
    <>
      <Dropdown
        clearable={false}
        data-test-id="ticketRelationsDropdown"
        disabled={!userData.permissions.includes('updateContent')}
        fluid={true}
        multiple={false}
        noResultsMessage={''}
        options={relationOptions}
        placeholder={''}
        scrolling={true}
        search={false}
        selectOnBlur={false}
        selection={true}
        size="small"
        style={{ marginBottom: '0.5rem' }}
        value={relationType || relationOptions[0].value}
        onChange={(e: any, data: any) => {
          setRelationType(data.value);
        }}
      />

      <div className="addRelatedTicketDiv" style={{ display: 'flex' }}>
        <Button
          disabled={!userData.permissions.includes('updateContent')}
          icon={true}
          labelPosition="right"
          style={{ width: '100%' }}
          onClick={() => {
            createNewSubContent(
              type,
              ticketTypes.find(
                (ticketType) => ticketType.id === userData.userPreferences.defaultTicketType
              )!.name,
              task.id,
              relationType
            );
          }}
        >
          <Icon name="plus" style={{ marginRight: '4px', float: 'right', fontSize: '16px' }} />
          {createText}
        </Button>

        <AddRelatedTicketById
          placeholder={attachPlaceholderText}
          addRelatedTicketToTicket={(targetId) => {
            dispatch(addRelatedTicketToTicket(task.id, targetId, relationType));
          }}
        />
      </div>
    </>
  );
};

export default TicketRelationsForm;

import { userToNumericalId } from 'src/Utilities/users';
import type { User } from 'src/types/User';

export const getUserById = (users: User[], UID?: number | string): User | undefined => {
  if (!UID) {
    return;
  }

  return users.find((usr) => userToNumericalId(usr.UID) === userToNumericalId(UID));
};

export const getProfileName = (user?: User) => {
  const { profile } = user || {};
  return `${profile?.firstName || ''} ${profile?.lastName || ''}`;
};

import { TFunction } from 'i18next';
import iziToast from 'izitoast';

interface ConfirmationToastProps {
  t: TFunction;
  onConfirm(): void;
  mergeTickets: string;
  mainTicket: string;
}

const openConfirmationToast = ({ t, onConfirm, mergeTickets, mainTicket }: ConfirmationToastProps) =>
  iziToast.question({
    timeout: 0,
    close: false,
    overlay: true,
    id: 'question',
    zindex: 999,
    backgroundColor: 'salmon',
    overlayClose: true,
    title: t('top_bar.advanced_actions.merge_tickets.title'),
    message: t('top_bar.advanced_actions.merge_tickets.message', { mergeTickets, mainTicket }),
    position: 'center',
    buttons: [
      [
        `<button><b>${t('YES')}</b></button>`,
        (instance, toast) => {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
          onConfirm();
        },
        true
      ],
      [
        `<button>${t('NO')}</button>`,
        function (instance, toast) {
          instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
        },
        false
      ]
    ]
  });

export default openConfirmationToast;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { FC } from 'react';

import FloatingWindow from 'src/Components/generic/FloatingWindow/FloatingWindow';
import { removeFloatingWindow, setWindowTop, updateFloatingWindow } from 'src/reducers/floatingWindowsReducer';
import type { FloatingWindowState } from 'src/types/FloatingWindow';

interface FloatingWindowWrapperProps {
  index: number;
  window: FloatingWindowState;
  children: React.ReactNode;

  extraContent?: React.ReactNode;
  minConstraints?: [number, number];

  onClose?(): void;
}

const FloatingWindowWrapper: FC<FloatingWindowWrapperProps> = ({
  index,
  window: win,
  children,
  extraContent,
  minConstraints,
  onClose: onWindowClose
}) => {
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(removeFloatingWindow(win.id));
    onWindowClose?.();
  }, [win.id]);
  const foldToggle = useCallback(() => {
    dispatch(updateFloatingWindow({ id: win.id, fold: !win.fold }));
  }, [win.id, win.fold]);
  const onDragStart = useCallback(() => {
    dispatch(setWindowTop(win.id));
  }, [win.id]);
  const onDragStop = useCallback(
    (_e, { x, y }) => {
      dispatch(updateFloatingWindow({ id: win.id, x, y }));
    },
    [win.id]
  );
  const onResizeStop = useCallback(
    (_e, data) => {
      dispatch(updateFloatingWindow({ id: win.id, width: data.size.width, height: data.size.height }));
    },
    [win.id]
  );

  return (
    <FloatingWindow
      index={index}
      window={win}
      onClose={onClose}
      foldToggle={foldToggle}
      onDragStart={onDragStart}
      onDragStop={onDragStop}
      onResizeStop={onResizeStop}
      extraContent={extraContent}
      minConstraints={minConstraints}
    >
      {children}
    </FloatingWindow>
  );
};

export default React.memo(FloatingWindowWrapper);

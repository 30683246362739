import _ from 'lodash';
import moment from 'moment';

import { getCommentSender } from 'src/Components/Utilities/comments';
import { store } from 'src/store';
import type { User } from 'src/types/User';
import type { Channel } from 'src/types/Channel';
import type { Comment, CommentFilters } from 'src/types/Ticket';

export const botButtonClickedState = (singleComment: Comment, listOfComments: Comment[]): undefined | Comment => {
  if (singleComment.metaData?.userClickedButton) {
    return [...listOfComments]
      .reverse()
      .find((comment) =>
        comment.metaData?.buttons?.some((button) => button.buttonState === singleComment.metaData?.userClickedButton)
      );
  }
  return undefined;
};

export const filterComments = (originalComments: Comment[], filters: CommentFilters | undefined) => {
  if (!filters || Object.keys(filters).length === 0) return originalComments;

  let comments = originalComments;
  const { direction, channels, author, text, createdAfter, createdBefore, hasAttachment } = filters;

  if (direction) {
    comments = comments.filter((comment) => comment.direction === direction);
  }

  if (Array.isArray(channels) && channels.length > 0) {
    comments = comments.filter((comment) => channels.indexOf(comment.channel!) !== -1);
  }

  if (author) {
    const channelsReduxState = store.getState().channels as Channel[];
    const usersListReduxState = store.getState().usersList.usersList as User[];
    comments = comments.filter((comment) =>
      author.includes(
        getCommentSender({
          entityData: _.get(comment, ['metaData', 'originalEntity']),
          channelData: channelsReduxState.find((channel) => channel.id === comment.channel),
          user: usersListReduxState.reduce((users, user) => {
            users[user.UID] = user;
            return users;
          }, {})[comment.createdByUser],
          metaData: comment.metaData,
          direction: comment.direction
        })
      )
    );
  }

  if (text) {
    comments = comments.filter((comment) => comment.content?.toLowerCase().indexOf(text.toLowerCase()) !== -1);
  }

  if (createdAfter || createdBefore) {
    comments = comments.filter((comment) => {
      const created = moment.unix(comment.created);
      return (
        (!createdAfter || created.isAfter(moment.unix(createdAfter))) &&
        (!createdBefore || created.isBefore(moment.unix(createdBefore)))
      );
    });
  }

  if (hasAttachment) {
    comments = comments.filter((comment) => !!comment.metaData?.attachments?.length);
  }

  return comments;
};

import axios from 'axios';
import axiosRetry from 'axios-retry';

export const NOT_ACCEPTABLE = 406;

// Client for querying Desk API
const apiClient = axios.create({
  withCredentials: true
});

// Client for everything else
const publicHttpClient = axios.create();

axiosRetry(apiClient, {
  retries: 5,
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay
});

axiosRetry(publicHttpClient, {
  retries: 5,
  shouldResetTimeout: true,
  retryDelay: axiosRetry.exponentialDelay
});

export { apiClient, publicHttpClient };

import { ActionCreator, AnyAction } from 'redux';
import { TOGGLE_MOBILE_MODE } from './index';

export interface MobileAction extends AnyAction {
  type: string;
  payload: Boolean;
}

export const toggleMobileMode: ActionCreator<MobileAction> = (payload: Boolean) => {
  return { type: TOGGLE_MOBILE_MODE, payload: payload };
};

import { ActionCreator, AnyAction } from 'redux';
import { SWIPE_LEFT, SWIPE_RIGHT } from './index';

export interface SwipeAction extends AnyAction {
  type: string;
}
export const swipeLeft: ActionCreator<SwipeAction> = () => {
  return { type: SWIPE_LEFT };
};

export const swipeRight: ActionCreator<SwipeAction> = () => {
  return { type: SWIPE_RIGHT };
};

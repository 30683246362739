import _ from 'lodash';
import socketIo from 'socket.io-client';
import type { Socket } from 'socket.io-client';

import ApiConfig from './api/ApiConfig';

class SocketInstance {
  public socket: Socket;
  public isInitialized: boolean;
  public _userId: string | null;

  constructor() {
    this.isInitialized = false;
    this._userId = null;
  }

  private acknowledgeMessage = (messageIdentifier: string): void => {
    this.socket.emit('acknowledgedMessages', { messageIdentifier });
  };

  // TODO: create enum with socket events list
  public initialize = async (
    userId: string,
    handlers: { [key: string]: (fn?: (messageIdentifier: string) => void) => (...args: any[]) => void }
  ) => {
    if (this.isInitialized) {
      return;
    }

    this._userId = userId;
    this.isInitialized = true;
    this.socket = socketIo(ApiConfig.getConfig().REAL_TIME_SERVICE_ADDRESS, {
      reconnectionDelay: 5000,
      reconnectionDelayMax: 10000,
      transports: ['websocket'],
      path: '/socket.io/',
      withCredentials: true,
      query: {
        UID: this._userId
      }
    });

    this.socket.on('connect', handlers.connect());
    this.socket.on('connect_error', handlers.connect_error());
    this.socket.on('disconnect', handlers.disconnect());

    this.socket.on('transcriptionsUpdated', handlers.transcriptionsUpdated(this.acknowledgeMessage));
    this.socket.on('contentUpdated', handlers.contentUpdated(this.acknowledgeMessage));
    this.socket.on('responseTemplatesUpdated', handlers.responseTemplatesUpdated(this.acknowledgeMessage));
    this.socket.on('tagsUpdated', handlers.tagsUpdated(this.acknowledgeMessage));
    this.socket.on('channelsUpdated', handlers.channelsUpdated(this.acknowledgeMessage));
    this.socket.on('categoriesUpdated', handlers.categoriesUpdated(this.acknowledgeMessage));
    this.socket.on('autoSuggestionsUpdated', handlers.autoSuggestionsUpdated(this.acknowledgeMessage));
    this.socket.on('chatStatusesUpdated', handlers.chatStatusesUpdated(this.acknowledgeMessage));
    this.socket.on('ticketTypesUpdated', handlers.ticketTypesUpdated(this.acknowledgeMessage));
    this.socket.on('userTicketTypeChanged', handlers.userTicketTypeChanged(this.acknowledgeMessage));
    this.socket.on('caseTicketTypeChanged', handlers.caseTicketTypeChanged(this.acknowledgeMessage));
    this.socket.on('usersUpdated', handlers.usersUpdated(this.acknowledgeMessage));
    this.socket.on('personalDataUpdated', handlers.personalDataUpdated(this.acknowledgeMessage));
    this.socket.on('prioritiesUpdated', handlers.prioritiesUpdated(this.acknowledgeMessage));
    this.socket.on('statusUpdates', handlers.statusUpdates());
    this.socket.on(
      'updateTicketsChatVisitorTypeStatus',
      handlers.updateTicketsChatVisitorTypeStatus(this.acknowledgeMessage)
    );
    this.socket.on('eIdentificationChanged', handlers.eIdentificationChanged(this.acknowledgeMessage));
    this.socket.on('filterPresetsChanged', handlers.filterPresetsChanged(this.acknowledgeMessage));
    this.socket.on('entityTagsUpdated', handlers.entityTagsUpdated(this.acknowledgeMessage));
  };

  public resendMessagesAfterTimestamp = _.throttle(
    (timestamp: number) => {
      if (this.socket?.connected) {
        this.socket.emit('resendMessages', { timestamp });
      } else {
        setTimeout(() => this.resendMessagesAfterTimestamp(timestamp), 1000);
      }
    },
    1000,
    { leading: false }
  );

  public disconnect = () => {
    if (this.socket !== undefined) {
      this.isInitialized = false;
      if (this.socket.connected) {
        this.socket.disconnect();
      }
      console.debug('User has disconnected from realtimeservice, resetting');
    }
  };

  public joinRoom = (id: string) => {
    if (this.socket === undefined || !this.socket.connected) {
      setTimeout(() => {
        this.joinRoom(id);
      }, 1000);
    } else {
      this.socket.emit('joinRoom', {
        room: id,
        UID: this._userId
      });
    }
  };

  public emit = (event: string, payload: { [key: string]: any }) => this.socket.emit(event, payload);

  /**
   * TODO: Refactor typing of incoming argument for `id`
   * RealtimeService is actually waiting for string with `TSK` prefix
   * Lots of `any` typing under leaveRoom invocations
   */
  public leaveRoom = (id: number | string) => {
    if (this.socket === undefined || !this.socket.connected) {
      setTimeout(() => {
        this.leaveRoom(id);
      }, 1000);
    } else {
      this.socket.emit('leaveRoom', {
        room: id,
        UID: this._userId
      });
    }
  };
}

export default new SocketInstance();

import React, { useMemo } from 'react';
import { Table } from 'semantic-ui-react';

import type { ComponentProps, VFC } from 'react';

import InfoField from 'src/Components/Case/Info/InfoField/InfoField';
import { getSalesforceObjectFieldsByType } from 'src/Components/Utilities/salesforce';

import type { ISalesforceSettingsData } from 'src/api/EnvSettings';
import type { SalesforceObjectType } from 'src/types/Salesforce';
import type { FieldSet } from 'src/types/TicketType';

type InfoFieldParams = ComponentProps<typeof InfoField>;

interface SalesforceObjectFormProps {
  fieldSet: FieldSet;
  type: SalesforceObjectType;
  settings: ISalesforceSettingsData;
  values: InfoFieldParams['values'];

  setValue: InfoFieldParams['setValue'];
}

const SalesforceObjectForm: VFC<SalesforceObjectFormProps> = ({ type, values, fieldSet, settings, setValue }) => {
  const fields =
    useMemo(() => getSalesforceObjectFieldsByType(type, fieldSet, settings), [type, fieldSet, settings]) ?? [];

  return (
    <Table>
      <Table.Body>
        {fields.map((field, index) => (
          <Table.Row key={`salesforce-tab-field-${index}`}>
            <InfoField
              field={{ ...field, params: { ...field.params, readOnly: false } }}
              fields={fields}
              values={values}
              setValue={setValue}
            />
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default React.memo(SalesforceObjectForm);

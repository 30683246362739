import type { InfopageStatusTypes, TicketListTicket } from '../types/Ticket';
import { Direction } from 'src/types/Sorting';

export interface InfoPageListItem extends TicketListTicket {
  status: InfopageStatusTypes;
}

// channel weights are used for sorting
// the higher value, the more higher priority
// not defined channels are using default weight (lowest priority)
const CHANNEL_WEIGHTS: { [x: number]: number } = {};
const DEFAULT_CHANNEL_WEIGHT = 10;

const infoPageVals = (t: InfoPageListItem, orderBy: keyof InfoPageListItem | null): any[] => [
  orderBy ? t[orderBy] || 0 : 0,
  t.priority,
  CHANNEL_WEIGHTS[t.channel] || DEFAULT_CHANNEL_WEIGHT,
  Number(t.dueDate) || 0
];

// compare two arrays of values, it ends on first inequal value
function arrayCmp(a: any[], b: any[]): -1 | 0 | 1 {
  let i = 0;
  while (i < a.length) {
    const va = a[i];
    const vb = b[i];
    if (va === vb) {
      i++;
      continue;
    }
    if (va > vb) return -1;
    return +1;
  }
  return 0;
}

export const orderInfoPageList = (
  infoPages: InfoPageListItem[],
  orderBy: keyof InfoPageListItem | null,
  mode: Direction
) => {
  if (mode === Direction.ASC) {
    return infoPages.sort((a, b) => arrayCmp(infoPageVals(a, orderBy), infoPageVals(b, orderBy)));
  } else {
    return infoPages.sort((a, b) => -arrayCmp(infoPageVals(a, orderBy), infoPageVals(b, orderBy)));
  }
};

import type { TicketArchivalUploadData } from '../types/Archival';
import { apiClient } from 'src/Utilities/httpClients';

const API_URL_SEND_ENTITY_EVENT = '/archival/submit';

export default class ArchivalApi {
  static sendArchivalRequest = (data: TicketArchivalUploadData): Promise<any> => {
    return apiClient.post(API_URL_SEND_ENTITY_EVENT, data).then((response) => {
      return response;
    });
  };
}

import { t as tr } from 'i18next';
import _ from 'lodash';
import React from 'react';
import { Translation } from 'react-i18next';
import { createFilter } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Button, Divider, Form, Icon, Label, Modal, Table } from 'semantic-ui-react';

import type { DropdownItemProps } from 'semantic-ui-react';

import ChannelType from '../CommentIconContent/ChannelType';
import { defaultCreatableSelectStyles } from '../Utilities';
import { trimAndSplitRecipients } from '../Utilities/recipients';
import FormDropzoneDropdown from './components/FormDropzoneDropdown';
import ReplyControlButtons from './components/ReplyControlButtons';
import ReplyVCDrafts from './components/ReplyVCDrafts';
import { ReplyMethod } from './ReplyMethod';
import ReplyTemplates from './ReplyTemplates';
import ReplyTextArea from './ReplyTextArea';
import { Channels } from 'src/types/Channel';
import { insertAtCursor } from 'src/Utilities/insertAtCursor';

import type { Option } from '../MultiSelectInput/MultiSelectInput';
import type { ReplyMethodProps } from './ReplyMethod';
import type { ResponseTemplate } from 'src/types/ResponseTemplate';
import type { Suggestion } from 'src/types/Suggestions';
import type { Attachment, Entity, Ticket, UploadFileResult } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';
import type { PersonalData, User } from 'src/types/User';

interface ReplyTampuuriProps extends ReplyMethodProps<ReplyTampuuriState> {
  userData: PersonalData;
  subject?: string;
  to: string;
  attachments: Array<Attachment>;
  ticketType: TicketType;
  taskType: TicketType;
  templates: Array<ResponseTemplate>;
  drafts?: Partial<ReplyTampuuriState>;
  task: Ticket;
  entities: Entity[];
  suggestions: Suggestion[];
  users: User[];

  // TODO: typing for functions
  onSubmit: (...args: any[]) => any;
  uploadFile: (ticketId: string, file: FormData) => Promise<UploadFileResult[]>;
  sendEntityEvent: (...args: any[]) => any;
}

interface ReplyTampuuriState {
  content: string;
  subject: string;
  to: string;
  isTampuuriOptionsOpen: boolean;
  isCaseDoneAfterSubmit: boolean;
  isSubmitModalOpen: boolean;
  isLoading: boolean;
  selectedAttachmentIds: string[];
  suggestionResults: Array<Suggestion>;
  searchLoading: boolean;
  selectedReplyTemplate: string | undefined;
  entitiesChanged?: boolean;
}

class ReplyTampuuri extends ReplyMethod<ReplyTampuuriProps, ReplyTampuuriState> {
  private channel = ChannelType.Tampuuri;
  private options: DropdownItemProps[];
  private editor: HTMLTextAreaElement | null;

  constructor(props: ReplyTampuuriProps) {
    super(props);
    this.state = this.getInitialState(this.props, false);
    this.options = this.getAttachmentsOptions(props.attachments);
  }

  getDraftChannel(): Channels {
    return Channels.tampuuri;
  }

  getDraftState(state: ReplyTampuuriState): Partial<ReplyTampuuriState> {
    return {
      content: state.content,
      isCaseDoneAfterSubmit: state.isCaseDoneAfterSubmit,
      subject: state.subject,
      to: state.to,
      selectedAttachmentIds: state.selectedAttachmentIds,
      isLoading: state.isLoading,
      isSubmitModalOpen: state.isSubmitModalOpen,
      isTampuuriOptionsOpen: state.isTampuuriOptionsOpen,
      selectedReplyTemplate: state.selectedReplyTemplate
    };
  }

  private getInitialState = (props: ReplyTampuuriProps, entitiesChanged: boolean, previousRecipients?: string) => {
    let to: string | string[] = [];

    if (props.drafts?.to === undefined) {
      to = props.to;
    } else {
      to = props.drafts.to;
    }

    if (entitiesChanged && previousRecipients !== undefined) {
      const currentPropsRecipientsArray = props.to.split(',').filter((address) => {
        return address !== '' && address !== undefined;
      });
      const previousPropsRecipientsArray = previousRecipients.split(',').filter((address) => {
        return address !== '' && address !== undefined;
      });

      let recipientsToBeAdded = currentPropsRecipientsArray.filter((address: string) => {
        return !previousPropsRecipientsArray.includes(address);
      });

      const recipientsToBeRemoved = previousPropsRecipientsArray.filter((address: string) => {
        return !currentPropsRecipientsArray.includes(address);
      });

      let currentRecipientsArray = typeof to === 'string' ? to.split(',') : to;
      const recipientOverlap = currentRecipientsArray.filter((address: string) => {
        return recipientsToBeAdded.includes(address);
      });

      recipientsToBeAdded = recipientsToBeAdded.filter((address) => {
        return !recipientOverlap.includes(address);
      });

      currentRecipientsArray = currentRecipientsArray.concat(recipientsToBeAdded);

      currentRecipientsArray = currentRecipientsArray.filter((address: string) => {
        return !recipientsToBeRemoved.includes(address);
      });

      to = currentRecipientsArray.join(',');
    }

    // don't included sender in recipients

    const state: ReplyTampuuriState = {
      content: props.drafts?.content || '',
      isCaseDoneAfterSubmit: props.drafts?.isCaseDoneAfterSubmit || false,
      subject: props.drafts?.subject || ' ' + props.subject || 'Re: ',
      to: to,
      isTampuuriOptionsOpen: props.drafts?.isTampuuriOptionsOpen || false,
      isLoading: props.drafts?.isLoading || false,
      isSubmitModalOpen: props.drafts?.isSubmitModalOpen || false,
      selectedAttachmentIds: props.drafts?.selectedAttachmentIds || [],
      suggestionResults: [],
      searchLoading: false,
      selectedReplyTemplate: props.drafts?.selectedReplyTemplate || undefined
    };

    return state;
  };

  componentDidUpdate(prevProps: ReplyTampuuriProps) {
    if (!_.isEqual(prevProps.entities, this.props.entities)) {
      this.setState(this.getInitialState(this.props, true, prevProps.to), () => {
        this.saveDraft(this.state);
      });
    }

    if (!_.isEqual(prevProps.attachments, this.props.attachments)) {
      this.options = this.getAttachmentsOptions();
    }
  }

  componentWillReceiveProps(nextProps: ReplyTampuuriProps) {
    if (!_.isEqual(nextProps.drafts, this.props.drafts) || this.props.taskId !== nextProps.taskId) {
      this.setState(this.getInitialState(nextProps, false));
    }

    if (this.props.drafts?.content !== nextProps.drafts?.content) {
      this.setState({ content: nextProps.drafts?.content || '' });
    }

    this.options = this.getAttachmentsOptions(nextProps.attachments);
  }

  private clearFields = () => {
    this.updateState({
      content: '',
      subject: 'Re: ' + this.props.subject,
      to: '',
      selectedAttachmentIds: [],
      isLoading: false,
      isSubmitModalOpen: false,
      selectedReplyTemplate: undefined
    });
  };

  private getSelectedAttachments = (selectAttachmentIds: string[]): Attachment[] => {
    return this.props.attachments.filter((attachment: Attachment) => {
      return selectAttachmentIds.includes(attachment.id);
    });
  };

  private submitTampuuri = async () => {
    this.setState({ isLoading: true }, async () => {
      const to = trimAndSplitRecipients(this.state.to)?.filter((to) => to);
      const bodyOfRequest = {
        content: this.state.content,
        sendAsTampuuriMessage: true,
        ViestiketjuId: this.props.task.case.ViestiketjuId || undefined,
        OsapuoliId: this.props.task.case.OsapuoliId || undefined,
        subject: this.state.subject?.length > 900 ? this.state.subject.slice(0, 900) : this.state.subject,
        channel: this.channel,
        direction: 'out',
        metaData: {
          title: this.state.subject,
          to: to,
          attachments: this.getSelectedAttachments(this.state.selectedAttachmentIds)
        },
        to: to,
        attachments: this.getSelectedAttachments(this.state.selectedAttachmentIds),
        title: this.state.subject.trim()
      } as any;

      try {
        const response = await this.props.onSubmit(
          bodyOfRequest,
          this.state.isCaseDoneAfterSubmit,
          this.state.subject !== ''
        );
        if (response !== false) {
          this.clearFields();
        }
      } catch (error) {
        console.error('Failed to save ticket', error);
        this.updateState({
          isLoading: false,
          isSubmitModalOpen: false
        });
      }
    });
  };

  private getSubmitModal = () => {
    return (
      <Modal
        open={this.state.isSubmitModalOpen}
        closeOnDocumentClick={true}
        onClick={() => this.updateState({ isSubmitModalOpen: false })}
      >
        <Modal.Header>{tr('COMMENT_SEND_AS_TAMPUURI_QUESTION')}</Modal.Header>
        <Modal.Description style={{ padding: '20px' }}>
          <Table celled padded>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{tr('ADD_COMMENT_TITLE')}</Table.Cell>
                <Table.Cell>{this.state.subject}</Table.Cell>
              </Table.Row>

              {this.state.selectedAttachmentIds.length > 0 && (
                <Table.Row>
                  <Table.Cell>{tr('ADD_COMMENT_ATTACHMENT_LABEL')}</Table.Cell>
                  <Table.Cell>
                    {this.getSelectedAttachments(this.state.selectedAttachmentIds).map((x) => (
                      <Label>{x.fileName}</Label>
                    ))}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <Divider horizontal>
            <Icon name="arrow down" />
          </Divider>
          <Table celled padded>
            <Table.Body>
              {this.state.to && (
                <Table.Row>
                  <Table.Cell>{tr('ADD_COMMENT_RECEPIENTS')}</Table.Cell>
                  <Table.Cell>
                    {trimAndSplitRecipients(this.state.to)?.map((recipient: string) => (
                      <>{!_.isEmpty(recipient) && <Label style={{ margin: '2px' }}>{recipient}</Label>}</>
                    ))}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Modal.Description>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            negative
            icon
            labelPosition="left"
            onClick={() => this.updateState({ isSubmitModalOpen: false })}
          >
            <Icon name="remove" />
            {tr('GENERAL_CANCEL')}
          </Button>
          <Button
            primary
            disabled={this.state.isLoading}
            loading={this.state.isLoading}
            icon
            onClick={this.submitTampuuri}
            labelPosition="left"
          >
            <Icon name="send" />
            {tr('GENERAL_SEND')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  private mapSuggestions = (suggestions: Suggestion[]) => {
    return suggestions
      .filter((suggestion: Suggestion) => {
        if (!_.isEmpty(suggestion.ticketTypes)) {
          return suggestion.type === 'tampuuri' && suggestion.ticketTypes.includes(this.props.ticketType.id);
        } else {
          return suggestion.type === 'tampuuri';
        }
      })
      .map((suggestion: Suggestion) => {
        return {
          label: suggestion.name,
          value: suggestion.value
        };
      });
  };

  private formatSuggestionOptionLabel = ({ label, value }: any) => (
    <span>
      {' '}
      {label ? <Label> {label}</Label> : ''} {value ? value : ''}
    </span>
  );

  private handleAddressChange = (addressArray: any, type: string) => {
    const state = { ...this.state };

    if (addressArray !== null && addressArray !== undefined) {
      const newAddresses = addressArray.map((address: any) => {
        return address.value;
      });
      state[type] = newAddresses.join(',');
    } else {
      state[type] = '';
    }

    this.setState(state, () => {
      this.saveDraft(this.state);
    });
  };

  formatCreateLabel = (text: string) => {
    return <span>{text}</span>;
  };

  private onNewOption = (value: string, type: string) => {
    const separatedValues = trimAndSplitRecipients(value);
    const inCompatibleValues = separatedValues?.filter((v: string) => {
      return !v.match(/^[0-9]*$/);
    });

    const mappedValues = separatedValues?.map((val: string) => {
      return {
        label: '',
        value: val,
        color: inCompatibleValues?.includes(val) ? '#fae5e3' : ''
      };
    });

    const newValues = mappedValues
      ?.map((recipient: any) => {
        return recipient.value;
      })
      .join(',');

    const patch = {};
    patch[type] = this.state[type] + ',' + newValues;

    this.setState(patch, () => {
      this.saveDraft(this.state);
    });
  };

  private getValue = (props: any, type: string) => {
    const recipients = props[type];

    const separatedValues = trimAndSplitRecipients(recipients);
    const inCompatibleValues = separatedValues?.filter((v: any) => {
      return !v.match(/^[0-9]*$/);
    });
    const mappedValues = separatedValues?.map((val: any) => {
      if (val !== '') {
        return {
          label: '',
          value: val,
          color: inCompatibleValues?.includes(val) ? '#fae5e3' : ''
        };
      } else {
        return;
      }
    });

    return mappedValues;
  };

  private onUploadAttachment = async (attachmentFiles: File[]) => {
    attachmentFiles.map((file: File) => {
      const data = new FormData();
      data.append('attachments', file);
      return this.props.uploadFile(this.props.taskId, data).then((files: { attachmentId: string }[]) => {
        const attachments = files.map((att: { attachmentId: string }) => {
          return att.attachmentId;
        });

        this.updateWithPreviousState((previous) => ({
          selectedAttachmentIds: [...previous.selectedAttachmentIds, ...attachments]
        }));

        return files;
      });
    });
  };

  private updateContent = (content: string) => {
    super.updateState({ content });
  };

  public submitComment = () => {
    if (!this.state.content.length || !this.state.to.length) {
      return;
    }
    this.updateState({ isSubmitModalOpen: true });
  };

  render() {
    return (
      <Translation ns="translations">
        {(t) => (
          <React.Fragment>
            {this.getSubmitModal()}
            <Form reply={true} style={{ marginTop: '20px' }} loading={this.state.isLoading}>
              <Form.Group>
                <Form.Field width={10}>
                  <label>{t('ADD_COMMENT_TITLE')}</label>
                  <Form.Input
                    onChange={(event, d) => this.updateState({ subject: d.value })}
                    type="text"
                    fluid={true}
                    // maxLength={900} // over limit 900 characters to the mail's content
                    value={this.state.subject}
                  >
                    <input />
                  </Form.Input>
                  {/** the title limit 998 but we add extra " [tsk1234567...]"  so limit to 900 to be sure */}
                  {this.state.subject.length >= 900 && (
                    <p style={{ color: 'red' }}>{t('general_reply.max_length_limit')} (900)</p>
                  )}
                </Form.Field>
                <Form.Field width={6}>
                  <label>{t('ADD_COMMENT_CANNED_RESPONSE')}</label>
                  <ReplyTemplates
                    userData={this.props.userData}
                    ticketType={this.props.ticketType}
                    templates={this.props.templates}
                    task={this.props.task}
                    channel={this.channel}
                    toAddresses={this.state.to}
                    selectedOption={this.state.selectedReplyTemplate}
                    setSelectedOption={(value) => this.setState({ selectedReplyTemplate: value })}
                    setContent={(value) => this.updateState({ ...value })}
                    insertAtCursor={(value, content) => {
                      value.content = insertAtCursor(this.editor, content);
                      this.updateState({ ...value });
                    }}
                    content={
                      this.state.subject && this.state.subject?.length > 900
                        ? this.state.subject.slice(900, -1) + ' \n\n ' + this.state.content
                        : this.state.content
                    }
                    entities={this.props.entities}
                    users={this.props.users}
                    discardHtml={true}
                  />
                </Form.Field>
              </Form.Group>
              <ReplyVCDrafts taskId={this.props.taskId} channelId={this.channel} onClickLoad={this.loadVCDraft} />
              <Form.Field id="commentContentField">
                <label>{t('ADD_COMMENT_CONTENT')}</label>
                <ReplyTextArea
                  ref={(ref) => (this.editor = ref)}
                  content={this.state.content}
                  onChange={this.updateContent}
                  onKeyDown={this.handleHotKeys}
                />
              </Form.Field>
              <Form.Field>
                <label>{t('ADD_COMMENT_RECEPIENTS')}</label>
                <CreatableSelect
                  isMulti
                  value={this.getValue(this.state, 'to') as Option[]}
                  hideSelectedOptions
                  isClearable
                  onChange={(value) => this.handleAddressChange(value, 'to')}
                  onCreateOption={(value) => this.onNewOption(value, 'to')}
                  formatOptionLabel={this.formatSuggestionOptionLabel}
                  options={this.mapSuggestions(this.props.suggestions)}
                  formatCreateLabel={() => this.formatCreateLabel(t('SELECT_ADD_NEW_RECIPIENT'))}
                  placeholder={t('ADD_COMMENT_TAMPUURI_RECEPIENTS_PLACEHOLDER')}
                  noOptionsMessage={() => t('SELECT_NO_OPTIONS')}
                  classNamePrefix="addressSelect"
                  styles={defaultCreatableSelectStyles}
                  filterOption={createFilter({ ignoreAccents: false })}
                />
              </Form.Field>
              <Form.Group widths="equal">
                <FormDropzoneDropdown
                  attachments={this.props.attachments}
                  onChangeAttachments={(addedAttachments) =>
                    this.updateState({ selectedAttachmentIds: addedAttachments })
                  }
                  onDropAccepted={this.onUploadAttachment}
                  options={this.options}
                  value={this.state.selectedAttachmentIds}
                />
              </Form.Group>
              <Divider />
              <ReplyControlButtons
                small={this.props.smallButtons}
                disabled={!this.state.content.length || !this.state.to.length}
                onClear={this.clearFields}
                onSaveDraft={() => this.saveVCDraft(false)}
                onSubmit={this.submitComment}
              />
            </Form>
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

export default ReplyTampuuri;

import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';

import InitialLoad from '../Components/InitialLoad';
import {
  fetchChannelTypes,
  fetchResponseTemplates,
  fetchTicketTypes,
  fetchTitleTemplates,
  fetchTicketPriorities
} from '../actions/ticketsActions';
import { fetchAutoSuggestions } from 'src/actions/suggestionActions';
import { logout, refreshToken } from '../actions/authActions';
import { fetchUsers } from '../actions/userActions';
import { fetchLinkLists } from '../actions/linkListActions';
import { fetchTags } from '../actions/tagActions';
import { fetchCategories } from '../actions/categoryActions';
import { fetchPersonalData, fetchRoles } from '../actions/userActions';
import { setInitialRequestToLoading } from '../actions/initActions';
import { fetchEnvSettings, fetchFeatureFlags, fetchPhoneConfigurations } from '../actions/configActions';
import { fetchCaseImportTemplates } from '../actions/caseImportTemplateActions';
import { fetchPriorities } from '../actions/priorityActions';
import { fetchChats } from 'src/actions/chatActions';
import type { State } from 'src/types/initialState';

const connector = connect(
  (state: State) => ({
    initialRequests: state.initialRequests
  }),
  {
    setInitialRequestToLoading,
    fetchEnvSettings,
    fetchFeatureFlags,
    fetchUsers,
    fetchTicketTypes,
    fetchChannelTypes,
    fetchResponseTemplates,
    fetchTitleTemplates,
    fetchLinkLists,
    fetchTags,
    fetchCategories,
    fetchCaseImportTemplates,
    fetchRoles,
    fetchPersonalData,
    logout,
    fetchAutoSuggestions,
    fetchTicketPriorities,
    fetchPriorities,
    fetchPhoneConfigurations,
    fetchChats,
    refreshToken
  }
);

export interface InitialLoadProps extends ConnectedProps<typeof connector> {
  children: JSX.Element;
}

export default connector(InitialLoad);

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import iziToast from 'izitoast';
import { connect } from 'react-redux';
import { Button, Icon, Modal, Checkbox, Table, Header } from 'semantic-ui-react';
import type { ConnectedProps } from 'react-redux';
import type { VFC } from 'react';
import type { CheckboxProps } from 'semantic-ui-react';

import { addComment } from 'src/actions/commentsActions';
import { updateSingleTicketCaseDetail } from 'src/actions/ticketsActions';
import * as styles from '../FieldHeader/FieldHeader.style';
import type { Entity } from 'src/types/Ticket';
import type { Field } from 'src/types/Info';
import type { InfoFieldProps } from 'src/Components/Case/Info/InfoField/InfoFieldProps';
import type { State } from 'src/types/initialState';
import type { TicketType } from 'src/types/TicketType';

interface VerifyFieldProps extends ConnectedProps<typeof connector> {
  InfoFieldComponent: React.VFC<InfoFieldProps>;
  taskId?: string | number;
  field: Field;
  fieldSet: Field[];
  values: any;
  entity: Entity;
  ticketTypes: TicketType[];
}

const VerifyField: VFC<VerifyFieldProps> = ({
  taskId,
  field,
  fieldSet,
  values,
  task,
  entity,
  addComment,
  updateSingleTicketCaseDetail,
  InfoFieldComponent,
  ticketTypes
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedFields, setSelectedFields] = useState<string[]>([]);
  const shouldVerifyChannel = field.mustBeVerifiedIn?.channels?.includes(Number(task?.channel));
  const isEntityVerified = !!(task?.case?.verifiedFields as { _id: string; _type: string }[])?.find(
    (e) => e._id === entity?._id
  );
  const [isSubComponentVisible, setSubComponentVisible] = useState(!shouldVerifyChannel || isEntityVerified);
  const { t } = useTranslation();

  const modalTrigger = (
    <Button labelPosition="left" onClick={() => setModalOpen(true)} basic primary icon>
      <Icon name="check" />
      {t('verifyFields.button.title')}
    </Button>
  );

  useEffect(() => {
    setModalOpen(false);
    setSelectedFields([]);
  }, [taskId, field]);

  useEffect(() => {
    setTimeout(() => {
      if (selectedFields.length >= Number(field.minSelected || 0)) {
        if (field.logAutomaticComment) {
          addComment(taskId, {
            type: 'automatic',
            content: `[VERIFIED_FIELDS:${selectedFields.join(',')}]`
          });
        }
        if (!isEntityVerified) {
          updateSingleTicketCaseDetail(
            taskId,
            'verifiedFields',
            [...(task?.case?.verifiedFields ?? []), { _id: entity._id, _type: entity._type }],
            'CaseDetails'
          );
        }
        setSubComponentVisible(true);
        iziToast.info({
          title: t('verifyFields.toast.title')
        });
        setModalOpen(false);
        setSelectedFields([]);
      }
    }, 500);
  }, [selectedFields]);

  const relevantFields = fieldSet.filter((f) => field.selectableFields?.includes(f.value));

  const onCheck = (name: string, checked: boolean) => {
    if (checked) {
      setSelectedFields(uniq([...selectedFields, name]));
    } else {
      setSelectedFields(selectedFields.filter((x) => x !== name));
    }
  };

  return (
    <>
      {!isSubComponentVisible && (
        <>
          <Table.Cell width={6}>
            <div style={styles.headerWrapper}>
              <Header as="h5" style={styles.header}>
                {field.name}
              </Header>
            </div>
          </Table.Cell>
          <Table.Cell width={10}>
            <Modal
              open={isModalOpen}
              size="small"
              trigger={modalTrigger}
              onClose={() => setModalOpen(false)}
              onOpen={() => setModalOpen(true)}
            >
              <Modal.Header>
                {t('verifyFields.modal.title')} ({selectedFields.length} / {field.minSelected})
              </Modal.Header>
              <Modal.Content>
                <Table>
                  {relevantFields.map((field) => (
                    <Table.Row>
                      <Checkbox
                        toggle
                        style={{ marginTop: '15px', marginRight: '10px', marginLeft: '10px' }}
                        checked={selectedFields.includes(field.value)}
                        onChange={(_event, data: CheckboxProps) => {
                          onCheck(field.value, data.checked || false);
                        }}
                      />
                      <InfoFieldComponent
                        entity={entity}
                        fields={relevantFields}
                        ticketTypes={ticketTypes}
                        field={field}
                        values={values}
                        isMobile={false}
                        taskId={taskId}
                      />
                    </Table.Row>
                  ))}
                </Table>
              </Modal.Content>
            </Modal>
          </Table.Cell>
        </>
      )}
      {isSubComponentVisible && (
        <InfoFieldComponent
          entity={entity}
          generalDisable={true}
          fields={[field.subField as Field]}
          field={field.subField as Field}
          values={values}
          taskId={taskId}
          ticketTypes={ticketTypes}
        />
      )}
    </>
  );
};

const connector = connect(
  (state: State) => ({
    task: state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)
  }),
  { addComment, updateSingleTicketCaseDetail }
);

export default connector(VerifyField);

import { useMemo, useEffect } from 'react';
import type { DependencyList } from 'react';

function useDeepCompareMemoize<T>(value: T) {
  return useMemo(() => value, [JSON.stringify(value)]);
}

export default function useDeepCompareEffect(callback: () => void | (() => undefined), dependencies: DependencyList) {
  const memoizedDependencies = useDeepCompareMemoize(dependencies);

  useEffect(callback, memoizedDependencies);
}

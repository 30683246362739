import { read, utils } from 'xlsx';
import linkifyHtml from 'linkifyjs/lib/linkify-html';

import ApiConfig from '../api/ApiConfig';
import FeatureFlags from '../api/FeatureFlags';
import type { Attachment } from '../types/Ticket';

const parser = new DOMParser();
const cidPrefix = 'cid:';
const cidPrefixEscaped = 'cid%3A';
const cidStandaloneRegex = /\[cid(?::|%3A)(.*?)\]/gm;
const htmlStartRegex = /^(\s*)<html/;
export const newlineRegex = /(?:\\r\\n|\\r|\\n|↵|\r\n|\r|\n)/gm;

const documentToString = (content: string, doc: Document): string => {
  // If the content to be parsed started with a html tag, return all html
  if (content.match(htmlStartRegex)) {
    return doc.documentElement.outerHTML;
  }

  // If the document has a body return all html within it, otherwise return all html
  // This avoids returning the <head> element unnecessarily
  return doc.body?.innerHTML ?? doc.documentElement.innerHTML;
};

const parseImages = (doc: Document, attachments: Attachment[]): void => {
  doc.body.innerHTML = doc.body.innerHTML.replace(cidStandaloneRegex, (_all, cId) => `<img src="cid:${cId}"/>`);

  const images = doc.getElementsByTagName('img');
  for (const image of images) {
    // Checking image.src directly here prepends the url to the cid
    const src = image.getAttributeNode('src')?.value;
    if (src?.startsWith(cidPrefixEscaped)) {
      image.src = src.replace(cidPrefixEscaped, cidPrefix);
    }

    if (image.src.startsWith(cidPrefix)) {
      const imageSrc = image.src.substring(cidPrefix.length);
      const attachment = attachments.find((a) => a.cId != null && encodeURI(a.cId) === imageSrc);
      if (attachment) {
        image.src = getAttachmentLink(attachment);
      }
    }

    const parent = image.parentElement;
    if (!(parent instanceof HTMLAnchorElement)) {
      const anchor = doc.createElement('a');
      anchor.href = image.src;
      anchor.target = '_blank';
      image.replaceWith(anchor);
      anchor.appendChild(image);
    }
  }
};

const parseLinks = (doc: Document, linkParse: boolean): void => {
  const parse = linkParse && FeatureFlags.isFlagOn('ENABLE_LINK_PARSE');

  if (parse) {
    doc.documentElement.innerHTML = linkifyHtml(doc.documentElement.innerHTML, {
      validate: {
        // Don't turn email addresses into links
        email: () => false
      }
    });

    const anchors = doc.getElementsByTagName('a');
    for (let i = 0; i < anchors.length; i++) {
      const anchor = anchors[i];
      anchor.target = '_blank';
    }
  }
};

const parseStyles = (doc: Document): void => {
  const elements = doc.getElementsByTagName('*');
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const style: CSSStyleDeclaration | undefined = element['style'];
    if (style?.position.includes('absolute')) {
      style.position = '';
    }
  }
};

export const newLinesToBr = (value: string) => {
  return value.replace(newlineRegex, '<br/>');
};

export const parseContent = (content: string, attachments: Attachment[], nToBr: boolean, linkParse = true): string => {
  if (!content) {
    return '';
  }

  if (nToBr) {
    content = newLinesToBr(content);
  }

  const doc = parser.parseFromString(content, 'text/html');

  if (FeatureFlags.isFlagOn('ENABLE_IMG_PARSE')) {
    parseImages(doc, attachments);
  }

  parseLinks(doc, linkParse);
  parseStyles(doc);

  return documentToString(content, doc);
};

const getAttachmentLink = (attachment: string | Attachment) => {
  if (typeof attachment !== 'string') {
    attachment = attachment.previewUri ? attachment.uri + '?thumb=1' : attachment.uri;
  }

  return ApiConfig.getConfig().API_URL + '/file/' + attachment;
};

const serializeCidTag = (tag: HTMLImageElement | HTMLAnchorElement, attachments: Attachment[]): void => {
  const url = 'src' in tag ? tag.src : tag.href;
  const attachment = attachments.find((a) => url === getAttachmentLink(a));
  if (attachment) {
    const cId = `${cidPrefix}${attachment.cId}`;
    if ('src' in tag) {
      tag.src = cId;
    } else {
      tag.href = cId;
    }
  }
};

const removeImageAnchors = (doc: Document): void => {
  const images = doc.getElementsByTagName('img');
  if (images.length === 0) {
    return;
  }

  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    const parent = image.parentElement;

    if (parent instanceof HTMLAnchorElement && parent.href === image.src) {
      const fragment = doc.createDocumentFragment();

      // In case this image isn't the only child of the parent anchor
      while (parent.firstChild) {
        fragment.appendChild(parent.firstChild);
      }

      parent.replaceWith(fragment);
    }
  }
};

export const getAttachmentIdsFromContent = (content: string, attachments: Attachment[]): string[] => {
  const doc = parser.parseFromString(content, 'text/html');
  const images = doc.getElementsByTagName('img');

  return attachments
    .filter((attachment) => {
      const attachmentLink = getAttachmentLink(attachment);
      return Array.from(images).some((image: HTMLImageElement) => image.src === attachmentLink);
    })
    .map(({ id }) => id);
};

const serializeImages = (doc: Document, attachments: Attachment[]): void => {
  removeImageAnchors(doc);

  const images = doc.getElementsByTagName('img');
  for (let i = 0; i < images.length; i++) {
    const image = images[i];
    serializeCidTag(image, attachments);
  }

  const anchors = doc.getElementsByTagName('a');
  for (let i = 0; i < anchors.length; i++) {
    serializeCidTag(anchors[i], attachments);
  }
};

export const serializeContent = (content: string, attachments: Attachment[]): string => {
  const doc = parser.parseFromString(content, 'text/html');
  serializeImages(doc, attachments);
  return documentToString(content, doc);
};

export const replaceNordicCharacters = (text: string) => {
  return text
    .split('&aring;')
    .join('å')
    .split('&Aring;')
    .join('Å')
    .split('&auml;')
    .join('ä')
    .split('&Auml;')
    .join('Ä')
    .split('&ouml;')
    .join('ö')
    .split('&Ouml;')
    .join('Ö');
};

export const checkXLSXFileRequiredHeaders = async (
  file: File,
  requiredHeaders: string[]
): Promise<{ name: string; valid: boolean }[]> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = event.target?.result;
      const readedData = read(data, { type: 'binary' });
      const dataParse = utils.sheet_to_json<any>(readedData.Sheets[readedData.SheetNames[0]], { header: 1 });

      if (dataParse[0]) {
        resolve(
          requiredHeaders.map((header: string) => {
            return { name: header, valid: !!dataParse[0].includes(header) };
          })
        );
      }
      resolve([]);
    };

    reader.onerror = reject;
    reader.onabort = reject;

    reader.readAsBinaryString(file);
  });

export const getXLSXFileRecordsAmount = async (file: File): Promise<number> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = event.target?.result;
      const readedData = read(data, { type: 'binary' });
      const dataParse = utils.sheet_to_json<any>(readedData.Sheets[readedData.SheetNames[0]], { header: 1 });

      resolve(dataParse.length - 1);
    };

    reader.onerror = reject;
    reader.onabort = reject;

    reader.readAsBinaryString(file);
  });

export const getXLSXFirstRecordData = async (file: File): Promise<{ [key: string]: string }> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const data = event.target?.result;
      const readedData = read(data, { type: 'binary' });
      const dataParse = utils.sheet_to_json<any>(readedData.Sheets[readedData.SheetNames[0]], { header: 1 });

      const firstRecordData = {};
      dataParse[0].map((header: string, index: number) => (firstRecordData[header] = dataParse[1][index]));
      resolve(firstRecordData);
    };

    reader.onerror = reject;
    reader.onabort = reject;

    reader.readAsBinaryString(file);
  });

export const getXLSXDataJson = (blob: Blob): Promise<string[][]> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader.readyState === FileReader.DONE) {
        const data = reader.result;
        const readData = read(data, { type: 'binary' });
        const json = utils.sheet_to_json<string[]>(readData.Sheets[readData.SheetNames[0]], { header: 1 });
        resolve(json);
      } else {
        reject(new Error('Error reading Blob'));
      }
    };

    reader.onerror = reject;
    reader.onabort = reject;

    reader.readAsArrayBuffer(blob);
  });

export const parseTicketNumber = (value: string): number => {
  const regExp = /^.*TSK([\d\s:-]+)/;
  const [, foundValue] = regExp.exec(value) || [];

  return Number((foundValue || value).replace(/[^0-9]/g, '')) || 0;
};

export const commentOutImages = (content?: string | null) =>
  content?.replace(/<img\b[^>]*>/g, (match) => `<!-- ${match} -->`) ?? '';

import type { SemanticICONS } from 'semantic-ui-react';
import type { TicketDirection } from 'src/types/Ticket';

export function getPhoneIcon(direction: TicketDirection): SemanticICONS {
  // TODO FONT AWESOME Change icons to
  // solid phone-arrow-down-left for inbound
  // solid phone-arrow-up-right for outbound
  switch (direction) {
    case 'in':
      return 'phone volume';
    case 'out':
      return 'phone';
  }
}

import type { Action, ActionCreator, Dispatch } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import type { AxiosError } from 'axios';

import TicketsApi from '../api/TicketsApi';
import TagsApi from '../api/TagsApi';
import { startAjaxCall } from './ajaxStatusActions';
import { FETCH_TAGS_SUCCESS, FETCH_TAGS_FAILURE, PATCH_TAGS_SUCCESS, CREATE_TAG_SUCCESS } from './index';
import { initialRequestSuccess, initialRequestFailure } from './initActions';
import type { Tag } from '../types/Tag';

export const loadTagsSuccess = (tags: Tag[]) => {
  return { type: FETCH_TAGS_SUCCESS, tags };
};
export const loadTagsFailure = (tags: Tag[]) => {
  return { type: FETCH_TAGS_FAILURE, tags };
};

export const patchTagSuccess = (tag: Tag[]) => {
  return { type: PATCH_TAGS_SUCCESS, tag };
};

export const createTagSuccess = (tag: Tag) => {
  return { type: CREATE_TAG_SUCCESS, tag };
};

export const fetchTags: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_TAGS' }));
    return TicketsApi.getTags()
      .then((tag) => {
        dispatch(loadTagsSuccess(tag));
        dispatch(initialRequestSuccess('tags'));
      })
      .catch((error) => {
        console.error('Failed to load tags', error);
        dispatch(loadTagsFailure(error));
        dispatch(initialRequestFailure('tags', error));
      });
  };
};

export const patchTag: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (data: {
  id: string;
  data: Partial<Tag>;
}) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'PATCH_TAGS' }));
    return TagsApi.patchTag(data)
      .then((tag) => {
        dispatch(patchTagSuccess(tag[0]));
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

export const createTag: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'CREATE_TAG' }));
    return TagsApi.createTag(data)
      .then((response) => {
        dispatch(createTagSuccess(response));
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

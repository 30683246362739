import React, { useCallback } from 'react';
import { Card } from 'semantic-ui-react';
import type { VFC } from 'react';

import type { FloatingWindowState } from 'src/types/FloatingWindow';
import FloatingWindowHeader from 'src/Components/generic/FloatingWindow/FloatingWindowHeader';

import * as styles from './FloatingWindows.style';
import { useDispatch } from 'react-redux';
import { removeFloatingWindow, updateFloatingWindow } from 'src/reducers/floatingWindowsReducer';

interface FoldedWindowProps {
  window: FloatingWindowState;
}

const FoldedWindow: VFC<FoldedWindowProps> = ({ window: win }) => {
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(removeFloatingWindow(win.id));
  }, [win.id]);
  const foldToggle = useCallback(() => {
    dispatch(updateFloatingWindow({ id: win.id, fold: !win.fold }));
  }, [win.id, win.fold]);

  return (
    <Card style={styles.folded}>
      <FloatingWindowHeader window={win} fullScreen={false} onClose={onClose} foldToggle={foldToggle} />
    </Card>
  );
};

export default React.memo(FoldedWindow);

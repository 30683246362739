import type { CheckForAndAssignParametersOptions } from 'src/types/ResponseTemplate';
import type { User } from 'src/types/User';

export const searchValueFromObject = (
  searchedObject: undefined | null | Record<string, any>[] | Record<string, any>,
  parameterArray: string[]
) => {
  let responseString = '';
  parameterArray.forEach((parameterKey: string) => {
    if (searchedObject != null) {
      if (Array.isArray(searchedObject)) {
        searchedObject.forEach(
          (searchedObjectSubArray: undefined | null | Record<string, any>[] | Record<string, any>) => {
            const result = searchValueFromObject(
              searchedObjectSubArray,
              parameterArray.slice(parameterArray.indexOf(parameterKey))
            );
            if (result !== undefined) {
              responseString += result + ', ';
            }
            searchedObject = null;
          }
        );
      } else {
        searchedObject = searchedObject[parameterKey];
      }
    }
  });

  if (searchedObject !== undefined) {
    if (searchedObject === null) {
      return responseString.substring(0, responseString.length - 2);
    } else {
      return `${responseString}${searchedObject}`;
    }
  }

  return searchedObject;
};

export const fillInTemplateParameters = ({
  template,
  task,
  userData,
  entities,
  users,
  handlingAgentString,
  typeParams
}: CheckForAndAssignParametersOptions): { templateContent: string; missingParameters: string[] } => {
  const paramMatchRegex = /\{{(.*?)\}}/g;
  const missingParameters: string[] = [];
  const userProfile = userData?.profile ?? {};

  let templateContent = template.content;
  const templateContentToBeReplaced = templateContent.match(paramMatchRegex) || [];

  templateContentToBeReplaced.forEach((paramKey: string) => {
    let paramToBeReplaced;
    const paramKeyName = paramKey.substring(2, paramKey.length - 2);
    const paramFromTemplate = 'params' in template ? template.params?.[paramKeyName] : null;

    if (paramKeyName === 'agentAliasName' || paramKeyName === 'UserWhoLastHandled') {
      const user = users.find((usr: User) => usr.UID === 'USR' + task.handledByUser);
      if (user) {
        paramToBeReplaced = user.aliasName;
      } else {
        missingParameters.push(handlingAgentString);
        paramToBeReplaced = '';
      }
    } else {
      const address = paramFromTemplate?.address ?? paramKeyName;
      paramToBeReplaced = searchValueFromObject(
        { ...task, userData: userProfile, entities, typeParams },
        address.split('.')
      );
    }
    if (!paramToBeReplaced) {
      missingParameters.push(paramFromTemplate?.UIName ?? paramKey);
      paramToBeReplaced = '';
    }
    templateContent = templateContent.replace(new RegExp(paramKey, 'gi'), paramToBeReplaced || '');
  });

  return { templateContent, missingParameters };
};

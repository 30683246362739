import React from 'react';
import iziToast from 'izitoast';
import querystring from 'query-string';
import { Loader } from 'semantic-ui-react';
import { Translation } from 'react-i18next';
import { t } from 'i18next';

import { normalizePhoneNumber } from 'src/Utilities/normalizeNumber';
import {
  findPhoneConfiguration,
  getIntegrationData,
  parsePhoneConfiguration,
  shouldCreateAPhoneCallTicket
} from 'src/Components/PhoneServices/utils/phoneConfigurationUtils';
import type { History } from 'history';
import type { IntegrationData, UpdateDetailsObject } from 'src/handlers/handlePhoneCall';
import type { PersonalData, User } from 'src/types/User';
import type { TicketType } from 'src/types/TicketType';
import type { PhoneConfiguration } from 'src/types/PhoneConfiguration';

interface UrlActionsProps {
  ticketTypes: TicketType[];
  url: string;
  history: History;
  userData: PersonalData;
  match: any;
  location: any;
  configurations: PhoneConfiguration[];
  user: User;

  handleIncomingPhoneCall(data: IntegrationData): Promise<void>;
}

export default class UrlActions extends React.Component<UrlActionsProps> {
  componentDidMount() {
    const { params } = this.props.match;
    const { search } = this.props.location;

    if (this.props.user.role.name.includes('readOnly')) {
      iziToast.error({
        message: t('PHONECONFIGURATION_READONLY_ROLE_LIMIT'),
        icon: 'ban',
        timeout: 7500,
        position: 'bottomRight'
      });
      return null;
    }
    switch (params.type) {
      case 'phoneContact': {
        const searchParams = querystring.parse(search);
        const phoneNumber = searchParams['phoneNumber'] as string | undefined;
        const serviceName = searchParams['serviceName'] as string | undefined;
        const direction = searchParams['direction'] as string | undefined;
        const ocTicketId = searchParams['ocTicketId'] as string | undefined;

        if (!phoneNumber || !serviceName) {
          return null;
        }

        return this.handleIncomingPhoneCall(phoneNumber, serviceName, ocTicketId, direction);
      }
      default: {
        return null;
      }
    }
  }

  handleIncomingPhoneCall = (
    originalPhoneNumber: string,
    lastServiceName: string,
    lastOCTicketId: string | null = null,
    callDirection: string | null = null
  ) => {
    const normalizedPhoneNumber = normalizePhoneNumber(originalPhoneNumber);
    const UID = this.props.userData.UID;
    const history = this.props.history;
    const configuration = findPhoneConfiguration(this.props.configurations, lastServiceName);
    const parsedConfiguration = parsePhoneConfiguration(configuration);
    const direction = callDirection === 'out' ? 'out' : 'in';

    const detailsObjects: UpdateDetailsObject[] = [
      {
        updateKey: 'ocTicketId',
        updateValue: lastOCTicketId,
        group: 'CaseDetails'
      }
    ];

    if (lastServiceName !== null) {
      detailsObjects.push({
        updateKey: 'ocServiceName',
        updateValue: lastServiceName,
        group: 'CaseDetails'
      });
    }

    const callObject = getIntegrationData({
      UID,
      configuration,
      detailsObjects,
      direction,
      history,
      normalizedPhoneNumber,
      parsedConfiguration,
      serviceName: lastServiceName,
      ticketTypes: this.props.ticketTypes,
      userDefaultTicketTypeId: this.props.userData.userPreferences.defaultTicketType
    });

    if (shouldCreateAPhoneCallTicket(configuration, parsedConfiguration)) {
      this.props.handleIncomingPhoneCall(callObject);
    } else {
      iziToast.error({
        message: t('PHONECONFIGURATION_NO_TICKET'),
        icon: 'ban',
        timeout: 7500,
        position: 'bottomRight'
      });
    }
  };

  render() {
    return (
      <Translation ns="translations">
        {(t) => (
          <div className="urlActionLoaderContainer">
            <Loader content={t('LOADING')} active inline />
          </div>
        )}
      </Translation>
    );
  }
}

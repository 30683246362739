import _ from 'lodash';
import React from 'react';
import iziToast from 'izitoast';
import { t } from 'i18next';
import { Translation } from 'react-i18next';
import { Form, Message } from 'semantic-ui-react';
import type { DropdownItemProps } from 'semantic-ui-react';

import FacebookApi from 'src/api/FacebookApi';
import ReplyTemplates from './ReplyTemplates';
import ReplyControlButtons from './components/ReplyControlButtons';
import FormDropzoneDropdown from './components/FormDropzoneDropdown';
import ChannelType from '../CommentIconContent/ChannelType';
import type { ReplyMethodProps } from './ReplyMethod';
import { ReplyMethod } from './ReplyMethod';
import ReplyTextArea from './ReplyTextArea';
import { insertAtCursor } from 'src/Utilities/insertAtCursor';
import { Channels } from 'src/types/Channel';
import type { PersonalData } from 'src/types/User';
import type { TicketType } from 'src/types/TicketType';
import type { Attachment, UploadFileResult, Entity, Ticket } from 'src/types/Ticket';
import type { ResponseTemplate } from 'src/types/ResponseTemplate';

interface ReplyInstagramProps extends ReplyMethodProps<ReplyInstagramState> {
  userData: PersonalData;
  ticketType: TicketType;
  templates: ResponseTemplate[];
  drafts?: Partial<ReplyInstagramState>;
  task: Ticket;
  entities: Entity[];
  attachments: Attachment[];

  uploadFile: (ticketId: string, file: FormData) => Promise<UploadFileResult[]>;
}

interface ReplyInstagramState {
  content: string;
  isLoading: boolean;
  selectedReplyTemplate: string | undefined;
  attachmentsOptions: DropdownItemProps[];
  selectedAttachmentID: string | undefined;
}

class ReplyInstagram extends ReplyMethod<ReplyInstagramProps, ReplyInstagramState> {
  private channel = ChannelType.Instagram;
  private editor: HTMLTextAreaElement | null;

  constructor(props: ReplyInstagramProps) {
    super(props);
    this.state = this.getInitialState(this.props.drafts);
  }

  componentWillReceiveProps(nextProps: ReplyInstagramProps) {
    if (!_.isEqual(this.props.attachments, nextProps.attachments)) {
      this.setState({ attachmentsOptions: this.getAttachmentsOptions(nextProps.attachments) });
    }
    if (this.props.drafts?.content !== nextProps.drafts?.content) {
      this.setState({ content: nextProps.drafts?.content || '' });
    }
  }

  getDraftChannel(): Channels {
    return Channels.instagram;
  }

  getDraftState(state: ReplyInstagramState): Partial<ReplyInstagramState> {
    return {
      content: state.content,
      selectedReplyTemplate: state.selectedReplyTemplate
    };
  }

  private getInitialState = (drafts: Partial<ReplyInstagramState> | undefined): ReplyInstagramState => ({
    isLoading: false,
    content: drafts?.content || '',
    selectedReplyTemplate: drafts?.selectedReplyTemplate || undefined,
    attachmentsOptions: this.getAttachmentsOptions(),
    selectedAttachmentID: undefined
  });

  private onUploadAttachment = async (attachmentFiles: File[]) => {
    return Promise.all(
      attachmentFiles.map(async (file) => {
        const data = new FormData();
        data.append('attachments', file);

        return this.props.uploadFile(this.props.taskId, data).then((files) => {
          const attachmentURIArray = files.map((att) => att.path);

          this.setState({ selectedAttachmentID: attachmentURIArray[0] });
          return files;
        });
      })
    );
  };

  public submitComment = async () => {
    if (this.state.isLoading || !this.state.content.length) {
      return;
    }

    this.setState({ isLoading: true });

    try {
      const commentsWithRelativeForeignId = this.props.task.comments.filter(
        (comment) => comment.foreignIdType === 'instagramMessage' && comment.foreignId
      );
      const replyToComment = commentsWithRelativeForeignId.at(-1);

      if (!replyToComment) {
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: 'No message reply to',
          timeout: 7500
        });
        throw new Error('[instagram-reply]: No message reply to');
      }

      if (!this.props.task.case.pageId || !this.props.task.case.instagramCustomerId) {
        iziToast.error({
          title: `${t('ERROR')}!`,
          icon: 'icon delete',
          message: 'Case details missing information: customer ID or page ID',
          timeout: 7500
        });
        throw new Error('[instagram-reply]: Case details missing information: customer ID or application ID');
      }

      const { content, selectedAttachmentID } = this.state;

      await FacebookApi.sendReply({
        content,
        replyToId: replyToComment.foreignId!,
        ticketId: this.props.taskId,
        instagramCustomerId: this.props.task.case.instagramCustomerId,
        pageId: this.props.task.case.pageId,
        ...(!!selectedAttachmentID && {
          imageID: this.state.selectedAttachmentID
        })
      });

      this.clearFields();
    } catch (error) {
      iziToast.error({
        title: `${t('ERROR')}!`,
        icon: 'icon delete',
        message: 'Failed to send an Instagram message',
        timeout: 7500
      });
    }

    this.setState({ isLoading: false });
  };

  private clearFields = () => {
    this.setState({ content: '', selectedReplyTemplate: undefined, selectedAttachmentID: undefined }, () => {
      this.saveDraft(this.state);
    });
  };

  private handleChangeContent = (content: string) => {
    this.setState({ content }, () => {
      this.saveDraft(this.state);
    });
  };

  render() {
    return (
      <Translation ns="translations">
        {(t) => (
          <Form reply={true} style={{ marginTop: '20px' }}>
            <Form.Field>
              <label>{t('ADD_COMMENT_CANNED_RESPONSE')}</label>
              <ReplyTemplates
                channel={this.channel}
                userData={this.props.userData}
                ticketType={this.props.ticketType}
                templates={this.props.templates}
                task={this.props.task}
                selectedOption={this.state.selectedReplyTemplate}
                setSelectedOption={(value) => this.setState({ selectedReplyTemplate: value })}
                setContent={(value) => this.updateState({ ...value })}
                insertAtCursor={(value, content) => {
                  value.content = insertAtCursor(this.editor, content);
                  this.updateState({ ...value });
                }}
                content={this.state.content}
                entities={this.props.entities}
                discardHtml={true}
              />
            </Form.Field>

            <Form.Field>
              <FormDropzoneDropdown
                isMultiple={false}
                attachments={this.props.attachments}
                onChangeAttachments={(addedAttachments) => this.setState({ selectedAttachmentID: addedAttachments[0] })}
                onDropAccepted={this.onUploadAttachment}
                options={this.state.attachmentsOptions}
                value={this.state.selectedAttachmentID!}
                // Instagram limitation for reply attachmets
                maxFileSize={1024 * 1024 * 8}
                acceptExtensions={{
                  'audio/wav': ['.wav'],
                  'audio/mp4': ['.mp4a'],
                  'image/png': ['.png'],
                  'image/jpeg': ['.jpeg'],
                  'video/mp4': ['.mp4'],
                  'video/ogg': ['.ogv'],
                  'video/x-msvideo': ['.avi'],
                  'video/quicktime': ['.mov'],
                  'video/webm': ['.webm']
                }}
              />
              <Message size="tiny" style={{ padding: '6px 9px' }}>
                <p>Attachments limit is 8MB</p>
              </Message>
            </Form.Field>

            <Form.Field id="commentContentField">
              <label>{t('ADD_COMMENT_CONTENT')}</label>
              <ReplyTextArea
                ref={(ref) => (this.editor = ref)}
                content={this.state.content}
                onChange={this.handleChangeContent}
                onKeyDown={this.handleHotKeys}
              />
            </Form.Field>

            <ReplyControlButtons
              small={this.props.smallButtons}
              disabled={this.state.isLoading || !this.state.content.length}
              loading={this.state.isLoading}
              onClear={this.clearFields}
              onSubmit={this.submitComment}
            />
          </Form>
        )}
      </Translation>
    );
  }
}

export default ReplyInstagram;

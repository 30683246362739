import React, { useState } from 'react';
import type { FC } from 'react';

interface CommentsContainerCtx {
  shouldShrink: boolean;
  setShouldShrink: React.Dispatch<React.SetStateAction<boolean>>;
}

const CommentsContainerContext = React.createContext<CommentsContainerCtx>({
  shouldShrink: false,
  setShouldShrink: () => {
    //
  }
});

const CommentsContainerContextProvider: FC = ({ children }) => {
  const [shouldShrink, setShouldShrink] = useState<boolean>(false);
  return (
    <CommentsContainerContext.Provider
      value={{
        shouldShrink,
        setShouldShrink
      }}
    >
      {children}
    </CommentsContainerContext.Provider>
  );
};

export { CommentsContainerContext, CommentsContainerContextProvider };

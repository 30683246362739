import { FETCH_CHAT_STATUSES_SUCCESS } from '../actions/index';
const initialState: any = [];

const chatStatusesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_CHAT_STATUSES_SUCCESS: {
      return action.chats;
    }
    default:
      return state;
  }
};

export default chatStatusesReducer;

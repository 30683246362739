import React from 'react';
import { useTranslation } from 'react-i18next';

import CommentWidgetButton from './CommentWidgetButton';
import * as styles from './CommentWidgetButtons.style';

export interface CommentWidgetButtonsProps {
  buttonsType?: 'basic' | 'primary';

  onReplyClick: () => void;
  onReplyAllClick?: () => void;
  onReplyForwardClick?: () => void;
}

export const CommentWidgetButtons = ({
  buttonsType,

  onReplyClick,
  onReplyAllClick,
  onReplyForwardClick
}: CommentWidgetButtonsProps) => {
  const { t } = useTranslation();

  return (
    <div style={styles.wrapper}>
      <CommentWidgetButton
        iconName="reply"
        text={t('COMMENT_MAIL_REPLY')}
        buttonsType={buttonsType}
        onClick={onReplyClick}
      />

      {onReplyAllClick && (
        <CommentWidgetButton
          iconName="reply all"
          text={t('COMMENT_MAIL_REPLY_ALL')}
          buttonsType={buttonsType}
          style={styles.button}
          onClick={onReplyAllClick}
        />
      )}

      {onReplyForwardClick && (
        <CommentWidgetButton
          iconName="mail forward"
          text={t('COMMENT_MAIL_FORWARD')}
          buttonsType={buttonsType}
          style={styles.button}
          onClick={onReplyForwardClick}
        />
      )}
    </div>
  );
};

import iziToast from 'izitoast';
import { clone } from 'ramda';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Button, Dimmer, Divider, Dropdown, Grid, Input, Loader, Pagination } from 'semantic-ui-react';

import type { VFC } from 'react';
import type { ConnectedProps } from 'react-redux';
import type { PaginationProps } from 'semantic-ui-react';

import EntityCreationModal from './EntityCreationModal/EntityCreationModal';
import EntityDetails from './EntityDetailed';
import EntityMergeModal from './EntityMergeModal';
import EntityResult from './EntityResult/EntityResult';
import { updateEntityDetails } from 'src/actions/ticketsActions';
import EntityApi from 'src/api/EntityApi';
import TicketsApi from 'src/api/TicketsApi';
import Info from 'src/Components/Case/Info/Info';
import {
  clearFilledEntitySearchFields,
  searchEntitiesSuccess,
  searchTicketsSuccess,
  setEntityViewerPage,
  setFilledEntitySearchFields,
  setPickedSearchEntityTypes,
  setSelectedEntity,
  updateEntityField
} from 'src/reducers/entityViewerReducer';
import { apiClient } from 'src/Utilities/httpClients';
import {
  buildSearchUrl,
  getEntityFields,
  getEntityFieldsByEntityType,
  getEntityTypes,
  getMutableEntityTypes,
  getRemovableEntityTypes,
  isEntityTypeReadonly
} from 'src/Utilities/ticketTypes';

import type { UpdateEntityDetail } from 'src/api/TicketsApi';
import type { OnDropdownChange, OnInputChange } from 'src/types/Campaign';
import type { EntitySearchResult } from 'src/types/EntityViewer';
import type { Field, OnInfoSave } from 'src/types/Info';
import type { State } from 'src/types/initialState';
import type { Entity, TicketListTicket } from 'src/types/Ticket';

const PAGE_SIZE = 10;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EntityViewerProps extends ConnectedProps<typeof connector> {}

const EntityViewer: VFC<EntityViewerProps> = ({
  ticketTypes,
  filledEntitySearchFields,
  selectedEntity,
  pickedSearchEntityTypes,
  entities,
  page,
  tickets
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchString, setSearchString] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [entitiesToMerge, setEntitiesToMerge] = useState<Entity[]>([]);

  const entityTypes = useMemo(
    () =>
      getEntityTypes(ticketTypes).map(({ entityType, displayName }) => ({
        text: displayName ?? entityType,
        value: entityType
      })),
    [ticketTypes]
  );
  const removableEntityTypes = useMemo(
    () =>
      new Set([
        ...getMutableEntityTypes(ticketTypes).map(({ entityType }) => entityType),
        ...getRemovableEntityTypes(ticketTypes).map(({ entityType }) => entityType)
      ]),
    [ticketTypes]
  );

  const performUserSearch = useCallback(() => {
    if (Object.keys(filledEntitySearchFields).length === 0) {
      return;
    }

    const url = buildSearchUrl({
      ...filledEntitySearchFields,
      entityTypes: pickedSearchEntityTypes
    });

    setIsLoading(true);

    return apiClient
      .get(url)
      .then((response) => {
        iziToast.success({
          message: t('SEARCH_RESULTS', { amount: response.data.length }),
          icon: 'icon check'
        });
        const results = response.data || [];
        dispatch(searchEntitiesSuccess(results));
      })
      .catch((error) => {
        iziToast.error({
          message: t('SEARCH_RESULTS_ERROR'),
          timeout: 3000,
          position: 'bottomRight'
        });
        console.error('Error while searcing entities', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [pickedSearchEntityTypes, filledEntitySearchFields]);

  const onSearchFieldChange = useCallback<OnInfoSave>(
    (...args) => {
      const [fieldName, valueToSave] = args;
      const newValues = clone(filledEntitySearchFields);
      newValues[fieldName] = valueToSave;

      dispatch(setFilledEntitySearchFields({ fieldName, value: valueToSave }));
    },
    [filledEntitySearchFields]
  );

  const performTicketsOfEntitySearch = useCallback(
    (entityId: string, entityType: string) =>
      TicketsApi.getTicketsByEntity(entityId, entityType, 'task')
        .then((response) => {
          iziToast.success({
            message: t('SEARCH_RESULTS', { amount: response.length }),
            icon: 'icon check'
          });
          const tickets = response || [];
          dispatch(searchTicketsSuccess({ entityId, tickets }));
        })
        .catch((error) => {
          iziToast.error({
            message: t('SEARCH_RESULTS_ERROR'),
            timeout: 3000,
            position: 'bottomRight'
          });
          console.error('Error while searcing entities', error);
        }),
    []
  );

  const chosenTicketTypes = useMemo(
    () =>
      ticketTypes.filter(
        (ticketType) =>
          !!ticketType.entityRouting?.find((entityRoute) => pickedSearchEntityTypes.includes(entityRoute.entityType))
      ),
    [ticketTypes, pickedSearchEntityTypes]
  );
  const searchFields: Field[] = useMemo(() => getEntityFields(chosenTicketTypes), [chosenTicketTypes]);
  const pickedEntityFields = useMemo(
    () =>
      getEntityFieldsByEntityType(ticketTypes, selectedEntity?._type ?? '').filter((field) => field.value !== '_id'),
    [ticketTypes, selectedEntity?._type]
  );
  const filteredResults = useMemo(
    () =>
      Object.values(entities).filter((erb) =>
        Object.keys(erb).some((erbKey) => typeof erb[erbKey] === 'string' && erb[erbKey].indexOf(searchString) !== -1)
      ),
    [entities, searchString]
  );
  const entitiesAndEntityFieldSets = useMemo(
    () =>
      filteredResults.map((entity) => ({
        entity,
        entityFields: getEntityFieldsByEntityType(ticketTypes, entity._type),
        readonly: isEntityTypeReadonly(ticketTypes, entity._type)
      })),
    [filteredResults, ticketTypes]
  );

  const onEntityTypeChange = useCallback<OnDropdownChange>((_event, data) => {
    if (!(data.value as string[]).length) {
      dispatch(clearFilledEntitySearchFields());
    }

    dispatch(setPickedSearchEntityTypes(data.value as string[]));
  }, []);

  const onSearchInputChange = useCallback<OnInputChange>((_event, data) => {
    setSearchString(data.value as string);
  }, []);

  const onPageChange = useCallback<(event: React.MouseEvent<HTMLAnchorElement>, data: PaginationProps) => void>(
    (_event, { activePage }) => {
      dispatch(setEntityViewerPage(activePage as number));
    },
    []
  );

  const onEntityMergeModalClose = useCallback(() => {
    setEntitiesToMerge([]);
  }, []);

  const onEntityMergeModalSuccess = useCallback(
    (updatedEntity: EntitySearchResult) => {
      dispatch(setSelectedEntity(updatedEntity));
      performUserSearch()?.then(() => {
        if (updatedEntity) {
          performTicketsOfEntitySearch(updatedEntity._id, updatedEntity._type);
        }
      });
    },
    [filledEntitySearchFields]
  );

  const onEntityDelete = useCallback(
    (entity?: Entity) => {
      const entityToDelete = entity ?? selectedEntity;

      if (!entityToDelete) {
        return Promise.resolve();
      }

      return EntityApi.deleteEntity({
        id: entityToDelete._id,
        entityType: entityToDelete._type,
        mongodb: entityToDelete.mongodb
      })
        .then((response) => {
          dispatch(setSelectedEntity(undefined));
          performUserSearch();
          iziToast.success({
            message: t('entityViewer.entity_delete_success'),
            icon: 'icon check'
          });
          const entityResult = response.data;
          console.log('response from deleting entities %o', entityResult);
        })
        .catch((error) => {
          iziToast.error({
            message: t('entityViewer.entity_delete_failed'),
            timeout: 3000,
            position: 'bottomRight'
          });
          console.error('Error while searcing entities', error);
        });
    },
    [selectedEntity, filledEntitySearchFields]
  );

  const onEntityDetailsSave = useCallback(
    (updateArgs: UpdateEntityDetail) => {
      (dispatch(updateEntityDetails({ updateArgs })) as any)
        .then(() => {
          const { entityId, fieldName, valueToSave } = updateArgs;
          dispatch(updateEntityField({ entityId, fieldName, valueToSave }));
        })
        .catch(() => {
          iziToast.error({ message: t('entityViewer.entity_update_failed'), timeout: 3000, position: 'bottomRight' });
        });
    },
    [filledEntitySearchFields]
  );

  const pickedEntityTickets = tickets?.[selectedEntity?._id ?? ''] ?? ([] as TicketListTicket[]);

  const entityResults = useMemo(
    () => entitiesAndEntityFieldSets.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE),
    [page, entitiesAndEntityFieldSets]
  );

  return (
    <Grid divided style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', margin: 0, height: '100%' }}>
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column width={5} style={{ padding: '15px' }}>
          <h2 style={{ display: 'flex', justifyContent: 'space-between' }}>
            {t('entityViewer.search_entities')}
            <Button primary onClick={performUserSearch} disabled={!pickedSearchEntityTypes.length || isLoading}>
              {t('SEARCH')}
            </Button>
          </h2>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <label style={{ marginLeft: '10px' }}>{t('GENERAL_TYPE')}</label>
            <Dropdown
              multiple
              selection
              data-test="entityViewer"
              value={pickedSearchEntityTypes}
              onChange={onEntityTypeChange}
              placeholder={t('DROPDOWN_CHOOSE')}
              options={entityTypes}
            />
          </div>
          <div style={{ height: 'calc(100vh - 225px)', overflow: 'auto', padding: '5px 10px', marginTop: '10px' }}>
            <Info
              disableWarning
              searchableFields
              fields={searchFields}
              onSave={onSearchFieldChange}
              values={filledEntitySearchFields}
            />
          </div>
          <br />
        </Grid.Column>

        <Grid.Column width={5} style={{ padding: '15px' }}>
          <Dimmer active={isLoading} inverted>
            <Loader inverted>{t('LOADING')}</Loader>
          </Dimmer>
          <h2 style={{ marginTop: 0 }}>
            {t('entityViewer.search_entities_results')} ({filteredResults.length}
            {' / '}
            {Object.values(entities).length})
          </h2>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ width: '40%' }}>
              <Input
                fluid
                icon="search"
                placeholder={t('entityViewer.search_from_entities_results')}
                onChange={onSearchInputChange}
              />
            </div>
            <div>
              <EntityCreationModal onSuccess={performUserSearch} />
            </div>
          </div>
          <Divider />
          <EntityMergeModal
            open={entitiesToMerge.length > 0}
            entities={entitiesToMerge}
            onClose={onEntityMergeModalClose}
            onSuccess={onEntityMergeModalSuccess}
          />
          <div style={{ maxHeight: '72vh', marginBottom: '10px', overflowY: 'auto', padding: '5px' }}>
            {entityResults.map(({ entity, entityFields, readonly }) => (
              <EntityResult
                key={`entity-result-${entity._id}`}
                values={entity}
                readonly={readonly}
                fields={entityFields}
                mongodb={!!entity.mongodb}
                isAttached={selectedEntity?._id === entity._id}
                onTicketCreateSuccess={() => {
                  performTicketsOfEntitySearch(entity._id, entity._type);
                }}
                onDrop={(droppedIds) => {
                  if (readonly) {
                    iziToast.error({
                      message: "This entity is readonly and can't be merged",
                      timeout: 3000,
                      position: 'center',
                      overlay: true
                    });

                    return;
                  }

                  const [mainEntityId, entityToMergeId] = droppedIds;
                  const mainEntity = entities[mainEntityId];
                  const entityToMerge = entities[entityToMergeId];

                  if (mainEntityId !== entityToMergeId && mainEntity._type === entityToMerge._type) {
                    setEntitiesToMerge([mainEntity, entityToMerge]);
                  }
                }}
                onClick={() => {
                  performTicketsOfEntitySearch(entity._id, entity._type);
                  dispatch(setSelectedEntity(entity));
                }}
                deleteEntity={removableEntityTypes.has(entity._type) ? onEntityDelete : undefined}
              />
            ))}
          </div>
          {filteredResults.length > PAGE_SIZE && (
            <div style={{ padding: '0 10px', display: 'flex', justifyContent: 'center' }}>
              <Pagination
                boundaryRange={0}
                activePage={page}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={Math.ceil(filteredResults.length / PAGE_SIZE)}
                onPageChange={onPageChange}
              />
            </div>
          )}
        </Grid.Column>

        <Grid.Column width={6} style={{ padding: '15px' }}>
          {selectedEntity && (
            <EntityDetails
              fields={pickedEntityFields}
              entityTickets={pickedEntityTickets}
              values={selectedEntity}
              deleteEntity={onEntityDelete}
              onSave={onEntityDetailsSave}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const connector = connect((state: State) => ({
  ticketTypes: state.ticketTypes,
  page: state.entityViewer.page,
  tickets: state.entityViewer.tickets,
  entities: state.entityViewer.entities,
  selectedEntity: state.entityViewer.selectedEntity,
  pickedSearchEntityTypes: state.entityViewer.pickedSearchEntityTypes,
  filledEntitySearchFields: state.entityViewer.filledEntitySearchFields
}));

export default connector(EntityViewer);

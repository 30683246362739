import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import { Button, Divider, Icon, Label, Modal, Table } from 'semantic-ui-react';

import type { FC } from 'react';

import { trimAndSplitRecipients } from 'src/Components/Utilities/recipients';
import { TicketStatusAfterCommentSubmit } from 'src/types/Salesforce';

import type { Attachment } from 'src/types/Ticket';

export interface ReplyEmailSubmitModalProps {
  isOpen: boolean;
  canCloseAsDone: boolean;
  content: string;
  cc: string;
  subject: string;
  bcc: string;
  to: string;
  from: string;
  selectedAttachmentIds: string[];
  attachments: Attachment[];
  closeWithStatus?: TicketStatusAfterCommentSubmit;

  closeModal: () => void;
  submitEmail: (closeWithStatus?: TicketStatusAfterCommentSubmit) => Promise<void>;
}

const ReplyEmailSubmitModal: FC<ReplyEmailSubmitModalProps> = (props) => {
  const getLabelForCloseWithStatus = (status: TicketStatusAfterCommentSubmit) => {
    switch (status) {
      case TicketStatusAfterCommentSubmit.todo: {
        return 'GENERAL_SEND_AND_CLOSE_AS_TODO';
      }
      case TicketStatusAfterCommentSubmit.doing: {
        return 'GENERAL_SEND_AND_CLOSE_AS_DOING';
      }
      case TicketStatusAfterCommentSubmit.done: {
        return 'GENERAL_SEND_AND_CLOSE';
      }
    }
  };

  const getSelectedAttachments = (selectAttachmentIds: string[]): Attachment[] =>
    props.attachments.filter(
      (attachment: Attachment) =>
        !attachment.isQuarantined && !attachment.deprecated && selectAttachmentIds.includes(attachment.id)
    );

  const [isLoading, setIsLoading] = useState(false);

  const submit = async (closeWithStatus?: TicketStatusAfterCommentSubmit) => {
    setIsLoading(true);
    try {
      await props.submitEmail(closeWithStatus);
    } finally {
      props.closeModal();
      setIsLoading(false);
    }
  };

  return (
    <Translation ns="translations">
      {(tr) => (
        <Modal
          open={props.isOpen}
          onClose={() => {
            props.closeModal();
          }}
          closeOnDocumentClick
          closeOnDimmerClick
          closeOnEscape
          centered
        >
          <Modal.Header>{tr('COMMENT_SEND_AS_EMAIL_QUESTION')}</Modal.Header>
          <Modal.Description style={{ padding: '20px' }}>
            <Table celled padded>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{tr('ADD_COMMENT_TITLE')}</Table.Cell>
                  <Table.Cell>{props.subject}</Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>{tr('ADD_COMMENT_SENDER_EMAIL_ADDRESS')}</Table.Cell>
                  <Table.Cell>{props.from}</Table.Cell>
                </Table.Row>

                {props.selectedAttachmentIds.length > 0 && (
                  <Table.Row>
                    <Table.Cell>{tr('ADD_COMMENT_ATTACHMENT_LABEL')}</Table.Cell>
                    <Table.Cell>
                      {getSelectedAttachments(props.selectedAttachmentIds).map((attachment) => (
                        <Label>{attachment.fileName}</Label>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
            <Divider horizontal>
              <Icon name="arrow down" />
            </Divider>
            <Table celled padded>
              <Table.Body>
                {props.to && (
                  <Table.Row>
                    <Table.Cell>{tr('ADD_COMMENT_RECEPIENTS')}</Table.Cell>
                    <Table.Cell>
                      {trimAndSplitRecipients(props.to)?.map((recipient: string) => (
                        <>{!isEmpty(recipient) && <Label style={{ margin: '2px' }}>{recipient}</Label>}</>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                )}
                {props.cc && (
                  <Table.Row>
                    <Table.Cell>CC</Table.Cell>
                    <Table.Cell>
                      {trimAndSplitRecipients(props.cc)?.map((recipient: string) => (
                        <>{!isEmpty(recipient) && <Label style={{ margin: '2px' }}>{recipient}</Label>}</>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                )}
                {props.bcc && (
                  <Table.Row>
                    <Table.Cell>BCC</Table.Cell>
                    <Table.Cell>
                      {trimAndSplitRecipients(props.bcc)?.map((recipient: string) => (
                        <>{!isEmpty(recipient) && <Label style={{ margin: '2px' }}>{recipient}</Label>}</>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Modal.Description>
          <Modal.Actions>
            <Button disabled={isLoading} labelPosition="left" onClick={() => props.closeModal()} negative icon>
              <Icon name="remove" />
              {tr('GENERAL_CANCEL')}
            </Button>
            <Button
              disabled={isLoading}
              labelPosition="left"
              loading={isLoading}
              onClick={() => submit(props.closeWithStatus)}
              icon
              primary
            >
              <Icon name="send" />
              {tr(props.closeWithStatus ? getLabelForCloseWithStatus(props.closeWithStatus) : 'GENERAL_SEND')}
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Translation>
  );
};

export default ReplyEmailSubmitModal;

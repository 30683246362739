import { t } from 'i18next';
import type { IziToastSettings } from 'izitoast';
import iziToast from 'izitoast';
import type { AxiosError } from 'axios';
import type { Dispatch, ActionCreator } from 'redux';
import type { ThunkAction } from 'redux-thunk';

import { UPDATE_INFOPAGE_SUCCESS, UPDATE_INFOPAGE_FAILURE } from './index';
import { contentsVC } from 'src/api/VersionControl';
import { taskIdToNumericalId } from '../Utilities/ticketList';
import InfoPagesApi from '../api/InfoPagesApi';
import { history } from '../reducers';
import { startAjaxCall } from './ajaxStatusActions';
import { activateTab, closeTab } from './tabActionsRTK';
import { fetchFeatureFlags } from './configActions';
import FeatureFlags from '../api/FeatureFlags';
import { fetchInfoPagesError, fetchInfoPagesSuccess } from './infoPagesActionsRTK';
import { StaticTabs } from 'src/types/TicketList';
import { typeToPrefix } from 'src/types/ContentTypes';
import { removeTicketFromDetailed } from './ticketsActionsRTK';
import type { Ticket } from 'src/types/Ticket';
import type { State } from 'src/types/initialState';

export const updateInfoPageSuccess = (contentId: string) => {
  return { type: UPDATE_INFOPAGE_SUCCESS, contentId };
};

export const updateInfoPageFailure = (contentId: string) => {
  return { type: UPDATE_INFOPAGE_FAILURE, contentId };
};

export function fetchInfoPages(searchParams: object | undefined, id: string, throughSearchTab: boolean) {
  return (dispatch: Dispatch) => {
    if (!FeatureFlags.isFlagOn('ENABLE_KNOWLEDGE_BASE')) return;

    const showIziToast = (settings: IziToastSettings) => {
      setTimeout(() => {
        iziToast.show({
          displayMode: 2,
          id: 'searchToast',
          target: '.toastSearchTarget',
          ...settings
        });
      }, 500);
    };

    dispatch(startAjaxCall({ name: 'FETCH_INFOPAGES' }));
    return InfoPagesApi.getInfoPages({ searchterms: { ...searchParams } })
      .then((infoPages) => {
        if ((!infoPages || infoPages.length < 1) && throughSearchTab) {
          showIziToast({
            color: 'blue',
            message: t('SEARCH_RESULT_NO_INFO_PAGES')
          });
        } else if (infoPages && infoPages.length === 1 && infoPages[0].id === 'Error') {
          showIziToast({
            color: 'red',
            message: t('SEARCH_FAILED')
          });
        } else if (infoPages && infoPages.length >= 1 && throughSearchTab && infoPages[0].id !== 'Error') {
          showIziToast({
            color: 'green',
            message: t('SEARCH_RESULTS_INFO_PAGES_COUNT') + '' + infoPages.length + '' + t('SEARCH_INFO_PAGES_PREFIX')
          });
        }
        dispatch(fetchInfoPagesSuccess({ tickets: infoPages, id }));
      })
      .catch((error: AxiosError) => {
        console.error('Failed to load info pages', error);
        dispatch(fetchInfoPagesError(id));
      });
  };
}

export const updateInfoPage: ActionCreator<ThunkAction<any, State, any, any>> = (
  id: string,
  content: Partial<Ticket>,
  closeAfterUpdate: boolean
) => {
  return async (dispatch: Dispatch, getState: () => State) => {
    dispatch(startAjaxCall({ name: 'UPDATE_INFOPAGE', id: id }));

    try {
      await InfoPagesApi.updateInfopage(id, content);
      contentsVC.clearEntry(taskIdToNumericalId(id));

      if (closeAfterUpdate) {
        const { ticketTabs } = getState();
        const tabs = ticketTabs.filter((tab) => {
          return tab.id !== id && tab.id.substring(0, 3) === typeToPrefix('infopage');
        });

        if (tabs.length === 0) {
          history.push('/infopage');
        } else if (tabs.length > 0) {
          const activeId = tabs[tabs.length - 1].id;
          history.push(`/infopage/${activeId}`);
          dispatch(activateTab(activeId));
        }

        dispatch(closeTab(id));
        dispatch(removeTicketFromDetailed(id));
      }

      dispatch(updateInfoPageSuccess(id));

      iziToast.success({
        title: t('OK'),
        icon: 'icon check',
        message: t('INFOPAGE_SAVE_SUCCESS'),
        timeout: 2500
      });
    } catch (error) {
      console.error('Error while updating infopage', error);
      iziToast.error({
        title: `${t('ERROR')}!`,
        icon: 'icon delete',
        message: t('INFOPAGE_SAVE_FAILURE'),
        timeout: 7500
      });
      dispatch(updateInfoPageFailure(id));
    }
  };
};

export const fetchFeatureFlagsAndInfoPages = () => {
  return async (dispatch: Dispatch) => {
    await fetchFeatureFlags()(dispatch);
    await fetchInfoPages(undefined, StaticTabs.MAIN_VIEW, false)(dispatch);
  };
};

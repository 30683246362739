import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Divider, Input, List, ListItem, Loader, Message, Table } from 'semantic-ui-react';

import type { FC } from 'react';

import ActionField from './ActionField';
import ComplexList from './ComplexList';
import GoogleMapsField from './GoogleMapsField';
import InfoCheckbox from './InfoCheckbox';
import InfoDatepicker from './InfoDatepicker';
import InfoDateRange from './InfoDateRange';
import InfoFieldDropdown from './InfoFieldDropdown';
import InfoLink from './InfoLink';
import PhoneNumber from './PhoneNumber';
import SubEntityField from './SubEntityField';
import TextAreaField from './TextAreaField';
import VerifyFields from './VerifyFields';
import DateSelector from 'src/Components/Case/DateSelector';
import FieldHeader from 'src/Components/Case/Info/FieldHeader/FieldHeader';
import DefaultInfoField from 'src/Components/Case/Info/InfoField/DefaultInfoField';
import { parseFieldValue } from 'src/Components/Utilities/infoField';
import { FieldTypes } from 'src/types/Info';
import { getInfoInputType } from 'src/Utilities/info';

import type { InfoFieldProps } from 'src/Components/Case/Info/InfoField/InfoFieldProps';
import type { DropdownOption, Field } from 'src/types/Info';
import type { State } from 'src/types/initialState';

const nonDefaultFields: Field['customType'][] = [
  FieldTypes.action,
  FieldTypes.complexList,
  FieldTypes.datepicker,
  FieldTypes.daterange,
  FieldTypes.dateselector,
  FieldTypes.empty,
  FieldTypes.errorMessage,
  FieldTypes.googleMaps,
  FieldTypes.link,
  FieldTypes.list,
  FieldTypes.loadingChevron,
  FieldTypes.phoneNumber,
  FieldTypes.separator,
  FieldTypes.subAccordion,
  FieldTypes.subEntityField,
  FieldTypes.textarea,
  FieldTypes.verifyFields
];

const InfoField: FC<InfoFieldProps> = ({
  fields,
  field,
  values,
  isMobile,
  params,
  task,
  taskId,
  taskType,
  language,
  entity,
  fieldWidth = 10,
  headerWidth = 6,
  /**
   * TODO: this is actually data from the state, but here
   * the fact if it is passed or not is part of business logic
   * should move those parts of component that depends
   * */
  personalData,
  generalDisable,
  disableInputIfNotMongoCustomer,
  ticketTypes,
  fireSearch,
  onSaveData,
  setValue,
  fieldSearchableStatus,
  disableWarning,
  filterOptions
}) => {
  const { t } = useTranslation();
  const mongoSaveDisabled = params?.ignoreFieldDisabling
    ? false
    : !!((values?.mongoCustomer && field?.params?.noMongo) || field?.params?.readOnly);
  const integrationSaveDisabled = params?.ignoreFieldDisabling
    ? false
    : (!values?.mongoCustomer && !values?.twoWayIntegrationEntity && disableInputIfNotMongoCustomer) ||
      !!field.params?.readOnly;
  const mongoNoRender = values?.mongoCustomer && field.params?.mongoNoRender;
  const integrationNoRender = !values?.mongoCustomer && field.params?.integrationNoRender;
  const ticketType = ticketTypes.find((type) => type.name === taskType)!;

  if (mongoNoRender || integrationNoRender) {
    return null;
  }

  if (field.value === undefined) {
    field.value = '';
  }

  let fieldValue: string | string[] | { name: string; value: string }[] | DropdownOption[] = '';

  if (
    ![
      FieldTypes.action,
      FieldTypes.empty,
      FieldTypes.errorMessage,
      FieldTypes.loadingChevron,
      FieldTypes.separator,
      FieldTypes.subAccordion,
      FieldTypes.subEntityField,
      FieldTypes.complexList
    ].includes(field.customType!)
  ) {
    const shouldEncodeUriComponent = field.customType === FieldTypes.link;

    fieldValue += parseFieldValue({
      field,
      values,
      shouldEncodeUriComponent,
      personalData,
      task
    });
  } else if ([FieldTypes.complexList].includes(field.customType!)) {
    fieldValue = parseFieldValue({
      field,
      values,
      shouldEncodeUriComponent: false,
      personalData,
      task
    });
  }

  const FieldHeaderComponent: FC<{ isNegative?: boolean; value: any }> = ({ value, isNegative = false }) => (
    <FieldHeader
      taskId={taskId}
      taskType={taskType}
      field={field}
      fieldValue={value}
      personalData={personalData}
      fireSearch={fireSearch}
      isNegative={isNegative}
      width={headerWidth}
    />
  );

  const inputType = getInfoInputType(field);
  const renderDefault =
    !nonDefaultFields.includes(field.customType) && field.options === undefined && field.switch === undefined;

  if (renderDefault) {
    return (
      <>
        <FieldHeaderComponent value={fieldValue} />
        <DefaultInfoField
          field={field}
          fields={fields}
          values={values}
          taskId={taskId!}
          width={fieldWidth}
          inputType={inputType}
          ticketType={ticketType}
          fieldValue={fieldValue as string}
          setValue={setValue}
          onSaveData={onSaveData}
          disableWarning={disableWarning}
          {...(!fieldSearchableStatus && {
            disabled: generalDisable,
            integrationSaveDisabled,
            mongoSaveDisabled
          })}
        />
      </>
    );
  }

  const renderSimpleFields =
    field.options === undefined &&
    ![
      FieldTypes.action,
      FieldTypes.complexList,
      FieldTypes.datepicker,
      FieldTypes.daterange,
      FieldTypes.verifyFields
    ].includes(field.customType!);

  if (renderSimpleFields) {
    return (
      <>
        <FieldHeaderComponent value={fieldValue} />
        <Table.Cell width={fieldWidth} style={field?.customType === 'link' ? { maxWidth: '100px' } : undefined}>
          {field?.customType === FieldTypes.separator && <Divider />}
          {field?.customType === FieldTypes.loadingChevron && <Loader />}
          {field?.customType === FieldTypes.empty && <div />}
          {field?.customType === FieldTypes.errorMessage && (
            <Message warning={true} icon={true}>
              <Message.Header>
                <p>{t('ENTITY_ENCOUNTERED_ERROR_DESCRIPTION')}</p>
              </Message.Header>
            </Message>
          )}
          {field?.switch !== undefined && (
            <InfoCheckbox
              field={field}
              disabled={integrationSaveDisabled || mongoSaveDisabled}
              fieldValue={fieldValue}
              onSaveData={onSaveData}
            />
          )}
          {field?.customType === FieldTypes.subEntityField && (
            <SubEntityField
              field={field}
              values={values}
              inputType={inputType}
              setValue={setValue}
              onSaveData={onSaveData}
              disableWarning={disableWarning}
              {...(!fieldSearchableStatus && {
                disabled: generalDisable,
                integrationSaveDisabled,
                mongoSaveDisabled
              })}
            />
          )}
          {field?.customType === FieldTypes.link && typeof fieldValue === 'string' && (
            <InfoLink field={field} fieldValue={fieldValue} taskId={taskId} />
          )}
          {field?.customType === 'list' && (
            <List divided={true} className="infoList">
              {fieldValue === undefined ? (
                <Input key="3" type="text" disabled={true} size="mini" fluid={true} />
              ) : (
                (fieldValue as unknown as string[]).map((fv, index: number) => (
                  <ListItem key={`info-list-item-${index}`}>{fv}</ListItem>
                ))
              )}
            </List>
          )}
          {field?.customType === 'phoneNumber' && (
            <PhoneNumber
              fieldValue={fieldValue}
              inputType={inputType}
              onSaveData={onSaveData}
              setValue={setValue}
              field={field}
              disableWarning={disableWarning}
              {...(!fieldSearchableStatus && {
                disabled: generalDisable,
                integrationSaveDisabled,
                mongoSaveDisabled
              })}
            />
          )}
          {field?.customType === 'dateselector' && (
            <DateSelector value={fieldValue} onChange={(timestamp) => onSaveData?.(field, timestamp)} />
          )}
          {field?.customType === 'textarea' && (
            <TextAreaField
              fieldValue={fieldValue}
              onSaveData={onSaveData}
              setValue={setValue}
              field={field}
              {...(!fieldSearchableStatus && {
                disabled: generalDisable,
                integrationSaveDisabled,
                mongoSaveDisabled
              })}
            />
          )}
          {field?.customType === 'googleMaps' && (
            <GoogleMapsField
              field={field}
              fieldValue={fieldValue as string}
              onSaveData={onSaveData}
              {...(!fieldSearchableStatus && {
                disabled: generalDisable,
                integrationSaveDisabled,
                mongoSaveDisabled
              })}
            />
          )}
        </Table.Cell>
      </>
    );
  }

  return (
    <>
      {field?.options !== undefined && (
        <InfoFieldDropdown
          field={field}
          values={values}
          width={fieldWidth}
          fieldValue={fieldValue as unknown as DropdownOption[]}
          disabled={generalDisable}
          selectionEnabled={integrationSaveDisabled || mongoSaveDisabled}
          setValue={setValue}
          onSaveData={onSaveData}
          renderHeader={FieldHeaderComponent}
          placeholder={t('CHOOSE')}
          noResultsMessage={t('GENERAL_SEARCH_NO_RESULTS')}
          filterOptions={filterOptions}
        />
      )}
      {!!entity && field?.customType === 'verifyFields' && (
        <VerifyFields
          taskId={taskId}
          field={field}
          values={values}
          fieldSet={fields}
          entity={entity}
          InfoFieldComponent={InfoField}
          ticketTypes={ticketTypes}
        />
      )}
      {field?.customType === FieldTypes.action && (
        <ActionField taskId={taskId} taskType={taskType} field={field} values={values} />
      )}
      {field?.customType === FieldTypes.daterange && (
        <>
          <FieldHeaderComponent value={fieldValue} />
          <InfoDateRange
            field={field}
            width={fieldWidth}
            fieldValue={fieldValue as string}
            language={language}
            onSaveData={onSaveData}
            {...(!fieldSearchableStatus && {
              inputDisabled: generalDisable,
              integrationSaveDisabled,
              mongoSaveDisabled
            })}
          />
        </>
      )}
      {field?.customType === 'datepicker' && (
        <>
          <FieldHeaderComponent value={fieldValue} />
          <InfoDatepicker
            field={field}
            width={fieldWidth}
            selectDateMessage={t('SELECT_DATE')}
            isMobile={isMobile}
            disabled={integrationSaveDisabled || mongoSaveDisabled}
            fieldValue={fieldValue as string}
            onSaveData={onSaveData}
          />
        </>
      )}
      {field?.customType === FieldTypes.complexList && typeof fieldValue !== 'undefined' && (
        <ComplexList
          field={field}
          fieldValue={fieldValue as unknown as { name: string; value: string }[]}
          {...(!fieldSearchableStatus && {
            disabled: generalDisable,
            integrationSaveDisabled,
            mongoSaveDisabled
          })}
        />
      )}
    </>
  );
};

const connector = connect((state: State) => ({
  personalData: state.userData,
  ticketTypes: state.ticketTypes,
  task: state.detailedTickets.find((ticket) => ticket.id === state.activeTicketTab)
}));

export default connector(InfoField);

import type { Entity } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';

const isEntityReadOnlyByTicketType = (entity: Entity, ticketType?: TicketType) => {
  if (!ticketType) {
    return false;
  }

  const { entityRouting } = ticketType;
  return entityRouting?.some(({ entityType, readonly }) => entityType === entity._type && readonly);
};

export default isEntityReadOnlyByTicketType;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { FieldSet, TicketType } from '../../types/TicketType';
import TaskDataContent from './TaskDataContent';
import EntitiesDataContent from './EntitiesDataContent';
import CaseDetailsDataContent from './CaseDetailsDataContent';
import AttachmentsContent from './AttachmentsContent';
import { Channel } from '../../types/Channel';

interface FieldGetter {
  (type: TicketType, fields: FieldSet[]): FieldSet | undefined;
}

interface DisplayTicketDataProps {
  taskType: any;
  origTicketData: any;
  ticketTypes: TicketType[];
  channels: Channel[];
  fieldGetter: FieldGetter;
}

const DataToContent = (props: DisplayTicketDataProps) => {
  const { t } = useTranslation();
  const { taskType, origTicketData, ticketTypes, channels, fieldGetter } = props;

  if (taskType == null || ticketTypes === undefined) {
    return <div></div>;
  }

  const origTicketType = ticketTypes.find((tType: TicketType) => {
    return tType.name === origTicketData.taskData.taskType;
  });

  if (!origTicketType) {
    return <div>{t('ERROR')}</div>;
  }

  const ticketCustomerFields = fieldGetter(origTicketType, origTicketType.fieldSets);
  const ticketDetailsFields = origTicketType.fieldSets.find((fSet: any) => {
    return fSet.id === 'caseInfo' || fSet.id === 'case';
  });

  const displayTicketData: JSX.Element[] = [];

  Object.keys(origTicketData).forEach((key: string, index: number) => {
    const data = origTicketData[key];

    let content;

    switch (key) {
      case 'taskData':
        content = <TaskDataContent index={index} data={data} channels={channels} channel={data.channel} />;
        break;
      case 'entitiesData':
        content = <EntitiesDataContent index={index} ticketCustomerFields={ticketCustomerFields} data={data} />;
        break;
      case 'caseDetailsData':
        content = <CaseDetailsDataContent index={index} ticketDetailsFields={ticketDetailsFields} data={data} />;
        break;
      case 'attachments':
        content = <AttachmentsContent index={index} data={data} />;
        break;
      default:
        break;
    }

    if (content !== undefined) {
      displayTicketData.push(content);
    }
  });

  return <div>{displayTicketData}</div>;
}

export default DataToContent;

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Archival from '../Components/Case/Widget/Archival';
import type { State } from 'src/types/initialState';
import type { TicketArchivalUploadData } from '../types/Archival';
import { sendTicketArchivalRequest } from '../actions/ticketsActions';
import { fetchTemplates } from '../actions/templateActions';

const mapStateToProps = (state: State, ownProps: any) => {
  const ticket = state.detailedTickets.find((ticket: any) => ticket.id === state.activeTicketTab);

  return {
    ticket: ticket,
    userData: state.userData,
    templates: state.templates.templates.filter((template) => {
      return template.type === "archivalTemplate";
    }),
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    submitArchival: (
      sendData: TicketArchivalUploadData) => {
      dispatch(sendTicketArchivalRequest(sendData));
    },
    fetchArchivalTemplates: () => {
      dispatch(fetchTemplates());
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Archival));

import { get } from 'lodash';

import { parseTicketNumber } from 'src/Utilities/parseUtils';

import type { Field } from 'src/types/Info';
import type { Ticket } from 'src/types/Ticket';
import type { PersonalData } from 'src/types/User';

const regex = /\[(.*?)\]/;

const searchValueRecursively = (searchedObject: any, parameterArray: string[], uriEncode: boolean) => {
  parameterArray.forEach((parameterKey: string) => {
    if (searchedObject !== undefined && searchedObject !== null) {
      searchedObject = searchedObject[parameterKey];
    }
  });

  if (uriEncode) {
    searchedObject = encodeURIComponent(searchedObject);
  }

  return searchedObject;
};

// TODO: Imho could be simplified and unified with parseDotedString
const parseStringWithDelimiter = ({
  field,
  personalData,
  shouldEncodeUriComponent,
  task,
  values
}: Pick<ParseValueArgs, 'field' | 'values' | 'personalData' | 'task' | 'shouldEncodeUriComponent'>) => {
  let fieldValue = '';

  field.value
    .toString()
    .split('|')
    .forEach((fieldParam) => {
      const fieldParamRegexTest = fieldParam.match(regex);
      const dotSplit = fieldParam.toString().split('.');
      if (fieldParamRegexTest !== null && fieldParamRegexTest[1].length > 0) {
        fieldValue += fieldParamRegexTest[1];
      } else if (dotSplit.length > 1) {
        if (dotSplit[0] === 'user') {
          fieldValue += searchValueRecursively(personalData?.profile, dotSplit.slice(1), shouldEncodeUriComponent);
        } else if (dotSplit[0] === 'case' && task) {
          fieldValue += searchValueRecursively(
            { id: parseTicketNumber(task.id), ...task.case },
            dotSplit.slice(1),
            shouldEncodeUriComponent
          );
        } else {
          fieldValue += searchValueRecursively(values, dotSplit, shouldEncodeUriComponent);
        }
      } else {
        fieldValue += values[fieldParam.toString()];
      }
    });

  return fieldValue;
};

const parseDotedString = ({
  field,
  values,
  personalData,
  shouldEncodeUriComponent
}: Pick<ParseValueArgs, 'field' | 'values' | 'personalData' | 'task' | 'shouldEncodeUriComponent'>) => {
  const fieldValueStringified = field.value.toString();
  const fieldParamRegexTest = fieldValueStringified.match(regex);
  const dotSplit = fieldValueStringified.split('.');

  if (fieldParamRegexTest === null || fieldParamRegexTest[1].length === 0 || dotSplit.length <= 1) {
    return get(values, fieldValueStringified);
  }

  if (fieldParamRegexTest !== null && fieldParamRegexTest[1].length > 0) {
    return fieldParamRegexTest[1];
  }

  if (dotSplit.length > 1) {
    const isUser = dotSplit[0] === 'user';
    const searchedObject = isUser ? personalData?.profile : values;
    const parameterArray = isUser ? dotSplit.slice(1) : dotSplit;

    return searchValueRecursively(searchedObject, parameterArray, shouldEncodeUriComponent);
  }
};

interface ParseValueArgs {
  field: Field;
  values: any;
  shouldEncodeUriComponent: boolean;
  personalData?: PersonalData;
  task?: Ticket;
}

export const parseFieldValue = ({ field, values, shouldEncodeUriComponent, personalData, task }: ParseValueArgs) => {
  let fieldValue: string | null = '';

  if (field.value.toString().includes('|')) {
    fieldValue = parseStringWithDelimiter({ field, task, values, personalData, shouldEncodeUriComponent });
  } else {
    fieldValue = parseDotedString({ field, values, personalData, shouldEncodeUriComponent });
  }

  if (typeof field.object !== 'undefined') {
    if (!Array.isArray(field.value)) {
      // if there is field.object available, true values is
      fieldValue = values[field.object];
      if (fieldValue !== null && fieldValue !== undefined && typeof field.value !== 'boolean') {
        fieldValue = fieldValue[field.value];
      } else {
        fieldValue = '';
      }
    }
  }

  return fieldValue || '';
};

export const fieldValueReplaceStrings = (fieldValue = '', field: Field): string => {
  let value = fieldValue.replace(/\+/g, '%2B');
  const replaceStrings = [field.params?.replaceString ?? [], ...(field.params?.replaceStrings ?? [])].filter(
    (arr) => arr?.length === 2
  );

  if (replaceStrings.length) {
    replaceStrings.forEach(([pattern, replacement]: string[]) => {
      value = value.replace(pattern, replacement);
    });
  }

  return value;
};

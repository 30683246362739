import type { VFC } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FeatureFlags from 'src/api/FeatureFlags';
import type { Attachment } from 'src/types/Ticket';
import type { PersonalData, User } from 'src/types/User';
import { Roles } from 'src/types/User';
import { hasImageExtension } from 'src/Utilities/images';
import type { AttachmentEdit } from './AttachmentItem';
import AttachmentsAccordion from './AttachmentsAccordion';

interface AttachmentsViewProps {
  user: User;
  attachments: Attachment[];
  personalData: PersonalData;
  dropZoneEnabled: boolean;

  openFileDialog?(): void;
  onUpload?(files: File[]): void;
  onDeprecate?(id: string): void;
  onUnDeprecate?(id: string): void;
  onEdit?(id: string, body: AttachmentEdit): void;
}

type AttachmentSorted = [Attachment[], Attachment[], Attachment[]];

const AttachmentsView: VFC<AttachmentsViewProps> = ({
  user,
  attachments,
  personalData,

  onEdit,
  onUpload,
  onDeprecate,
  onUnDeprecate,
  openFileDialog
}) => {
  const { t } = useTranslation();

  const [deprecated, images, others] = useMemo(
    () =>
      attachments.reduce(
        (acc, attachment) => {
          if (attachment.deprecated) {
            acc[0].push(attachment);
          } else if (hasImageExtension(attachment.fileName)) {
            acc[1].push(attachment);
          } else {
            acc[2].push(attachment);
          }

          return acc;
        },
        [[], [], []] as AttachmentSorted
      ),
    [attachments]
  );

  const userRole = user?.role.id;
  const isAdmin = userRole ? Roles.isAdminLike(userRole) : false;

  return (
    <>
      <AttachmentsAccordion
        initialOpen={true}
        title={t('ATTACHMENTS')}
        attachments={others}
        fullAttachments={attachments}
        uploadFile={onUpload}
        editAttachment={onEdit}
        deprecateAttachment={onDeprecate}
        unDeprecateAttachment={onUnDeprecate}
        openFileDialog={openFileDialog}
        personalData={personalData}
      />
      <AttachmentsAccordion
        initialOpen={!FeatureFlags.isFlagOn('EMBEDDED_ATTACHMENTS_LIST_CLOSED')}
        title={t('IMAGES')}
        attachments={images}
        fullAttachments={attachments}
        uploadFile={onUpload}
        editAttachment={onEdit}
        deprecateAttachment={onDeprecate}
        unDeprecateAttachment={onUnDeprecate}
        openFileDialog={openFileDialog}
        personalData={personalData}
      />
      {isAdmin && (
        <AttachmentsAccordion
          initialOpen={false}
          title={t('DELETED_ATTACHMENTS')}
          attachments={deprecated}
          fullAttachments={attachments}
          uploadFile={onUpload}
          editAttachment={onEdit}
          deprecateAttachment={onDeprecate}
          unDeprecateAttachment={onUnDeprecate}
          openFileDialog={openFileDialog}
          personalData={personalData}
        />
      )}
    </>
  );
};

export default React.memo(AttachmentsView);

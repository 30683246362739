import { t } from 'i18next';
import iziToast from 'izitoast';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  FETCH_EIDENTIFICATIONS_SUCCESS,
  UPDATE_EIDENTIFICATIONS_SUCCESS,
  GENERATE_EIDENTIFICATION_LINK_SUCCESS,
  GET_EIDENTIFICATION_LINK_SUCCESS
} from '.';
import { startAjaxCall } from './ajaxStatusActions';
import EIdentificationApi from 'src/api/EIdentificationApi';
import type { EIdentification } from 'src/types/EIdentification';

export const fetchEIdentificationsSuccess = createAction<{ contentId: number; eIdentifications: EIdentification[] }>(
  FETCH_EIDENTIFICATIONS_SUCCESS
);
export const updateEIdentificationsSuccess = createAction<{ contentId: number }>(UPDATE_EIDENTIFICATIONS_SUCCESS);
export const generateEIdentificationLinkSuccess = createAction<{ contentId: number }>(
  GENERATE_EIDENTIFICATION_LINK_SUCCESS
);
export const getEIdentificationLinkSuccess = createAction<{ hash: string }>(GET_EIDENTIFICATION_LINK_SUCCESS);

export const fetchEIdentifications = createAsyncThunk<
  { status: string },
  { contentId: number },
  { rejectValue: Error }
>('fetchEIdentifications', async ({ contentId }, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'FETCH_EIDENTIFICATIONS', id: contentId }));
  try {
    const result = await EIdentificationApi.getEIdentificationsByContentId(contentId);
    dispatch(fetchEIdentificationsSuccess({ contentId, eIdentifications: result }));
    return { status: 'success' };
  } catch (error) {
    iziToast.error({
      title: t('ERROR_HAPPENED'),
      message: 'Failed to fetch E-Identifications',
      position: 'bottomRight',
      timeout: 2500,
      icon: 'delete'
    });
    return { status: 'failed' };
  }
});

export const updateEIdentification = createAsyncThunk<
  { status: string },
  { contentId: number; hash: string; type: 'accept' | 'reject' },
  { rejectValue: Error }
>('fetchEIdentifications', async (payload, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'UPDATE_EIDENTIFICATIONS', id: payload.contentId }));
  try {
    await EIdentificationApi.updateEIdentification(payload);
    dispatch(updateEIdentificationsSuccess({ contentId: payload.contentId }));
    return { status: 'success' };
  } catch (error) {
    throw error;
  }
});

export const generateEIdentificationLink = createAsyncThunk<
  { link: string },
  { contentId: number },
  { rejectValue: Error }
>('fetchEIdentifications', async ({ contentId }, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'GENERATE_EIDENTIFICATION_LINK', id: contentId }));
  try {
    const result = await EIdentificationApi.generateEIdentificationLink(contentId);
    dispatch(generateEIdentificationLinkSuccess({ contentId }));
    return { link: result.link };
  } catch (error) {
    throw error;
  }
});

export const getEIdentificationLink = createAsyncThunk<{ link: string }, { hash: string }, { rejectValue: Error }>(
  'fetchEIdentifications',
  async ({ hash }, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'GENERATE_EIDENTIFICATION_LINK', hash: hash }));
    try {
      const result = await EIdentificationApi.getEIdentificationLink(hash);
      dispatch(getEIdentificationLinkSuccess({ hash }));
      return { link: result.link };
    } catch (error) {
      throw error;
    }
  }
);

import React from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { filter } from 'lodash';
import { useSelector } from 'react-redux';
import type { VFC } from 'react';

import TicketList from './TicketList';
import type { State } from 'src/types/initialState';
import type { ContentTypes } from 'src/types/ContentTypes';
import type { StatusTypes, InfopageStatusTypes, Ticket } from 'src/types/Ticket';

import 'react-virtualized/styles.css';

interface TicketListProps {
  type: ContentTypes;
  selectedTicketId: string | null;
  active: boolean;
  index: number;
  tickets: Ticket[];
  title: string;
  status: StatusTypes | InfopageStatusTypes;
  shouldOpenTicket?: boolean;

  onClick(index: number): void;
  startWorkingOn?: (currentUserId: string, taskId: string) => void;
}

const TicketListAccordion: VFC<TicketListProps> = ({
  type,
  index,
  status,
  active,
  tickets,
  shouldOpenTicket,
  selectedTicketId,
  title: originalTitle,
  onClick,
  startWorkingOn
}) => {
  const leftTabOpen = useSelector((state: State) => state.mobileReducer.leftTabOpen);
  const mobileMode = useSelector((state: State) => state.mobileReducer.mobileMode);

  // Doing accordion switch without debounce
  const onClickChangeAccordion = () => {
    if (index !== null) {
      onClick(index);
    }
  };

  const icon = <Icon name={active ? 'chevron down' : 'chevron right'} />;
  const title = `${originalTitle} (${tickets.length})`;

  return (
    <React.Fragment>
      <Accordion.Title
        className={`ticketlist ticketlist_${filter}`}
        active={active}
        onClick={onClickChangeAccordion}
        id={status}
      >
        {title} {icon}
      </Accordion.Title>
      <Accordion.Content id={status + '_content'} active={active} style={{ flex: '1 1 auto' }}>
        {/* triggers list re-render */}
        {(!mobileMode || leftTabOpen) && (
          <TicketList
            type={type}
            active={active}
            tickets={tickets}
            shouldOpenTicket={shouldOpenTicket}
            selectedTicketId={selectedTicketId}
            startWorkingOn={startWorkingOn}
          />
        )}
      </Accordion.Content>
    </React.Fragment>
  );
};

export default React.memo(TicketListAccordion);

import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

type ModalProps = { title: string; onClickClose: () => void };
export const ModalWindow: React.FC<ModalProps> = ({ title, children, onClickClose }) => {
  const { t } = useTranslation();

  return (
    <Modal closeOnDocumentClick closeOnDimmerClick closeOnEscape onClose={onClickClose} open>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content scrolling>{children}</Modal.Content>
      <Modal.Actions>
        <Button content={t('GENERAL_CLOSE')} onClick={onClickClose} primary icon="remove" labelPosition="left" />
      </Modal.Actions>
    </Modal>
  );
};

import { AxiosError } from 'axios';
import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Chat } from 'src/types/Chat';
import ChatsApi from '../api/ChatsApi';
import { startAjaxCall } from './ajaxStatusActions';
import { FETCH_CHAT_STATUSES_FAILURE, FETCH_CHAT_STATUSES_SUCCESS, PATCH_CHAT_STATUS_SUCCESS } from './index';
import { initialRequestFailure, initialRequestSuccess } from './initActions';

export const loadChatsSuccess = (chats: Chat[]) => {
  return { type: FETCH_CHAT_STATUSES_SUCCESS, chats };
};
export const loadChatsFailure = (chats: Chat[]) => {
  return { type: FETCH_CHAT_STATUSES_FAILURE, chats };
};

export const patchChatSuccess = (chats: Chat[]) => {
  return { type: PATCH_CHAT_STATUS_SUCCESS, chats };
};

export const fetchChats: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_CHATS' }));
    return ChatsApi.getChats()
      .then((chats) => {
        dispatch(loadChatsSuccess(chats));
        dispatch(initialRequestSuccess('chatStatuses'));
        return chats;
      })
      .catch((error) => {
        console.error('Failed to load chats', error);
        dispatch(loadChatsFailure(error));
        dispatch(initialRequestFailure('chatStatuses', error));
      });
  };
};

export const patchChat: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'PATCH_CHAT' }));
    return ChatsApi.patchChat(data)
      .then((chat) => {
        dispatch(patchChatSuccess(chat[0]));
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

export const patchAllChats: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (switchState: any) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'PATCH_ALL_CHATS' }));
    return ChatsApi.patchAllChats({ switchState })
      .then((chat: any) => {
        dispatch(patchChatSuccess(chat[0]));
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

export const createChat: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'CREATE_CHAT' }));
    return ChatsApi.createChat(data)
      .then((response: any) => {
        return response;
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

import { createReducer } from '@reduxjs/toolkit';

import {
  createAutoSuggestionSuccess,
  deleteAutoSuggestionSuccess,
  fetchAutoSuggestionsSuccess,
  fetchAutoSuggestionSuccess,
  updateAutoSuggestionSuccess
} from 'src/actions/suggestionActions';

import type { Suggestion } from 'src/types/Suggestions';

type SuggestionState = Suggestion[];
const initialState: SuggestionState = [];

const suggestionsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAutoSuggestionsSuccess, (state, action) => {
      return action.payload.suggestions;
    })
    .addCase(fetchAutoSuggestionSuccess, (state, action) => {
      const index = state.findIndex((suggestion) => suggestion.id === action.payload.suggestion.id);
      if (index === -1) {
        state.push(action.payload.suggestion);
      } else {
        state[index] = action.payload.suggestion;
      }
    })
    .addCase(updateAutoSuggestionSuccess, (state, action) => {
      const index = state.findIndex((suggestion) => suggestion.id === action.payload.suggestion.id);
      state[index] = action.payload.suggestion;
    })
    .addCase(createAutoSuggestionSuccess, (state, action) => {
      state.push(action.payload.suggestion);
    })
    .addCase(deleteAutoSuggestionSuccess, (state, action) => {
      return state.filter((suggestion) => suggestion.id !== action.payload.id);
    });
});

export default suggestionsReducer;

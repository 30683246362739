import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { UpdateEntityDetail } from 'src/api/TicketsApi';
import type { EntitySearchResult } from 'src/types/EntityViewer';
import type { EntitySearchFields } from 'src/types/Info';
import type { TicketListTicket } from 'src/types/Ticket';

export interface EntityViewerState {
  entities: { [id: string]: EntitySearchResult };
  tickets: { [entityId: string]: TicketListTicket[] };
  selectedEntity?: EntitySearchResult;
  filledEntitySearchFields: EntitySearchFields;
  pickedSearchEntityTypes: string[];
  page: number;
}

const initialState: EntityViewerState = {
  entities: {},
  tickets: {},
  filledEntitySearchFields: {},
  pickedSearchEntityTypes: [],
  page: 1
};

const entityViewerSlice = createSlice({
  name: 'entityViewer',
  initialState,
  reducers: {
    searchEntitiesSuccess: (state, action: PayloadAction<EntitySearchResult[]>) => {
      state.entities = action.payload.reduce((entities, entity) => ({ ...entities, [entity._id]: entity }), {});
    },
    updateEntityField: (
      state,
      action: PayloadAction<Pick<UpdateEntityDetail, 'entityId' | 'fieldName' | 'valueToSave'>>
    ) => {
      const { entityId, fieldName, valueToSave } = action.payload;

      if (state.entities[entityId]) {
        state.entities[entityId][fieldName] = valueToSave;
      }
      if (state.selectedEntity && state.selectedEntity?._id === entityId) {
        state.selectedEntity[fieldName] = valueToSave;
      }
    },
    searchTicketsSuccess: (state, action: PayloadAction<{ tickets: TicketListTicket[]; entityId: string }>) => {
      state.tickets = { ...state.tickets, [action.payload.entityId]: action.payload.tickets };
    },
    setSelectedEntity: (state, action: PayloadAction<EntitySearchResult | undefined>) => {
      state.selectedEntity = action.payload;
    },
    setFilledEntitySearchFields: (
      state,
      action: PayloadAction<{
        fieldName: keyof EntitySearchFields;
        value: EntitySearchFields[keyof EntitySearchFields];
      }>
    ) => {
      const { fieldName, value } = action.payload;
      state.filledEntitySearchFields[fieldName] = value;
    },
    clearFilledEntitySearchFields: (state) => {
      state.filledEntitySearchFields = {};
    },
    setPickedSearchEntityTypes: (state, action: PayloadAction<string[]>) => {
      state.pickedSearchEntityTypes = action.payload;
    },
    setEntityViewerPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    }
  }
});

export const {
  searchEntitiesSuccess,
  updateEntityField,
  searchTicketsSuccess,
  setSelectedEntity,
  setFilledEntitySearchFields,
  clearFilledEntitySearchFields,
  setPickedSearchEntityTypes,
  setEntityViewerPage
} = entityViewerSlice.actions;

export default entityViewerSlice.reducer;

import _ from 'lodash';

import { FETCH_TAGS_SUCCESS, PATCH_TAGS_SUCCESS, CREATE_TAG_SUCCESS } from '../actions/index';
import { Tag } from '../types/Tag';

const initialState = {
  tags: []
};

const tagsReducer = (state = initialState.tags, action: any) => {
  switch (action.type) {
    case FETCH_TAGS_SUCCESS: {
      return _.cloneDeep(action.tags);
    }
    case PATCH_TAGS_SUCCESS: {
      const oldState: any = _.cloneDeep(state);
      const updatedTag: any = _.find(oldState, (tag: Tag) => {
        return tag.id === 'TAG' + action.tag.id;
      });
      const newTag = { ...action.tag, id: 'TAG' + action.tag.id };
      let indexToBeUpdated = oldState.findIndex((tag: Tag) => tag.id === updatedTag?.id);
      oldState.splice(indexToBeUpdated, 1, newTag);
      return oldState;
    }
    case CREATE_TAG_SUCCESS: {
      const oldState: any = _.cloneDeep(state);
      return [...oldState, ...[action.tag]];
    }
    default:
      return state;
  }
};

export default tagsReducer;

import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import { Accordion, Icon } from 'semantic-ui-react';
import MergeTickets from './MergeTickets/MergeTickets';
import PrintComments from './PrintComments';

const AdvancedActions = () => {
  const [open, setOpen] = useState(false);

  return (
    <Translation>
      {(t) => (
        <Accordion>
          <Accordion.Title active={open} onClick={() => setOpen(!open)}>
            <Icon name={open ? 'angle right' : 'angle up'} />
            {t('top_bar.advanced_actions.title')}
          </Accordion.Title>
          <Accordion.Content active={open}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <MergeTickets />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', paddingTop: '6px' }}>
                <PrintComments />
              </div>
            </div>
          </Accordion.Content>
        </Accordion>
      )}
    </Translation>
  );
};

export default AdvancedActions;

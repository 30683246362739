import * as React from 'react';
import { Modal } from 'semantic-ui-react';

import LoaderComponent from './Loader';

const ModalLoaderComponent = () => (
  <Modal isOpen={true} size="fullscreen">
    <Modal.Content>
      {LoaderComponent}
      Hello world!
    </Modal.Content>
  </Modal>
);

export default ModalLoaderComponent;

import { ThunkAction } from 'redux-thunk';
import { Action, ActionCreator, Dispatch } from 'redux';
import { startAjaxCall } from './ajaxStatusActions';
import { FETCH_LINKLISTS_SUCCESS, FETCH_LINKLISTS_FAILURE } from './index';
import { initialRequestSuccess, initialRequestFailure } from './initActions';
import LinkListApi from '../api/LinkListsApi';
import type { LinkList } from 'src/types/Widgets';

export const loadLinkListsSuccess = (linkLists: LinkList[]) => {
  return { type: FETCH_LINKLISTS_SUCCESS, payload: { linkLists } };
};
export const loadLinkListsFailure = (linkLists: LinkList[]) => {
  return { type: FETCH_LINKLISTS_FAILURE, payload: { linkLists } };
};

export const fetchLinkLists: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_LINKLISTS' }));
    return LinkListApi.getLinkLists()
      .then((lists) => {
        dispatch(loadLinkListsSuccess(lists));
        dispatch(initialRequestSuccess('linkLists'));
      })
      .catch((error) => {
        console.error('Failed to load link lists', error);
        dispatch(loadLinkListsFailure(error));
        dispatch(initialRequestFailure('linkLists', error));
      });
  };
};

import { ComponentProps, CSSProperties } from 'react';
import Modal from 'react-modal';

export const modal: ComponentProps<typeof Modal>['style'] = {
  overlay: { backgroundColor: 'rgba(0,0,0,.75)', zIndex: 1000 },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    border: 0,
    outline: 0,
    top: '50%',
    right: 'auto',
    bottom: 'auto',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '300px',
    minHeight: '250px'
  }
};

export const close: CSSProperties = { position: 'absolute', top: '20px', right: '20px', cursor: 'pointer', zIndex: 5 };

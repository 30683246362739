import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import type { ConnectedProps } from 'react-redux';

import HeaderEmailPreview from 'src/Components/CommentIconContent/HeaderEmailPreview';
import { parseContent } from 'src/Utilities/parseUtils';
import type { State } from 'src/types/initialState';

type PrintCommentsProps = ConnectedProps<typeof connector>;

const PrintComments: React.VFC<PrintCommentsProps> = ({ ticket, channels, ticketTypes }) => {
  if (!ticket?.comments?.length) {
    return null;
  }

  const { t } = useTranslation();
  const { current: iframe } = useRef(document.createElement('iframe'));

  const focusListener = () => {
    iframe.remove();
  };

  useEffect(() => {
    return window.removeEventListener('focus', focusListener);
  }, []);

  const commentsToHTML = () =>
    ticket?.comments
      .filter((c) => c.type === 'normal')
      .map((comment) =>
        renderToString(
          <>
            <HeaderEmailPreview
              channels={channels}
              ticketTypes={ticketTypes}
              channel={comment.channel!}
              title={comment.title}
              metaData={comment.metaData}
            />
            {typeof comment.content === 'string' && (
              <>
                <br />
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: parseContent(comment.content, comment.metaData?.attachments || ticket.attachments, true)
                  }}
                />
                <hr />
              </>
            )}
            <br />
          </>
        )
      )
      .join('') ?? '';

  const onClick = () => {
    const timestamp = new Date().getTime();
    iframe.id = `element-to-print-${timestamp}`;
    iframe.name = `element-to-print-${timestamp}`;
    iframe.title = `${ticket.id} comments`;
    iframe.style.display = 'none';
    iframe.style.top = '-1000px';
    iframe.style.left = '-1000px';
    iframe.srcdoc = `
      <html>
        <head><title>${ticket.id} comments</title></head>
        <body>
        ${commentsToHTML()}
        </body>
      </html>
    `;

    document.body.appendChild(iframe);

    window.frames[`element-to-print-${timestamp}`].document.close(); // >=IE11 fix
    window.addEventListener('focus', focusListener);
    window.frames[`element-to-print-${timestamp}`].focus();
    setTimeout(() => {
      // safari and firefox fix
      window.frames[`element-to-print-${timestamp}`].print();
    }, 500);
  };

  return <Button onClick={onClick}>{t('top_bar.advanced_actions.print_current_discussion')}</Button>;
};

const connector = connect((state: State) => ({
  channels: state.channels,
  ticketTypes: state.ticketTypes,
  ticket: state.detailedTickets.find((t) => t.id === state.activeTicketTab)
}));

export default connector(PrintComments);

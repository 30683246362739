import React from 'react';
import { t } from 'i18next';
import { isArray } from 'util';

import Spinner from './Spinner';
import SurveyList from './SurveyList';
import Notification from './Notification';
import Error from './Error';
import { getSurveyAnswers } from './Api/SurveyRequest';
import type { SurveyAnswer } from 'src/types/Survey';

interface SurveyContainerProps {
  taskId: string;
}

interface SurveyContainerState {
  status: 'loading' | 'success' | 'error' | 'notification';
  surveys: SurveyAnswer[];
  notification: string;
  currentTaskId: string; // Store the taskId of the fetched Survey. It changes when another ticket is selected
}

export default class SurveyContainer extends React.Component<SurveyContainerProps, SurveyContainerState> {
  constructor(props: SurveyContainerProps) {
    super(props);

    this.state = {
      status: 'loading',
      surveys: [],
      notification: '',
      currentTaskId: ''
    };
  }

  getSurveys = () => {
    const { taskId } = this.props;
    const taskNumber = taskId.match(/\d+/g)?.toString();
    if (taskNumber !== undefined) {
      this.setState({ currentTaskId: taskId, surveys: [], status: 'loading' });
      getSurveyAnswers(taskNumber)
        .then((data: any) => {
          if (isArray(data) && data[0] !== undefined) {
            this.setSurveys(data);
          } else {
            this.setNotificationState(t('CASE_SURVEY_NOT_FOUND'));
          }
        })
        .catch((error: any) => {
          console.error('Error while getting survey', error.data);
          if (error.status === 404) {
            this.setNotificationState('notification');
          } else {
            this.setErrorState(error);
          }
        });
    } else {
      this.setNotificationState(t('CASE_SURVEY_NOT_FOUND'));
    }
  };

  setSurveys = (data: any) => {
    this.setState({ status: 'success', surveys: data });
  };

  setErrorState = (error: any) => {
    this.setState({ status: 'error', notification: error.message });
  };

  setNotificationState = (notification: string) => {
    this.setState({ status: 'notification', notification: notification });
  };

  render() {
    const { status, notification, surveys, currentTaskId } = this.state;
    const { taskId } = this.props;

    if (currentTaskId !== taskId) {
      this.getSurveys();
    }

    switch (status) {
      case 'loading':
        return (
          <div>
            <Spinner taskId={taskId} className="loading" />
          </div>
        );
      case 'success':
        return (
          <div>
            <SurveyList taskId={taskId} surveys={surveys} />
          </div>
        );
      case 'notification':
        return (
          <div>
            <Notification type={['negative']} taskId={taskId} className="error" text={notification} />
          </div>
        );
      case 'error':
        return (
          <div>
            <Error taskId={taskId} className="error" text={notification} />
          </div>
        );
      default:
        return <div></div>;
    }
  }
}

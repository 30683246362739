import { AxiosResponse } from 'axios';

import { apiClient } from 'src/Utilities/httpClients';

export default class EezyApi {
  static sendReply = async (parameters: {
    replyToId: number;
    subject: string;
    content: string;
    selectedAttachmentURIs: string[];
    isSetReplyAsDone: boolean;
    ticketId: string;
  }): Promise<any> =>
    await apiClient
      .post(`/eezy/reply`, parameters)
      .then((response: AxiosResponse<any>) => response.data)
      .catch((error) => {
        throw error;
      });
}

import _ from 'lodash';
import type { Styles } from 'react-select';

import type { IItem } from 'src/Components/MultiDraggableDropdown/types';
import type { ResponseTemplate } from 'src/types/ResponseTemplate';

export const sortTemplatesNumerical = (array: ResponseTemplate[]): ResponseTemplate[] => {
  const collator = new Intl.Collator([], { numeric: true });
  return [...array].sort((a, b) => collator.compare(a.name, b.name));
};

export const replaceObjectParameters = (incomingString: string, payload?: Record<string, any> | null): string => {
  return incomingString.replace(/\{{(.*?)\}}/g, (string, p1) => {
    const matches = p1.split(', ');
    return matches.reduce(
      (reducedValue: string, matchValue: string) =>
        reducedValue.replace(`{{${matchValue}}}`, _.get(payload, matchValue) || ''),
      string
    );
  });
};

export const defaultCreatableSelectStyles: Partial<Styles<IItem, true>> = {
  multiValue: (styles: { [key: string]: any }, { data }) => ({
    ...styles,
    backgroundColor: data.color ? data.color : '#F0F0F0',
    color: data.color ? '#912d2b' : 'black'
  }),
  multiValueLabel: (styles: { [key: string]: any }, { data }) => ({
    ...styles,
    fontWeight: data.color ? 600 : 300,
    color: data.color ? '#912d2b' : 'black'
  }),
  placeholder: (provided) => ({
    ...provided,
    pointerEvents: 'none',
    userSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none'
  }),
  input: (css) => ({
    ...css,
    flex: '1 1 auto',
    '> div': {
      width: '100%'
    },
    input: {
      width: '100% !important',
      textAlign: 'left'
    }
  }),
  menu: (css) => ({ ...css, zIndex: 10 })
};

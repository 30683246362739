import { connect } from 'react-redux';

import type { vector2 } from 'react-with-gesture';
import type { Dispatch } from 'redux';

import { swipeLeft, swipeRight } from '../actions/swipeActions';
import { history } from '../reducers';
import Main from 'src/Components/Main/Main';

import type { State } from 'src/types/initialState';

const mapStateToProps = (state: State) => {
  return {
    leftTabOpen: state.mobileReducer.leftTabOpen,
    rightTabOpen: state.mobileReducer.rightTabOpen,
    mobileMode: state.mobileReducer.mobileMode,
    userPersonalData: state.userData
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onDown: () => {
      console.debug('User swiped down');
    },
    onUp: ([deltaX]: vector2) => {
      console.debug('User swiped up');
      if (deltaX > 80) {
        console.debug('User swiped right');
        dispatch(swipeRight());
        history.push('/');
      } else if (deltaX < -80) {
        console.debug('User swiped left');
        dispatch(swipeLeft());
      } else {
        console.debug("User's swipe was too short");
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);

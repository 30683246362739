import { createReducer } from '@reduxjs/toolkit';
import {
  fetchFilterPresetsSuccess,
  updateFilterPresetSuccess,
  deleteFilterPresetSuccess,
  createFilterPresetSuccess,
  resetFilterPresets,
  setActiveFilterPreset
} from 'src/actions/filterPresetsActions';
import type { FilterPreset } from 'src/Components/Management/FilterPresets/types';

export type FilterPresetsState = {
  filterPresets: FilterPreset[];
  loaded: boolean;
  activeId: number | null;
};

const initialState: FilterPresetsState = { filterPresets: [], loaded: false, activeId: null };

const filterPresetsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchFilterPresetsSuccess, (state, action) => {
      return { ...state, filterPresets: action.payload.presets, loaded: true };
    })
    .addCase(createFilterPresetSuccess, (state, action) => {
      state.filterPresets.push(action.payload.preset);
    })
    .addCase(updateFilterPresetSuccess, (state, action) => {
      const index = state.filterPresets.findIndex((p) => p.id === action.payload.preset.id);
      state.filterPresets[index] = action.payload.preset;
    })
    .addCase(deleteFilterPresetSuccess, (state, action) => {
      return { ...state, filterPresets: state.filterPresets.filter((p) => p.id !== action.payload.filterPresetId) };
    })
    .addCase(setActiveFilterPreset, (state, action) => {
       state.activeId = action.payload.filterPresetId;
    })
    .addCase(resetFilterPresets, (state, action) => {
      return { filterPresets: [], loaded: false, activeId: null };
    });
});

export default filterPresetsReducer;

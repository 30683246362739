import _ from 'lodash';
import React from 'react';
import { Translation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Checkbox, Dropdown, Icon, Input, Form, Modal, Label } from 'semantic-ui-react';

import { clearCommentFilter, setCommentFilter } from 'src/actions/commentFiltersActions';
import DateSelector, { timePrefix } from 'src/Components/Case/DateSelector';
import { ArrayDropdownFilter } from 'src/Components/Filter/DropdownFilter';
import type { State } from 'src/types/initialState';
import type { CommentFilters } from 'src/types/Ticket';

interface CommentFiltersModal extends CommentFiltersModalReduxProps {
  caseId: string;
  commentAuthors: string[];
  showedCommentsLength: number;
  totalCommentsAmount: number;

  setCommentFiltersModalOpen: (state: boolean) => void;
}

const CommentFiltersModal = ({
  caseId,
  commentFilters = {},
  commentAuthors,
  showedCommentsLength,
  totalCommentsAmount,
  clearCommentFilter,
  channels,
  setCommentFiltersModalOpen,
  setCommentFilter
}: CommentFiltersModal) => {
  const setFilterValue = (property: keyof CommentFilters, value: any) =>
    setCommentFilter({
      caseId,
      property,
      value
    });

  const throttledSetFilterValue = _.throttle((property: keyof CommentFilters, value: any) => {
    setFilterValue(property, value);
  }, 2000);

  return (
    <Translation ns="translations">
      {(tr) => (
        <Modal
          open
          size="small"
          centered={false}
          closeOnEscape
          closeOnDimmerClick
          closeOnDocumentClick
          dimmer="inverted"
          className="commentFiltersModal"
          onClose={() => setCommentFiltersModalOpen(false)}
        >
          <Modal.Header>
            <span>{tr('comments.titles.comment_filters')}</span>

            <Label color="teal" size="small" style={{ marginLeft: '5px' }}>
              {tr('comments.labels.showing_comments', {
                showedCommentsLength,
                totalCommentsAmount
              })}
            </Label>

            <Button
              icon
              negative
              labelPosition="left"
              style={{ marginLeft: 'auto' }}
              onClick={() => clearCommentFilter(caseId)}
            >
              <Icon name="delete" />
              {tr('filter.clear')}
            </Button>
          </Modal.Header>

          <Modal.Content>
            <Form.Field className="col2">
              <label>{tr('comments.labels.search_by_text')}</label>
              <Input
                fluid
                icon="search"
                placeholder={tr('comments.labels.search_through_content')}
                value={commentFilters.text || ''}
                onChange={(event, data) => throttledSetFilterValue('text', data.value)}
              />
            </Form.Field>

            <Form.Field className="col2">
              <label>{tr('GENERAL_DIRECTION')}</label>
              <Dropdown
                options={[
                  {
                    text: tr('DIRECTION_IN'),
                    value: 'in'
                  },
                  {
                    text: tr('DIRECTION_OUT'),
                    value: 'out'
                  }
                ]}
                fluid
                search
                selection
                scrolling
                clearable
                selectOnBlur={false}
                placeholder={tr('GENERAL_DIRECTION')}
                value={commentFilters.direction || ''}
                onChange={(event, data) => setFilterValue('direction', data.value)}
              />
            </Form.Field>

            <Form.Field className="col2">
              <label>{tr('SETTINGS_TITLE_ADMIN_MANAGE_CHANNELS')}</label>
              <ArrayDropdownFilter
                placeholder={tr('SETTINGS_TITLE_ADMIN_MANAGE_CHANNELS')}
                value={commentFilters.channels}
                onChange={(value) => setFilterValue('channels', value)}
                options={channels.map((channel) => ({ text: channel.channel, value: channel.id }))}
              />
            </Form.Field>

            <Form.Field className="col2">
              <label>{tr('GENERAL_AUTHOR')}</label>
              <ArrayDropdownFilter
                placeholder={tr('GENERAL_AUTHOR')}
                value={commentFilters.author}
                onChange={(value) => setFilterValue('author', value)}
                options={commentAuthors.map((author) => ({ text: author, value: author }))}
              />
            </Form.Field>

            <Form.Field>
              <label>{tr('comments.labels.date_creation_range')}</label>
              <div style={{ display: 'flex', gap: '10px' }}>
                <DateSelector
                  value={commentFilters.createdAfter}
                  onChange={(timestamp) => setFilterValue('createdAfter', timestamp?.replace(timePrefix, ''))}
                />

                <DateSelector
                  value={commentFilters.createdBefore}
                  onChange={(timestamp) => setFilterValue('createdBefore', timestamp?.replace(timePrefix, ''))}
                />
              </div>
            </Form.Field>

            <Form.Field className="col2">
              <label>{tr('comments.labels.has_attachments')}</label>
              <Checkbox
                toggle={true}
                checked={commentFilters.hasAttachment || false}
                onChange={(event, data) => setFilterValue('hasAttachment', data.checked)}
              />
            </Form.Field>
          </Modal.Content>
        </Modal>
      )}
    </Translation>
  );
};

const connector = connect(
  (state: State) => ({
    channels: state.channels,
    commentFilters: state.commentFilters[state.activeTicketTab!]
  }),
  {
    clearCommentFilter,
    setCommentFilter
  }
);

interface CommentFiltersModalReduxProps extends ConnectedProps<typeof connector> {}

export default connector(CommentFiltersModal);

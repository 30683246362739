import React from 'react';

import { CommentWidgetButtons } from './CommentWidgetButtons';
import { newLinesToBr } from 'src/Utilities/parseUtils';
import { filterDuplicateRecipients } from 'src/Components/Utilities/recipients';
import { defineToParameter } from 'src/Components/Utilities/recipients';
import {
  getReplyContent,
  getReplyAllContent,
  getForwardContent,
  getReplyContentSimple,
  getForwardContentSimple
} from 'src/Utilities/replyContent';
import type { CommentEditorProps } from './types';
interface CommentEditorWidgetProps extends CommentEditorProps {
  isHTML: boolean;
  attachmentIds: string[];
  buttonsType?: 'basic' | 'primary';
}

const CommentEditorWidget = (props: CommentEditorWidgetProps) => {
  const metaDataIsValidJson = props.metaData != null;
  const hasToAddresses = props.metaData?.to !== undefined && Array.isArray(props.metaData?.to);
  // for replyAll : extra condition added because missing
  const hasFromAddresses =
    props.metaData?.from !== undefined && (Array.isArray(props.metaData?.from) || props.metaData?.from);

  const replyToEmail = (data: object) => {
    props.replyToEmail(data, props.taskId);
  };

  const handleReply = () => {
    replyToEmail({
      content: !props.isHTML ? newLinesToBr(getReplyContent(props)) : getReplyContent(props),
      subject: 'RE: ' + (props.title !== '' ? props.title : props.metaData.title),
      to: defineToParameter(props.metaData)
    });
  };

  const handleReplyForward = () => {
    replyToEmail({
      content: !props.isHTML ? newLinesToBr(getForwardContent(props)) : getForwardContent(props),
      subject: 'FW: ' + (props.title !== '' ? props.title : props.metaData.title),
      to: '',
      selectedAttachmentIds: props.attachmentIds
    });
  };

  const handleReplySimple = () => {
    replyToEmail({
      content: !props.isHTML ? newLinesToBr(getReplyContentSimple(props)) : getReplyContentSimple(props)
    });
  };

  const handleForwardSimple = () => {
    replyToEmail({
      content: !props.isHTML ? newLinesToBr(getForwardContentSimple(props)) : getForwardContentSimple(props),
      to: ''
    });
  };

  if (metaDataIsValidJson && hasToAddresses && hasFromAddresses) {
    const filteredRecipients = filterDuplicateRecipients(props.metaData.to, props.metaData.from, props.senderEmails);

    const handleReplyAll = () => {
      replyToEmail({
        content: !props.isHTML ? newLinesToBr(getReplyAllContent(props)) : getReplyAllContent(props),
        subject: 'RE: ' + (props.title || props.metaData.title),
        to: filteredRecipients,
        cc: props.metaData.cc ? props.metaData.cc.join(', ') : undefined
      });
    };

    return (
      <CommentWidgetButtons
        onReplyClick={handleReply}
        onReplyAllClick={handleReplyAll}
        onReplyForwardClick={handleReplyForward}
        buttonsType={props.buttonsType}
      />
    );
  } else {
    return <CommentWidgetButtons onReplyClick={handleReplySimple} onReplyForwardClick={handleForwardSimple} />;
  }
};

export default CommentEditorWidget;

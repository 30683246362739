import type { CSSProperties, VFC } from 'react';
import React, { useMemo } from 'react';
import type { SemanticICONS } from 'semantic-ui-react';
import { Divider, Icon, Label } from 'semantic-ui-react';

import type { Field } from 'src/types/Info';
import type { Entity } from 'src/types/Ticket';

interface EntityDetailsProps {
  fields: Field[];
  values: Entity;
}

interface Detail {
  value?: string;
  icon?: string;
  style?: CSSProperties;
}

const EntityDetails: VFC<EntityDetailsProps> = ({ fields, values }) => {
  const emailField = fields.find((field) => field.displayField === 'emailField');
  const phoneField = fields.find((field) => field.displayField === 'phoneField');
  const addressField = fields.find((field) => field.displayField === 'addressField');
  const companyField = fields.find((field) => field.displayField === 'companyField');
  const registryField = fields.find((field) => field.displayField === 'registryField');
  const idField = fields.find((field) => field.displayField === 'idField');

  const unwrapField = (field?: Field): string | undefined => {
    if (field?.object === undefined) {
      return values[field?.value ?? ''];
    }

    return values?.[field?.object]?.[field?.value];
  };

  const details = useMemo<Detail[]>(
    () => [
      ...[
        { value: unwrapField(emailField), icon: 'mail', style: { paddingRight: 10 } },
        { value: unwrapField(phoneField), icon: 'call' },
        { value: unwrapField(addressField), icon: 'address book' },
        { value: unwrapField(companyField), icon: 'mail' },
        { value: unwrapField(registryField), icon: 'hashtag' },
        { value: unwrapField(idField), icon: 'address book' }
      ].filter(({ value }) => value),

      ...fields
        .filter((field) => field.displayField === 'extra' && values[field.value] !== undefined)
        .map(({ value }) => ({ value: values[value] }))
    ],
    []
  );

  return (
    <>
      <span style={{ display: 'flex', flexDirection: 'column' }} data-test="entityDetails">
        {details.map(({ style, value, icon }, index) => (
          <span style={style} key={`entity-details-${value}-${index}`}>
            <Icon style={{ marginRight: '5px' }} name={(icon ?? 'info circle') as SemanticICONS} />
            {value}
          </span>
        ))}
      </span>

      {values['__tags']?.length > 0 && (
        <>
          <Divider />
          {values['__tags'].map((tag: string, index: number) => (
            <Label basic color="grey" key={`entity-details-tags-${tag}-${index}`}>
              <Icon name="info circle" /> {tag}
            </Label>
          ))}
        </>
      )}
    </>
  );
};

export default React.memo(EntityDetails);

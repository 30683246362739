import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

import EnvSettings from 'src/api/EnvSettings';
import { automaticClosingTicket } from 'src/actions/ticketsActions';

const MILLISECONDS = 1000;
const INTERVAL_TIMEOUT = 60 * MILLISECONDS;

const AutomaticClosingTickets = () => {
  const { delay, channel, status } = EnvSettings.getSettings().AUTOMATIC_CLOSING_TICKETS.data;
  const dispatch = useDispatch();

  useEffect(() => {
    const intervalId = setInterval(() => dispatch(automaticClosingTicket(delay, channel, status)), INTERVAL_TIMEOUT);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  return null;
};

export default AutomaticClosingTickets;

import { t as tr } from 'i18next';
import _ from 'lodash';
import React from 'react';
import { Translation } from 'react-i18next';
import { Button, Divider, Dropdown, Form, Icon, Label, Modal, Table } from 'semantic-ui-react';
import type { DropdownItemProps } from 'semantic-ui-react';

import { MAX_ATTACHMENT_SIZE } from 'src/config';
import FeatureFlags from 'src/api/FeatureFlags';
import ReplyEditor from '../Case/ReplyEditor';
import { KnowledgeBank } from './KnowledgeBank';
import ReplyControlButtons from './components/ReplyControlButtons';
import ReplyTemplates from './ReplyTemplates';
import { ReplyMethod } from './ReplyMethod';
import ChannelType from '../CommentIconContent/ChannelType';
import { onDropRejected } from './UploadErrorHandler';
import ReplyVCDrafts from './components/ReplyVCDrafts';
import { Channels } from 'src/types/Channel';
import type { ReplyMethodProps } from './ReplyMethod';
import type { PersonalData } from 'src/types/User';
import type { TicketType } from 'src/types/TicketType';
import type { ResponseTemplate } from 'src/types/ResponseTemplate';
import type { Attachment, Entity, Ticket } from 'src/types/Ticket';
import { DropzoneRefContext } from '../Attachments/AttachmentsContext';

interface ReplyCustomerPortalProps extends ReplyMethodProps<ReplyCustomerPortalState> {
  userData: PersonalData;
  attachments: Attachment[];
  activeTicketType: TicketType;
  templates: Array<ResponseTemplate>;
  drafts?: Partial<ReplyCustomerPortalState>;
  task: Ticket;
  entities: Entity[];
  subject?: string;

  addTag: (tagId: string) => void;
  onSubmit: (...args: any[]) => any;
}

interface ReplyCustomerPortalState {
  content: string;
  subject: string;
  pickedEntities: string[];
  selectedAttachments: Attachment[];
  isCaseDoneAfterSubmit: boolean;
  isSubmitModalOpen: boolean;
  isLoading: boolean;
  selectedReplyTemplate: string | undefined;
}

class ReplyCustomerPortal extends ReplyMethod<ReplyCustomerPortalProps, ReplyCustomerPortalState> {
  private channel = ChannelType.CustomerPortal;
  private options: DropdownItemProps[];
  private entityOptions: any[];

  static contextType = DropzoneRefContext;
  context: React.ContextType<typeof DropzoneRefContext>;

  constructor(props: ReplyCustomerPortalProps) {
    super(props);

    this.state = this.getInitialState(this.props);
    this.options = props.attachments.map((attachment: Attachment) => {
      return {
        text: attachment.fileName,
        value: attachment.id
      };
    });
    this.options.push({ text: tr('ATTACHMENT_ADD_DROPDOWN'), value: 'ADD_ATTACHMENT' });

    this.entityOptions = this.generateEntityOptions(this.props);
  }

  getDraftChannel(): Channels {
    return Channels.customerPortal;
  }

  getDraftState(state: ReplyCustomerPortalState): Partial<ReplyCustomerPortalState> {
    return {
      content: state.content,
      isCaseDoneAfterSubmit: state.isCaseDoneAfterSubmit,
      subject: state.subject,
      pickedEntities: state.pickedEntities,
      selectedAttachments: state.selectedAttachments,
      isLoading: state.isLoading,
      isSubmitModalOpen: state.isSubmitModalOpen,
      selectedReplyTemplate: state.selectedReplyTemplate
    };
  }

  private generateEntityOptions = (props: ReplyCustomerPortalProps) => {
    return props.entities.map((entity) => {
      const customerInfoFieldset = this.props.activeTicketType.fieldSets.find((fieldset) => {
        return fieldset.id === 'customerInfo';
      });

      let customerName1Field = undefined;
      let customerName2Field = undefined;
      if (customerInfoFieldset !== undefined) {
        customerName1Field = customerInfoFieldset['customerInfo']?.find((field) => field.displayField === 'name1');
        customerName2Field = customerInfoFieldset['customerInfo']?.find((field) => field.displayField === 'name2');
        let entityText = '';
        let customerName1 = '';
        let customerName2 = '';
        if (entity.data) {
          customerName1 = customerName1Field && entity.data[customerName1Field.value as string];
          customerName2 = customerName2Field && entity.data[customerName2Field.value as string];
        }
        entityText = `${customerName1 || ''} ${customerName2 || ''}`;

        if (entityText !== '') {
          return {
            value: entity._id,
            text: entityText // replace this later
          };
        }
      }

      return {
        value: entity._id,
        text: entity._id // replace this later
      };
    });
  };

  private getInitialState = (props: ReplyCustomerPortalProps) => {
    const state: ReplyCustomerPortalState = {
      content: props.drafts?.content || '',
      isCaseDoneAfterSubmit: props.drafts?.isCaseDoneAfterSubmit || false,
      subject: props.drafts?.subject || 'Re: ' + props.subject || 'Re: ',
      pickedEntities: props.drafts?.pickedEntities || [],
      selectedAttachments: props.drafts?.selectedAttachments || [],
      isLoading: props.drafts?.isLoading || false,
      isSubmitModalOpen: props.drafts?.isSubmitModalOpen || false,
      selectedReplyTemplate: props.drafts?.selectedReplyTemplate || undefined
    };

    return state;
  };

  componentWillReceiveProps(nextProps: ReplyCustomerPortalProps) {
    if (!_.isEqual(nextProps.drafts, this.props.drafts) || this.props.taskId !== nextProps.taskId) {
      this.setState(this.getInitialState(nextProps));
    }

    this.options = nextProps.attachments.map((attachment) => {
      const option = {
        text: attachment.fileName,
        value: attachment.id
      };
      return option;
    });
    this.options.push({ text: tr('ATTACHMENT_ADD_DROPDOWN'), value: 'ADD_ATTACHMENT' });

    this.entityOptions = this.generateEntityOptions(nextProps);
  }

  private clearFields = () => {
    this.updateState({
      content: '',
      subject: 'Re: ' + this.props.subject,
      pickedEntities: [],
      selectedAttachments: [],
      isLoading: false,
      isSubmitModalOpen: false,
      selectedReplyTemplate: undefined
    });
  };

  private submitCustomerPortalMessage = async () => {
    this.setState({ isLoading: true }, async () => {
      const bodyOfRequest = {
        content: this.state.content,
        subject: this.state.subject,
        channel: this.channel,
        direction: 'out',
        metaData: {
          title: this.state.subject,
          entityIds: this.state.pickedEntities,
          entityIdsAndTypes: this.state.pickedEntities.map((entityId) => {
            return (
              entityId +
              ':' +
              this.props.entities.find((entity) => {
                return entity._id === entityId;
              })?._type
            );
          }),
          attachments: this.state.selectedAttachments
        },
        attachments: this.state.selectedAttachments
      };

      try {
        const response = await this.props.onSubmit(bodyOfRequest, this.state.isCaseDoneAfterSubmit);
        if (!this.props.task.tags.includes('TAG500')) {
          this.props.addTag('TAG500');
        }
        if (response !== false) {
          this.clearFields();
        }
      } catch (error) {
        console.error('Failed to save ticket', error);
        this.updateState({
          isLoading: false,
          isSubmitModalOpen: false
        });
      }
    });
  };

  private getSubmitModal = () => {
    return (
      <Modal
        open={this.state.isSubmitModalOpen}
        closeOnDocumentClick={true}
        onClick={() => this.updateState({ isSubmitModalOpen: false })}
      >
        <Modal.Header>{tr('ADD_COMMENT_SEND_AS_CUSTOMER_PORTAL_MESSAGE')}</Modal.Header>
        <Modal.Description style={{ padding: '20px' }}>
          <Table celled padded>
            <Table.Body>
              {this.state.selectedAttachments.length > 0 && (
                <Table.Row>
                  <Table.Cell>{tr('ADD_COMMENT_ATTACHMENT_LABEL')}</Table.Cell>
                  <Table.Cell>
                    {this.state.selectedAttachments.map((x) => (
                      <Label>{x.fileName}</Label>
                    ))}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <Divider horizontal></Divider>
        </Modal.Description>
        <Modal.Actions>
          <Button
            disabled={this.state.isLoading}
            negative
            icon
            labelPosition="left"
            onClick={() => this.updateState({ isSubmitModalOpen: false })}
          >
            <Icon name="remove" />
            {tr('GENERAL_CANCEL')}
          </Button>
          <Button
            primary
            disabled={this.state.isLoading}
            loading={this.state.isLoading}
            icon
            onClick={this.submitCustomerPortalMessage}
            labelPosition="left"
          >
            <Icon name="send" />
            {tr('GENERAL_SEND')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  public submitComment = () => {
    if (!this.state.content.length || !this.state.pickedEntities.length) {
      return;
    }

    this.updateState({ isSubmitModalOpen: true });
  };

  render() {
    const attachmentSelectedValues = this.state.selectedAttachments.map((selectedAttachment) => {
      return selectedAttachment.id;
    });

    return (
      <Translation ns="translations">
        {(t) => (
          <React.Fragment>
            {this.getSubmitModal()}
            <Form reply={true} style={{ marginTop: '20px' }} loading={this.state.isLoading}>
              <Form.Group>
                <Form.Field width={10}>
                  <label>{t('ADD_COMMENT_TITLE')}</label>
                  <Form.Input
                    onChange={(e, d) => this.updateState({ subject: d.value })}
                    type="text"
                    fluid={true}
                    value={this.state.subject}
                  >
                    <input />
                  </Form.Input>
                </Form.Field>
                <Form.Field width={6}>
                  <label>{t('ADD_COMMENT_CANNED_RESPONSE')}</label>
                  <ReplyTemplates
                    userData={this.props.userData}
                    ticketType={this.props.activeTicketType}
                    templates={this.props.templates}
                    task={this.props.task}
                    channel={this.channel}
                    selectedOption={this.state.selectedReplyTemplate}
                    setSelectedOption={(value) => this.setState({ selectedReplyTemplate: value })}
                    setContent={(value) => {
                      this.updateState({ ...value });
                    }}
                    content={this.state.content}
                    entities={this.props.entities}
                  />
                </Form.Field>
              </Form.Group>
              {FeatureFlags.isFlagOn('ENABLE_EGAIN') && (
                <Form.Field>
                  <KnowledgeBank
                    title={this.props.subject}
                    id={this.props.taskId}
                    extraArguments={this.props.activeTicketType.knowledgeBank}
                    content={
                      this.state.subject && this.state.subject?.length > 900
                        ? this.state.subject.slice(900, -1) + ' \n\n ' + this.state.content
                        : this.state.content
                    }
                    updateState={(value: string) => {
                      this.updateState({ content: value });
                    }}
                  />
                </Form.Field>
              )}
              <ReplyVCDrafts taskId={this.props.taskId} channelId={this.channel} onClickLoad={this.loadVCDraft} />
              <Form.Field id="commentContentField">
                <label>{t('ADD_COMMENT_CONTENT')}</label>
                <ReplyEditor
                  style={{ borderRadius: '5px', marginTop: '10px', marginBottom: '10px', wordBreak: 'break-word' }}
                  value={this.state.content}
                  onChange={(value: string) => this.updateState({ content: value })}
                  ticket={this.props.task}
                  onKeyDown={this.handleHotKeys}
                />
              </Form.Field>

              <Form.Input label={t('CUSTOMER_PORTAL_ADD_COMMENT_RECIPIENTS_LABEL')}>
                <Dropdown
                  selection={true}
                  selectOnBlur={false}
                  fluid={true}
                  search={true}
                  multiple={true}
                  value={this.state.pickedEntities}
                  onChange={(e, d) => {
                    const value = d.value as string[];
                    this.updateState({ pickedEntities: value });
                  }}
                  noResultsMessage={t('GENERAL_SEARCH_NO_RESULTS')}
                  placeholder={t('CUSTOMER_PORTAL_ADD_COMMENT_RECIPIENT_PLACEHOLDER')}
                  options={this.entityOptions}
                />
              </Form.Input>

              <Form.Group widths="equal">
                <Form.Input label={t('ADD_COMMENT_ATTACHMENT_LABEL')}>
                  <Dropdown
                    onDropRejected={onDropRejected}
                    maxSize={MAX_ATTACHMENT_SIZE}
                    selection={true}
                    selectOnBlur={false}
                    fluid={true}
                    search={true}
                    multiple={true}
                    value={attachmentSelectedValues}
                    onChange={(e, d) => {
                      const value = d.value as string[];
                      if (value.toString().includes('ADD_ATTACHMENT')) {
                        this.context.dropzoneRef?.current?.open();
                        value.pop();
                      } else {
                        let addedAttachments = this.props.attachments.filter((attachment: Attachment) => {
                          return value.indexOf(attachment.id) !== -1;
                        });
                        addedAttachments = _.map(addedAttachments, (att) => {
                          return { ...att, value: att.id, text: att.fileName };
                        });
                        this.updateState({ selectedAttachments: addedAttachments });
                      }
                    }}
                    noResultsMessage={t('GENERAL_SEARCH_NO_RESULTS')}
                    placeholder={t('ADD_COMMENT_ATTACHMENT_PLACEHOLDER')}
                    options={this.options}
                  />
                </Form.Input>
              </Form.Group>

              <ReplyControlButtons
                small={this.props.smallButtons}
                disabled={!this.state.content.length || !this.state.pickedEntities.length}
                onClear={this.clearFields}
                onSaveDraft={() => this.saveVCDraft(false)}
                onSubmit={this.submitComment}
              />
            </Form>
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

export default ReplyCustomerPortal;

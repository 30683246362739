import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Divider, Tab } from 'semantic-ui-react';

import EntityDeletionModal from './EntityDeletionModal';
import EntityTickets from './EntityTickets';
import Info from 'src/Components/Case/Info/Info';
import { getMutableEntityTypes, isEntityTypeReadonly } from 'src/Utilities/ticketTypes';

import type { UpdateEntityDetail } from 'src/api/TicketsApi';
import type { Field, OnInfoSave } from 'src/types/Info';
import type { State } from 'src/types/initialState';
import type { Entity, TicketListTicket } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';

interface EntityDetailsProps {
  fields: Field[];
  values: Entity;
  entityTickets: TicketListTicket[];
  onSave(updateArgs: UpdateEntityDetail): void;
  deleteEntity(entity?: Entity): Promise<void>;
}

function EntityDetails({ fields, values, entityTickets, onSave, deleteEntity }: EntityDetailsProps) {
  const { t } = useTranslation();
  const ticketTypes = useSelector<State, TicketType[]>((state) => state.ticketTypes);

  const onSaveData = useCallback<OnInfoSave>(
    (...args) => {
      const [fieldName, valueToSave, object, partial, , , , entityType, entityId] = args;

      if (typeof entityId !== 'undefined' && typeof entityType !== 'undefined') {
        onSave({
          entityId,
          entityType,
          fieldName,
          valueToSave,
          object,
          partial
        });
      }
    },
    [onSave]
  );

  const onEntityDelete = useCallback(() => deleteEntity(values), [values, deleteEntity]);
  const mutableEntityTypes = useMemo(
    () => new Set(getMutableEntityTypes(ticketTypes).map(({ entityType }) => entityType)),
    [ticketTypes]
  );

  const panes = [
    {
      menuItem: t('entityViewer.entity_info'),
      render: () => (
        <Tab.Pane>
          <div style={{ height: '70vh', marginBottom: '10px' }}>
            <Info
              fields={fields}
              values={values}
              onSave={onSaveData}
              generalDisable={!mutableEntityTypes.has(values._type)}
            />
          </div>
        </Tab.Pane>
      )
    },
    {
      menuItem: `${t('entityViewer.entity_tickets')} (${entityTickets.length})`,
      render: () => (
        <Tab.Pane>
          <div style={{ height: '75vh', marginBottom: '10px' }}>
            <EntityTickets entityTickets={entityTickets} />
          </div>
        </Tab.Pane>
      )
    }
  ];

  const readonly = isEntityTypeReadonly(ticketTypes, values._type);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2>{t('entityViewer.selected_entity')}</h2>
        <div>{!readonly && <EntityDeletionModal deleteEntity={onEntityDelete} />}</div>
      </div>
      <Divider />
      <div style={{ width: '100%' }}>
        <Tab panes={panes} />
      </div>
    </>
  );
}

export default React.memo(EntityDetails);

import { t as tr } from 'i18next';
import iziToast from 'izitoast';
import get from 'lodash/get';
import React from 'react';
import { Translation } from 'react-i18next';
import { Card, Icon } from 'semantic-ui-react';

import type { TFunction } from 'i18next';

import type { Field } from '../../types/Info';
import type { Entity } from '../../types/Ticket';

interface CustomerResultProps {
  onClick: any;
  fields: any[];
  values: Entity;
  mongodb: boolean;
  isAttached?: boolean;
}

class CustomerResult extends React.Component<CustomerResultProps> {
  render() {
    return (
      <Translation ns="translations">
        {(t: TFunction) => (
          <Card
            onMouseDown={this.props.isAttached ? this.onClickAlreadyAttachedEntity : this.props.onClick}
            fluid={true}
            link={this.props.isAttached ? false : true}
            color={this.props.isAttached ? 'green' : undefined}
            style={
              this.props.isAttached
                ? {
                    boxShadow: '0 0 0 1px #d4d4d5, 0 5px 0 0 #21ba45, 0 1px 3px 0 #d4d4d5',
                    cursor: 'not-allowed'
                  }
                : undefined
            }
          >
            <Card.Content>
              <Card.Header>
                <div className="customerCard">
                  <div>{this.getName()}</div>
                  <div className={this.props.mongodb ? 'eeedoCustomer' : 'integrationCustomer'} />
                </div>
              </Card.Header>
              <Card.Meta>{this.getDetails()}</Card.Meta>
            </Card.Content>
            {this.props.isAttached && (
              <Card.Content extra={true}>
                <Icon name="check" color="green" /> {t('TICKET_CUSTOMER_ALREADY_ATTACHED_CARD')}
              </Card.Content>
            )}
          </Card>
        )}
      </Translation>
    );
  }

  onClickAlreadyAttachedEntity = () => {
    iziToast.info({
      icon: 'info',
      message: tr('TICKET_CUSTOMER_ALREADY_ATTACHED_TOAST'),
      timeout: 5000
    });
  };

  getFieldValue = (values: Entity, field?: Field, defaultValue?: string) => {
    return field ? get(values, [field.object, field.value].filter(Boolean).join('.'), '') : defaultValue;
  };

  getName = () => {
    const firstNameField = this.props.fields.find((x) => x.displayField === 'name1');
    const lastNameField = this.props.fields.find((x) => x.displayField === 'name2');
    const additionalNameField = this.props.fields.find((x) => x.displayField === 'name3');

    const firstName = get(this.props.values, firstNameField?.value, '');
    const lastName = get(this.props.values, lastNameField?.value, '');
    const additionalName = get(this.props.values, additionalNameField?.value, '');

    return firstName + ' ' + lastName + ' ' + additionalName;
  };

  getDetails = () => {
    const emailField = this.props.fields.find((x) => x.displayField === 'emailField');
    const phoneField = this.props.fields.find((x) => x.displayField === 'phoneField');
    const addressField = this.props.fields.find((x) => x.displayField === 'addressField');
    const companyField = this.props.fields.find((x) => x.displayField === 'companyField');
    const registryField = this.props.fields.find((x) => x.displayField === 'registryField');
    const idField = this.props.fields.find((x) => x.displayField === 'idField');

    const email = this.getFieldValue(this.props.values, emailField);
    const phoneNumber = this.getFieldValue(this.props.values, phoneField);
    const address = this.getFieldValue(this.props.values, addressField);
    const company = this.getFieldValue(this.props.values, companyField);
    const registry = this.getFieldValue(this.props.values, registryField);
    const id = this.getFieldValue(this.props.values, idField);

    return (
      <span style={{ display: 'flex', flexDirection: 'column' }}>
        {email && typeof email !== 'object' && (
          <span style={{ paddingRight: 10 }}>
            <Icon name="mail" />
            {email}
          </span>
        )}
        {phoneNumber && typeof phoneNumber !== 'object' && (
          <span>
            <Icon name="call" />
            {phoneNumber}
          </span>
        )}
        {address && typeof address !== 'object' && (
          <span>
            <Icon name="address book" />
            {address}
          </span>
        )}
        {company && typeof company !== 'object' && (
          <span>
            <Icon name="mail" />
            {company}
          </span>
        )}
        {registry && typeof registry !== 'object' && (
          <span>
            <Icon name="hashtag" />
            {registry}
          </span>
        )}
        {id && typeof id !== 'object' && (
          <span>
            <Icon name="address book" />
            {id}
          </span>
        )}
      </span>
    );
  };
}

export default CustomerResult;

import { TFunction } from 'i18next';
import moment from 'moment';
import React from 'react';
import { Translation } from 'react-i18next';
import { Button, Icon, List, Popup, SemanticCOLORS, SemanticICONS, Table } from 'semantic-ui-react';
import UserLabel from 'src/Components/User/UserLabel';

import type { EIdentification } from 'src/types/EIdentification';
import { DATE_TIME_FORMAT, DATE_TIME_SECONDS_FORMAT } from 'src/Utilities/dates';

interface SingleEIdentificationProps {
  contentId: number;
  eIdentification: EIdentification;
  eIdentificationUpdateInProgress: boolean;
  getLinkByHash(hash: string): void;
  updateEIdentification: (contentId: number, hash: string, type: 'accept' | 'reject') => Promise<void>;
}

type EIDStates = 'createdAt' | 'verifiedAt' | 'acceptedAt' | 'rejectedAt';
type EIDStyle = { iconName: SemanticICONS; iconColor?: SemanticCOLORS };

const data: Map<EIDStates, EIDStyle> = new Map();

data.set('acceptedAt', {
  iconName: 'check circle',
  iconColor: 'green'
});
data.set('rejectedAt', {
  iconName: 'delete',
  iconColor: 'red'
});

data.set('verifiedAt', {
  iconName: 'info circle',
  iconColor: 'blue'
});

data.set('createdAt', {
  iconName: 'clock',
  iconColor: 'grey'
});

const getData = (state: EIDStates): EIDStyle => {
  return data.get(state) as EIDStyle;
};

const getState = (eIdentification: EIdentification): EIDStates => {
  if (eIdentification.acceptedAt) {
    return 'acceptedAt';
  } else if (eIdentification.rejectedAt) {
    return 'rejectedAt';
  } else if (eIdentification.verifiedAt) {
    return 'verifiedAt';
  } else {
    return 'createdAt';
  }
};

const getActionPerformer = (state: EIDStates, eIdentification: EIdentification, showName: boolean) => {
  let lastActionDoneBy;

  if (state === 'createdAt') {
    lastActionDoneBy = eIdentification.createdBy;
  } else if (state === 'verifiedAt') {
    return null;
  } else if (state === 'acceptedAt' && eIdentification.acceptedBy) {
    lastActionDoneBy = eIdentification.acceptedBy;
  } else if (state === 'rejectedAt' && eIdentification.rejectedBy) {
    lastActionDoneBy = eIdentification.rejectedBy;
  } else {
    return null;
  }

  return <UserLabel UID={lastActionDoneBy} showTitle={showName} />;
};

const getTimePopup = (eIdentification: EIdentification) => {
  return (
    <Translation ns="translations">
      {(translate) => (
        <List divided relaxed>
          {(['createdAt', 'verifiedAt', 'acceptedAt', 'rejectedAt'] as EIDStates[]).map((key: EIDStates) => {
            if (eIdentification[key] === null) {
              return null;
            } else {
              return (
                <List.Item>
                  <List.Icon
                    name={(data.get(key as EIDStates) as EIDStyle).iconName}
                    color={(data.get(key as EIDStates) as EIDStyle).iconColor}
                  ></List.Icon>
                  <List.Content>
                    {translate('widgets.eIdentification.labels.' + key, {
                      fromNow: moment.unix(eIdentification[key] as number).format(DATE_TIME_SECONDS_FORMAT)
                    })}
                    {getActionPerformer(key, eIdentification, true)}
                  </List.Content>
                </List.Item>
              );
            }
          })}
        </List>
      )}
    </Translation>
  );
};

export default function SingleEIdentification({
  contentId,
  eIdentification,
  eIdentificationUpdateInProgress,
  getLinkByHash,
  updateEIdentification
}: SingleEIdentificationProps) {
  const state = getState(eIdentification);

  const renderEIdentificationResponse = (translate: TFunction) => {
    if (Object.keys(eIdentification.response).length) {
      return (
        <div>
          <h4 style={{ margin: '7px 0 5px 0' }}>{translate('widgets.eIdentification.labels.customer_data_label')}</h4>
          <Table striped>
            {Object.keys(eIdentification.response).map((name) => (
              <Table.Row>
                <Table.Cell>
                  <strong>{name}</strong>
                </Table.Cell>
                <Table.Cell>{eIdentification.response![name]}</Table.Cell>
              </Table.Row>
            ))}
            {getState(eIdentification) === 'verifiedAt' && (
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="3">
                    <Button
                      labelPosition="left"
                      icon
                      positive
                      style={{ marginTop: '10px' }}
                      onClick={() => updateEIdentification(contentId, eIdentification.hash, 'accept')}
                      disabled={eIdentificationUpdateInProgress}
                    >
                      <Icon name="check" />
                      {translate('GENERAL_ACCEPT_LABEL')}
                    </Button>
                    <Button
                      labelPosition="left"
                      icon
                      negative
                      style={{ marginTop: '10px', marginLeft: 'auto' }}
                      onClick={() => updateEIdentification(contentId, eIdentification.hash, 'reject')}
                      disabled={eIdentificationUpdateInProgress}
                    >
                      <Icon name="remove" />
                      {translate('GENERAL_REJECT_LABEL')}
                    </Button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            )}
          </Table>
        </div>
      );
    }
    return null;
  };

  const time = moment.unix(eIdentification[getState(eIdentification)]!).format(DATE_TIME_FORMAT);

  return (
    <Translation ns="translations">
      {(translate) => (
        <>
          <List.Item>
            <List.Icon
              name={getData(state).iconName}
              color={getData(state).iconColor}
              size="large"
              verticalAlign="middle"
            />
            <List.Content>
              <div style={{ flex: 'column', display: 'flex' }}>
                <div>
                  <List.Header
                    style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
                    as="a"
                    onClick={() => getLinkByHash(eIdentification.hash)}
                  >
                    <Popup
                      wide
                      position="left center"
                      trigger={
                        <span>
                          {eIdentification.hash} <Icon name="copy" />
                        </span>
                      }
                      content={translate('widgets.eIdentification.notifications.click_to_copy_link')}
                    />
                  </List.Header>
                  <List.Description as="a">
                    <Popup
                      wide
                      position="left center"
                      hoverable
                      trigger={
                        <span>
                          <Icon name="question circle" style={{ marginLeft: '5px' }} />
                          {translate('widgets.eIdentification.labels.' + getState(eIdentification), {
                            fromNow: time
                          })}
                        </span>
                      }
                      content={getTimePopup(eIdentification)}
                    />
                  </List.Description>
                </div>
                <div style={{ marginLeft: '10px' }}>{getActionPerformer(state, eIdentification, false)}</div>
              </div>
            </List.Content>
            {renderEIdentificationResponse(translate)}
            {Object.keys(eIdentification.response).length > 0 && (
              <h4 style={{ marginBottom: '10px', marginLeft: '10px' }}>
                {translate('widgets.eIdentification.labels.verificationCode')}:
                <span
                  style={{
                    padding: '5px',
                    fontFamily: 'monaco, monospace',
                    color: 'darkred',
                    fontSize: '14pt',
                    textAlign: 'center',
                    letterSpacing: '10px'
                  }}
                >
                  {eIdentification.verificationCode}
                </span>
              </h4>
            )}
          </List.Item>
        </>
      )}
    </Translation>
  );
}

import { apiClient } from 'src/Utilities/httpClients';

export default class ViestiTyttiApi {
  static sendReply = async (parameters: {
    isNewMessageThread: boolean;
    content: string;
    sourceId: string;
    ticketId: string;
    title: string;
    commentFieldIds: [number, number];
    author: {
      UID: number;
      email: string;
    };
    issueId?: string;
    threadStateId?: number;
    isThreadUpdate?: boolean;
  }) => await apiClient.post(`/viestitytti/sendMessage`, parameters).then((response) => response.data);
}

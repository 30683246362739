import _ from 'lodash';
import { UPDATE_WORK_STATUS, UPDATE_WORK_RECORDING_INFO } from '../actions/index';

const initialState = {
  workStatus: {
    lastAction: 'INITIALIZE_STATUS',
    status: [],
    timestamp: 0,
    recordedTicket: {}
  }
};

const workStatusReducer = (state = initialState.workStatus, action: any) => {
  switch (action.type) {
    case UPDATE_WORK_STATUS: {
      return {
        status: action.payload.status,
        timestamp: action.payload.timestamp,
        lastAction: action.payload.message
      };
    }
    case UPDATE_WORK_RECORDING_INFO: {
      const actionUID = action.payload.UID;
      const actionTicket = '' + action.payload.ticketId;
      const newStatusArray = _.cloneDeep(state.status);
      const ticket: any = newStatusArray.find((ticket: any) => {
        return ticket.UID === actionUID && ticket.ticketId === actionTicket;
      });

      if (!ticket) {
        return state;
      }

      const newState = { ...state, status: newStatusArray };
      return newState;
    }

    default:
      return state;
  }
};

export default workStatusReducer;

import { TicketType } from 'src/types/TicketType';
import { postMessageToIFrame } from './postMessageToIFrame';

export const callElisaOC = (phoneNumber: string, serviceName: TicketType['OCoutboundServiceName']['value']) => {
  postMessageToIFrame('ElisaOC', {
    type: 'outboundCallData',
    payload: {
      serviceName: serviceName || 'Asiakaspalvelu',
      phonenumber: phoneNumber
    }
  });
};

import { t } from 'i18next';
import iziToast from 'izitoast';
import queryString from 'query-string';

import { getURLParam } from 'src/Utilities/helper';
import { apiClient } from 'src/Utilities/httpClients';

import type { LoginState } from 'src/types/Auth';

export default class AuthApi {
  static loginExternal = async (token: string, username: string): Promise<LoginState> => {
    return apiClient
      .post('/loginExternal', {
        username: username,
        coretoken: token
      })
      .then((response) => {
        localStorage.setItem('token', response.data.token);
        apiClient.defaults.headers.common = { Authorization: 'Bearer ' + localStorage.getItem('token') };
        return {
          hasLoggedIn: true,
          hasEnteredIncorrectLoginCredentials: false,
          hasEncounteredGeneralLoginError: false
        };
      })
      .catch(() => {
        return {
          hasLoggedIn: false,
          hasEnteredIncorrectLoginCredentials: false,
          hasEncounteredGeneralLoginError: true
        };
      });
  };

  // Refactor to be sync invocation
  static loginBackend = async (): Promise<void> => {
    return Promise.resolve().then(() => {
      apiClient.defaults.withCredentials = true;
    });
  };

  static activate = async (token: string, password: string): Promise<any> => {
    return apiClient
      .post('/activateUser', {
        token: token,
        password: password
      })
      .then(() => {
        return { success: 'true' };
      })
      .catch((error: any) => {
        if (error.response.data.msg === 'Token is not available') {
          iziToast.error({
            title: t('ERROR_HAPPENED'),
            message: t('ACTIVATION_TOKEN_NOT_AVAILABLE'),
            position: 'bottomRight',
            timeout: 10000,
            icon: 'delete'
          });
          throw error;
        } else {
          iziToast.error({
            title: t('ERROR_HAPPENED'),
            message: '',
            position: 'bottomRight',
            timeout: 10000,
            icon: 'delete'
          });
          throw error;
        }
      });
  };

  static forgotPasswordRequest = async (email: string): Promise<{ success: boolean }> => {
    return apiClient
      .post('/forgotpassword', {
        email: email
      })
      .then(() => {
        return { success: 'true' };
      })
      .catch((error) => {
        return error;
      });
  };

  static refreshToken = async (): Promise<string> => {
    return apiClient.post('/auth/refresh').then((response) => {
      return response.data.accessTokenExpiration;
    });
  };

  static login = async (user: string, password: string): Promise<LoginState> => {
    apiClient.defaults.withCredentials = true;

    const redirectUrl = getURLParam<string | undefined>(window.location.search, 'redirectUrl');
    const searchParam = redirectUrl ? `?${queryString.stringify({ redirectUrl })}` : '';
    return apiClient
      .post(`/auth/login/${searchParam}`, {
        username: user,
        password: password
      })
      .then((response) => {
        if (redirectUrl) {
          window.location = redirectUrl as unknown as Location;
        }

        localStorage.setItem('loggedIn', 'true');
        apiClient.defaults.withCredentials = true;

        if (response.data.accessTokenExpiration)
          localStorage.setItem('accessTokenExpiration', response.data.accessTokenExpiration);

        return {
          hasLoggedIn: true,
          hasEnteredIncorrectLoginCredentials: false,
          hasEncounteredGeneralLoginError: false
        };
      })
      .catch((error) => {
        if (error.response) {
          // server responsed with non 2xx code
          console.error('Error while logging in', error.response);
          switch (error.response.status) {
            case 400:
              iziToast.error({
                title: t('LOGIN_FAILURE'),
                message: t('LOGIN_CHECK_YOUR_CREDENTIALS'),
                position: 'center',
                timeout: 5000,
                icon: 'delete'
              });
              return {
                hasLoggedIn: false,
                hasEnteredIncorrectLoginCredentials: true,
                hasEncounteredGeneralLoginError: false
              };
            default:
              iziToast.error({
                title: t('LOGIN_FAILURE'),
                message: t('LOGIN_TRY_AGAIN_SOON'),
                position: 'center',
                timeout: 5000,
                icon: 'delete'
              });
              return {
                hasLoggedIn: false,
                hasEnteredIncorrectLoginCredentials: false,
                hasEncounteredGeneralLoginError: true
              };
          }
        } else if (error.request) {
          iziToast.error({
            title: t('LOGIN_FAILURE'),
            message: t('LOGIN_CHECK_YOUR_INTERNET_CONNECTION'),
            position: 'center',
            timeout: 5000,
            icon: 'delete'
          });
          return {
            hasLoggedIn: false,
            hasEnteredIncorrectLoginCredentials: false,
            hasEncounteredGeneralLoginError: true
          };
        } else {
          iziToast.error({
            title: t('LOGIN_FAILURE'),
            message: t('LOGIN_TRY_AGAIN_SOON'),
            position: 'center',
            timeout: 5000,
            icon: 'delete'
          });
          return {
            hasLoggedIn: false,
            hasEnteredIncorrectLoginCredentials: false,
            hasEncounteredGeneralLoginError: true
          };
        }
      });
  };

  static logout = async (forcedLogout = false): Promise<void> => {
    return apiClient
      .get('/auth/logout')
      .then(() => {
        if (!forcedLogout) {
          iziToast.success({
            message: t('LOGOUT_SUCCESSFUL'),
            position: 'bottomRight',
            icon: 'icon check'
          });
        }
      })
      .catch((error) => {
        console.error('Error while logout in authApi', error);
      });
  };
}

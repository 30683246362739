import { ACTIVE_FRONTEND_FEATURE_FLAGS, TFeatureFlag } from './FeatureFlags';
import type { EnvSettingsParameters } from './EnvSettings';
import type { SMSConfiguration } from 'src/types/Configuration';
import { apiClient } from 'src/Utilities/httpClients';
import type { PhoneConfiguration } from 'src/types/PhoneConfiguration';

export default class UsersApi {
  static getEnvSettings = async (): Promise<EnvSettingsParameters> => {
    return apiClient.get('/settings').then((response) => response.data);
  };

  static getFeatureFlags = async (): Promise<TFeatureFlag[]> => {
    return apiClient.post('/featureFlagsSelected', { featureFlags: ACTIVE_FRONTEND_FEATURE_FLAGS }).then((response) => {
      return response.data;
    });
  };

  static getPhoneConfigurations = async (): Promise<PhoneConfiguration[]> => {
    return apiClient.get('/PhoneConfigurations').then((response) => {
      return response.data;
    });
  };

  static getSMSConfigurations = async (): Promise<SMSConfiguration[]> => {
    return apiClient.get(`/SMS/serviceNumbers/all`).then((response) => response.data);
  };
}

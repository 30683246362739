import type { User } from 'src/types/User';

export const userToNumericalId = (user: User | string | number): number => {
  if (typeof user === 'number') {
    return user;
  }

  if (typeof user !== 'string') {
    user = user.UID;
  }

  return parseInt(user.substring(3), 10);
}

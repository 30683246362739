import React, { useCallback, useState } from 'react';
import { Accordion, Icon, List } from 'semantic-ui-react';
import type { Attachment } from 'src/types/Ticket';
import type { PersonalData } from 'src/types/User';
import type { AttachmentEdit } from './AttachmentItem';
import AttachmentItem from './AttachmentItem';

interface AttachmentsAccordionProps {
  title: string;
  initialOpen: boolean;
  attachments: Attachment[];
  fullAttachments: Attachment[];
  personalData: PersonalData;

  openFileDialog?(): void;
  uploadFile?(files: File[]): void;
  deprecateAttachment?(id: string): void;
  unDeprecateAttachment?(id: string): void;
  editAttachment?(id: string, body: AttachmentEdit): void;
}

const AttachmentsAccordion = ({
  title,
  attachments,
  initialOpen,
  personalData,
  fullAttachments,

  editAttachment,
  deprecateAttachment,
  unDeprecateAttachment,
  openFileDialog
}: AttachmentsAccordionProps) => {
  const [open, setOpen] = useState(initialOpen);

  const onTitleClick = useCallback(() => {
    setOpen((old) => !old);
  }, []);

  return (
    <Accordion>
      <Accordion.Title active={open} onClick={onTitleClick}>
        <Icon name="dropdown" />
        {`${title} (${attachments.length})`}
      </Accordion.Title>
      <Accordion.Content active={open}>
        <List relaxed={true} divided={true} verticalAlign="middle">
          {attachments.map((attachment) => (
            <AttachmentItem
              key={`attachment-item-${attachment.id}`}
              id={attachment.id}
              uri={attachment.uri}
              size={attachment.size}
              uploaded={attachment.uploaded}
              fileName={attachment.fileName}
              deprecated={attachment.deprecated}
              previewUri={attachment.previewUri}
              isQuarantined={attachment.isQuarantined}
              attachments={fullAttachments}
              personalData={personalData}
              onEdit={editAttachment}
              onDeprecate={deprecateAttachment}
              onUnDeprecate={unDeprecateAttachment}
              openFileDialog={openFileDialog}
            />
          ))}
        </List>
      </Accordion.Content>
    </Accordion>
  );
};

export default React.memo(AttachmentsAccordion);

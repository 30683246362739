import type { VFC } from 'react';
import React, { useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import type { State } from 'src/types/initialState';
import type { Attachment } from 'src/types/Ticket';
import type { PersonalData, User } from 'src/types/User';
import type { AttachmentEdit } from '../Attachments/AttachmentItem';
import AttachmentsView from '../Attachments/AttachmentsView';
import AttachmentDropzone from './AttachmentDropzone';

import { DropzoneRefContext } from '../Attachments/AttachmentsContext';
import './Attachments.css';

interface AttachmentsProps {
  user: User;
  attachments: Attachment[];
  personalData: PersonalData;
  dropZoneEnabled: boolean;

  onUpload: (files: File[]) => void;
  onDeprecate: (id: string) => void;
  onUnDeprecate: (id: string) => void;
  onEdit: (id: string, body: AttachmentEdit) => void;
}

const mapStateToProps = (state: State) => ({
  personalData: state.userData
});

const Attachments: VFC<AttachmentsProps> = ({
  user,
  attachments,
  personalData,
  dropZoneEnabled,

  onEdit,
  onUpload,
  onDeprecate,
  onUnDeprecate
}) => {
  const { dropzoneRef } = useContext(DropzoneRefContext);

  const openFileDialog = useCallback(() => {
    dropzoneRef?.current?.open();
  }, [dropzoneRef?.current]);

  return (
    <React.Fragment>
      {attachments.length > 0 && (
        <AttachmentsView
          user={user}
          attachments={attachments}
          personalData={personalData}
          dropZoneEnabled={dropZoneEnabled}
          onEdit={onEdit}
          onUpload={onUpload}
          onDeprecate={onDeprecate}
          onUnDeprecate={onUnDeprecate}
          openFileDialog={openFileDialog}
        />
      )}
      <AttachmentDropzone enabled={dropZoneEnabled} dropzoneRef={dropzoneRef?.current} onUpload={onUpload} />
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(Attachments);

import * as React from 'react';
import { Grid, Button, ButtonProps } from 'semantic-ui-react';
import { Translation } from 'react-i18next';

interface SearchControlsProp {
  onSubmit(event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps): void;
  onClear(event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps): void;
}

class SearchControls extends React.Component<SearchControlsProp> {
  render() {
    return (
      <Translation ns="translations">
        {(t) => (
          <Grid columns={2}>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Button
                  onClick={this.props.onSubmit}
                  content={t('SEARCH')}
                  labelPosition="left"
                  icon="search"
                  id="ticketFilterSearchBtn"
                  fluid={true}
                  primary={true}
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  onClick={this.props.onClear}
                  content={t('GENERAL_CLEAR')}
                  basic={true}
                  fluid={true}
                  id="ticketFilterSearchClearBtn"
                  negative={true}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Translation>
    );
  }
}

export default SearchControls;

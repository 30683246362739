import type { ActionCreator, ThunkAction } from '@reduxjs/toolkit';
import { t } from 'i18next';
import iziToast from 'izitoast';
import type { State } from 'src/types/initialState';
import type { Comment } from 'src/types/Ticket';
import { ADD_COMMENT_SUCCESS } from '.';
import TicketsApi from '../api/TicketsApi';
import { startAjaxCall } from './ajaxStatusActions';

export const addComments = (ticketId: string, comment: Comment) => {
  return { type: ADD_COMMENT_SUCCESS, payload: { ticketId, comment } };
};

export const addComment: ActionCreator<ThunkAction<any, State, any, any>> = (
  ticketId: string,
  data: { [key: string]: any }
) => {
  return (dispatch) => {
    dispatch(startAjaxCall({ name: 'ADD_COMMENT', id: ticketId }));
    return TicketsApi.addComment(ticketId, data)
      .then((response) => {
        if (typeof response.id !== 'undefined') {
          iziToast.success({
            title: t('OK'),
            icon: 'icon check',
            message: `${t('TICKET_COMMENT_SAVE_SUCCESS')}.`,
            timeout: 2500
          });
          dispatch(addComments(ticketId, response));
          return response;
        } else {
          iziToast.error({
            title: `${t('ERROR')}!`,
            icon: 'icon delete',
            message: `${t('TICKET_COMMENT_SAVE_FAILURE')}.` + response,
            timeout: 7500
          });
          throw new Error('Failed to save comment due to id found in response');
        }
      })
      .catch((error) => {
        if (typeof error.response?.data?.message === "string" && error.response?.data?.message.substring(0,1) === "["  && t(error.response?.data?.message.substring(1, error.response?.data?.message.length - 2)) !== error.response?.data?.message) {
          iziToast.error({
            title: `${t('ERROR')}!`,
            icon: 'icon delete',
            message: `${t(error.response.data?.message.substring(1, error.response.data?.message.length - 2))}.`,
            timeout: 7500
          });
          throw error;
        } else {
          iziToast.error({
            title: `${t('ERROR')}!`,
            icon: 'icon delete',
            message: `${t('TICKET_COMMENT_SAVE_FAILURE')}.`,
            timeout: 7500
          });
          throw error;
        }
      });
  };
};

import React, { CSSProperties } from 'react';
import { t } from 'i18next';

import type { SurveyQuestionAnswerQuestionConfiguration } from 'src/types/Survey';

/**
 * Resolve survey answers to smileys
 */
interface SmileyProps {
  configuration: SurveyQuestionAnswerQuestionConfiguration;
  value: number;
  ariaText?: string;
  inline?: boolean;
}

/**
 * Smileys:
 * 0: 😊 U+1F60A
 * 1: 🙂 U+1F642
 * 2: 😐 U+1F610
 * 3: 😕 U+1F615
 * 4: 🙁 U+1F641
 */

export const getSmileyType = (value: number, configuration: SurveyQuestionAnswerQuestionConfiguration) => {
  let text = '';
  if (value !== undefined) {
    // Convert the Unicode Smiley for JS
    const smileyCode = configuration.options.find((s) => s.value === value);
    const smiley = String.fromCodePoint(Number.parseInt(smileyCode?.text ? smileyCode.text.replace('U+', '0x') : '0'));
    text = `${value} ${smiley}`;
  } else {
    text = `${value} (${t('CASE_SURVEY_ANSWER_TYPE_NOT_FOUND')})`;
  }
  return text;
};

const Smiley = ({ configuration, value, ariaText, inline }: SmileyProps) => {
  const style: CSSProperties = {};
  if (inline) {
    style.display = 'inline-block';
  }

  return (
    <div className="smiley" style={style}>
      <span className={value.toString()} aria-label={ariaText} role="img" style={{ fontSize: '1.5rem' }}>
        {getSmileyType(value, configuration)}
      </span>
    </div>
  );
};

export default Smiley;

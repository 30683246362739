import React, { useState } from 'react';
import iziToast from 'izitoast';
import { Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import type { VFC } from 'react';

import TicketNumberInput from 'src/Components/generic/TicketNumberInput';

interface AddRelatedTicketByIdProps {
  addRelatedTicketToTicket: (id: number) => void;
  placeholder: string;
}

const AddRelatedTicketById: VFC<AddRelatedTicketByIdProps> = ({ placeholder, addRelatedTicketToTicket }) => {
  const { t } = useTranslation();
  const [id, setId] = useState<number | null>(null);

  const onClick = async () => {
    if (id === null) {
      return;
    }

    try {
      addRelatedTicketToTicket(id);
    } catch (error) {
      console.log('Error failed to add relation to ticket', error);
      iziToast.error({ message: t('CASE_TOAST_COULD_NOT_ADD_RELATION_TO_TASK') + ': ' + id });
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  const label = () => (
    <Button className="addRelatedIdTicketButton" icon onClick={onClick} primary style={{ borderRadius: '0px' }}>
      <Icon name="plus" />
    </Button>
  );

  return (
    <TicketNumberInput
      label={label}
      onChange={setId}
      placeholder={placeholder}
      value={id}
      style={{ width: '100%' }}
      onKeyDown={onKeyDown}
    />
  );
};

export default AddRelatedTicketById;

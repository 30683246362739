import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ChannelDropdown from 'src/Components/generic/ChannelDropdown';
import type { Ticket } from 'src/types/Ticket';
import type { Widget } from 'src/types/TicketType';
import AccordionHeader from '../AccordionHeader';

interface CaseChannelProps {
  task: Ticket;
  widget: Widget;
  widgetOpen: boolean;

  handleChannelChange(channel: number): void;

  ind: number;
}

const CaseChannel = ({ task, widgetOpen, widget, handleChannelChange, ind }: CaseChannelProps) => {
  const { t } = useTranslation();

  const onChange = useCallback(
    (_, data) => {
      handleChannelChange(data.value);
    },
    [handleChannelChange]
  );

  return (
    <AccordionHeader
      id="widget-channel"
      key={`case-channel-${ind}`}
      as="h4"
      icon="inbox"
      active={widgetOpen}
      title={t('CASE_CHANNEL')}
    >
      <ChannelDropdown id={widget.type} value={task.channel} onChange={onChange} />
    </AccordionHeader>
  );
};

export default React.memo(CaseChannel);

import { t } from 'i18next';
import iziToast from 'izitoast';
import { equals } from 'ramda';
import React from 'react';
import { Dropdown, Form, Radio } from 'semantic-ui-react';

import type { DropdownItemProps } from 'semantic-ui-react';

import ReplyControlButtons from './components/ReplyControlButtons';
import { ReplyMethod } from './ReplyMethod';
import ReplyTemplates from './ReplyTemplates';
import ReplyTextArea from './ReplyTextArea';
import ForeignIdApi from 'src/api/ForeignIdApi';
import ViestiTyttiApi from 'src/api/ViestiTyttiApi';
import ChannelType from 'src/Components/CommentIconContent/ChannelType';
import { Channels } from 'src/types/Channel';

import type { ReplyMethodProps } from './ReplyMethod';
import type { ResponseTemplate } from 'src/types/ResponseTemplate';
import type { Entity, Ticket } from 'src/types/Ticket';
import type { TicketType } from 'src/types/TicketType';
import type { PersonalData, User } from 'src/types/User';

interface ViestiTyttiFormPayload {
  title: string;
  content: string;
  sourceId: string;
  commentFieldIds: [number, number];
  threadStateId: number;
  isThreadUpdate: boolean;
}

interface ReplyViestiTyttiProps extends ReplyMethodProps<ReplyViestiTyttiState> {
  drafts?: Partial<ReplyViestiTyttiState>;
  userData: PersonalData;
  task: Ticket;
  ticketType: TicketType;
  templates: ResponseTemplate[];
  entities: Entity[];
  users: User[];
}

export interface ReplyViestiTyttiState {
  isSubmitting: boolean;
  payload: ViestiTyttiFormPayload;
  selectedReplyTemplate: string | undefined;
}

class ReplyViestiTytti extends ReplyMethod<ReplyViestiTyttiProps, ReplyViestiTyttiState> {
  private commentFieldOptions: DropdownItemProps[] = [
    {
      text: 'Näkyy hakijalle, lähetä tiedote',
      value: '10,30'
    },
    {
      text: 'Sisäinen',
      value: '10,29'
    },
    {
      text: 'Näkyy hakijalle, ei tiedotetta',
      value: '10,31'
    }
  ];

  private threadStateOptions: DropdownItemProps[] = [
    {
      text: 'Käsittelyssä',
      value: 27
    },
    {
      text: 'Uusi',
      value: 26
    },
    {
      text: 'Uutta tietoa',
      value: 28
    },
    {
      text: 'Odottaa vastausta',
      value: 29
    },
    {
      text: 'Käsitelty',
      value: 30
    },
    {
      text: 'Suljettu',
      value: 31
    }
  ];

  constructor(props: ReplyViestiTyttiProps) {
    super(props);

    this.state = this.getInitialState(this.props.drafts);
  }

  componentDidUpdate(prevProps: ReplyViestiTyttiProps) {
    if (!equals(prevProps.task.case, this.props.task.case)) {
      this.setState({
        payload: {
          ...this.state.payload,
          threadStateId:
            (this.threadStateOptions.find((status) => status.text === this.props.task.case.threadStatus)
              ?.value as number) || 0
        }
      });
    }
  }

  getDraftChannel(): Channels {
    return Channels.viestitytti;
  }

  getDraftState(): Partial<ReplyViestiTyttiState> {
    return {
      payload: this.state.payload
    };
  }

  private getInitialState = (drafts?: Partial<ReplyViestiTyttiState>): ReplyViestiTyttiState => ({
    isSubmitting: false,
    selectedReplyTemplate: drafts?.selectedReplyTemplate,
    payload: {
      ...{
        title: this.props.task.title,
        content: '',
        sourceId: '',
        commentFieldIds: (this.commentFieldOptions[0].value as string)
          .split(',')
          .map((element: string) => parseInt(element, 10)) as [number, number],
        isThreadUpdate: false
      },
      ...drafts?.payload,
      threadStateId:
        (this.threadStateOptions.find((status) => status.text === this.props.task.case.threadStatus)
          ?.value as number) || (this.threadStateOptions[0].value as number)
    }
  });

  private handleSetState = (fields: Partial<ReplyViestiTyttiState['payload']>) => {
    this.setState(
      {
        payload: {
          ...this.state.payload,
          ...fields
        }
      },
      () => {
        this.saveDraft(this.state);
      }
    );
  };

  public clearFields = () =>
    this.setState(
      {
        payload: {
          title: this.props.task.title,
          content: '',
          sourceId: '',
          commentFieldIds: (this.commentFieldOptions[0].value as string)
            .split(',')
            .map((element: string) => parseInt(element, 10)) as [number, number],
          threadStateId:
            (this.threadStateOptions.find((status) => status.text === this.props.task.case.threadStatus)
              ?.value as number) || (this.threadStateOptions[0].value as number),
          isThreadUpdate: false
        },
        selectedReplyTemplate: undefined
      },
      () => {
        this.saveDraft(this.state);
      }
    );

  public submitComment = async () => {
    if (this.isDisabled()) {
      return;
    }

    this.setState({
      isSubmitting: true
    });

    try {
      const [foreignId] = await ForeignIdApi.searchForeignId({
        foreignIdType: 'viestiTyttiIntegration',
        localId: this.props.taskId.substring(3),
        localIdType: 'content'
      });
      const isNewMessageThread = !foreignId?.foreignId;

      const response = await ViestiTyttiApi.sendReply({
        isNewMessageThread,
        content: this.state.payload.content,
        sourceId: isNewMessageThread ? this.state.payload.sourceId : this.props.task.originalContact!,
        ticketId: this.props.taskId,
        title: isNewMessageThread ? this.state.payload.title : this.props.task.title,
        commentFieldIds: this.state.payload.commentFieldIds,
        author: {
          UID: parseInt(this.props.userData.UID.substring(3)),
          email: this.props.userData.profile.email
        },
        threadStateId: this.state.payload.threadStateId,
        isThreadUpdate: this.state.payload.isThreadUpdate,
        ...(foreignId?.foreignId && {
          issueId: String(foreignId.foreignId)
        })
      });

      let toastContent: string | undefined;
      if (isNewMessageThread) {
        toastContent = 'New thread was created';
      }
      if (response.isMessageCreated) {
        toastContent = 'New comment was created';
      }
      if (response.isThreadUpdate) {
        toastContent = 'The thread was updated';
      }

      iziToast.success({
        title: t('OK'),
        icon: 'icon check',
        message: toastContent,
        timeout: 5000
      });
    } catch (error) {
      iziToast.error({
        title: `${t('ERROR')}!`,
        icon: 'icon delete',
        timeout: 7500
      });
    }

    this.clearFields();
    this.setState({
      isSubmitting: false
    });
  };

  public isThreadAlreadyExist = () =>
    this.props.task.comments.find(
      (comment) => comment.foreignIdType === 'viestiTyttiIntegration' || comment.metaData?.bot?.name
    );

  private isDisabled = () => {
    return this.isThreadAlreadyExist()
      ? this.state.isSubmitting || (!this.state.payload.content.length && !this.state.payload.isThreadUpdate)
      : this.state.isSubmitting ||
          !this.state.payload.title.length ||
          !this.state.payload.content.length ||
          !this.state.payload.threadStateId ||
          !this.state.payload.sourceId.length;
  };

  render() {
    return (
      <Form reply={true} style={{ marginTop: '20px' }}>
        <Form.Group>
          {!this.isThreadAlreadyExist() && (
            <Form.Field width={10}>
              <label>{t('ADD_COMMENT_TITLE')}</label>

              <Form.Input
                onChange={(event, data) => this.handleSetState({ title: data.value })}
                type="text"
                fluid={true}
                value={this.state.payload.title}
              >
                <input />
              </Form.Input>

              {this.state.payload.title.length >= 900 && (
                <p style={{ color: 'red' }}>{t('general_reply.max_length_limit')} (900)</p>
              )}
            </Form.Field>
          )}

          <Form.Field width={this.isThreadAlreadyExist() ? 'sixteen' : 'six'}>
            <label>{t('ADD_COMMENT_CANNED_RESPONSE')}</label>

            <ReplyTemplates
              userData={this.props.userData}
              ticketType={this.props.ticketType}
              templates={this.props.templates}
              task={this.props.task}
              channel={ChannelType.ViestiTytti}
              entities={this.props.entities}
              users={this.props.users}
              selectedOption={this.state.selectedReplyTemplate}
              setSelectedOption={(value) => this.setState({ selectedReplyTemplate: value })}
              setContent={(value) => this.handleSetState(value)}
              content={
                this.state.payload.title && this.state.payload.title.length > 900
                  ? this.state.payload.title.slice(900, -1) + ' \n\n ' + this.state.payload.content
                  : this.state.payload.content
              }
              discardHtml={true}
            />
          </Form.Field>
        </Form.Group>

        <Form.Field id="commentContentField">
          <label>{t('ADD_COMMENT_CONTENT')}</label>
          <ReplyTextArea
            content={this.state.payload.content}
            onChange={(value) => {
              this.handleSetState({ content: value });
              // Customer want to set status to 'Käsitelty' in case desk user starts typing the response
              this.handleSetState({ threadStateId: 30 });
            }}
            onKeyDown={this.handleHotKeys}
          />
        </Form.Field>

        {!this.isThreadAlreadyExist() && (
          <Form.Field>
            <label>{t('viestitytti_reply.labels.customer_id')}</label>

            <Form.Input
              onChange={(_, data) => this.handleSetState({ sourceId: data.value })}
              type="text"
              fluid={true}
              value={this.state.payload.sourceId}
            >
              <input />
            </Form.Input>
          </Form.Field>
        )}

        <Form.Field>
          <label>{t('viestitytti_reply.labels.customer_field_type')}</label>

          <Dropdown
            fluid
            search
            selection
            options={this.commentFieldOptions}
            value={this.state.payload.commentFieldIds.join(',')}
            onChange={(_, data) => {
              this.handleSetState({
                commentFieldIds: (data.value as string).split(',').map((element: string) => parseInt(element, 10)) as [
                  number,
                  number
                ]
              });
            }}
          />
        </Form.Field>

        <Form.Field>
          <label>{t('viestitytti_reply.labels.thread_state')}</label>

          <Dropdown
            fluid
            search
            selection
            clearable
            options={this.threadStateOptions}
            value={this.state.payload.threadStateId}
            onChange={(_, data) => {
              this.handleSetState({ threadStateId: data.value as number });
            }}
          />
        </Form.Field>

        <Form.Field>
          <Radio
            toggle
            label={t('viestitytti_reply.labels.thread_status_update_only')}
            checked={this.state.payload.isThreadUpdate}
            onClick={(_, data) => {
              this.handleSetState({ isThreadUpdate: data.checked });
            }}
          />
        </Form.Field>

        <ReplyControlButtons
          internal
          small={this.props.smallButtons}
          disabled={this.isDisabled()}
          loading={this.state.isSubmitting}
          onClear={this.clearFields}
          onSubmit={this.submitComment}
        />
      </Form>
    );
  }
}

export default ReplyViestiTytti;

import type { Action, ActionCreator, Dispatch } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import PriorityApi from '../api/PriorityApi';
import { initialRequestSuccess, initialRequestFailure } from './initActions';
import { startAjaxCall } from './ajaxStatusActions';
import {
  ADD_PRIORITIES_SUCCESS,
  FETCH_PRIORITIES_SUCCESS,
  PATCH_PRIORITIES_SUCCESS,
  FETCH_PRIORITIES_FAILURE
} from './index';
import type { Priority } from '../types/Priority';
import type { AxiosError } from 'axios';

export const addPrioritiesSuccess = (priority: Priority) => {
  return { type: ADD_PRIORITIES_SUCCESS, payload: { priority } };
};

export const patchPrioritySuccess = (priority: Priority) => {
  return { type: PATCH_PRIORITIES_SUCCESS, payload: { priority } };
};

export const loadPrioritiesSuccess = (priorities: any[]) => {
  return { type: FETCH_PRIORITIES_SUCCESS, payload: { priorities } };
};

export const loadPrioritiesFailure = (error: Error) => {
  return { type: FETCH_PRIORITIES_FAILURE, payload: { type: 'priorities', error: error } };
};

export const addPriority: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'ADD_PRIORITY' }));
    return PriorityApi.addPriority(data)
      .then((channel) => {
        dispatch(addPrioritiesSuccess(channel[0]));
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

export const patchPriority: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = (data: any) => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'PATCH_PRIORITY' }));
    return PriorityApi.patchPriority(data)
      .then((priorities) => {
        dispatch(patchPrioritySuccess(priorities[0]));
        // TODO typing
        dispatch(fetchPriorities() as any);
      })
      .catch((error: AxiosError) => {
        throw error;
      });
  };
};

export const fetchPriorities: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_PRIORITIES' }));
    return PriorityApi.getPriorities()
      .then((priorities) => {
        dispatch(loadPrioritiesSuccess(priorities));
        dispatch(initialRequestSuccess('priorities'));
      })
      .catch((error: AxiosError) => {
        console.error('Failed to load channel types', error);
        dispatch(loadPrioritiesFailure(error));
        dispatch(initialRequestFailure('priorities', error));
      });
  };
};

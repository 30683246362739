import React from 'react';

import Survey from './Survey';
import type { SurveyAnswer } from 'src/types/Survey';

interface SurveyListProps {
  taskId: string;
  surveys: Array<SurveyAnswer>;
}

const SurveyList = ({ taskId, surveys }: SurveyListProps) => (
  <div>
    {surveys.map((s) => (
      <Survey
        key={s.id}
        surveyIndex={s.id}
        quote={s.feedback}
        answers={s.answers}
        sendAt={s.sendAt}
        sendTo={s.sendTo}
        handledByUserId={s.handledByUserId}
        sendMethod={s.sendMethod}
        originalContactChannel={s.originalContactChannel}
        answeredAt={s.answeredAt}
        complete={s.answeredAt !== null}
      />
    ))}
  </div>
);

export default SurveyList;

import type { TabFilter } from './Filter';
import type { MenuTab } from './MenuTab';
import type { Direction, SortBy } from './Sorting';
import type { TicketDirection, Ticket, TicketListTicket } from './Ticket';

export interface TicketList {
  id: string;
  title: string;
  activeTab: boolean;
  hasError: boolean;
  tickets: Ticket[];
}

export interface TicketListTabFilter extends TabFilter {
  channel?: number[];
  taskType?: string[];
  tags?: string[];
  handledBy?: string;
  title?: string;
  handledByMe?: boolean;
  delegatedToMe?: boolean;
  notDelegated?: boolean;
  delegates?: string[];
  dueDate24h?: boolean;
  dueDateToday?: boolean;
  priorities?: number[];
  tagAND?: string[];
  tagNOT?: string[];
  originalContact?: string;
  originalDirection?: TicketDirection;
  lastContactAddress?: string;
  createdAfter?: number;
  createdBefore?: number;
  touchedAfter?: number;
  touchedBefore?: number;
  dueDateAfter?: number;
  dueDateBefore?: number;
}

export interface TicketListTab extends MenuTab {
  tickets: TicketListTicket[];
  sorting: SortBy;
  direction: Direction;
}

export enum StaticTabs {
  MAIN_VIEW = 'MAIN_VIEW'
}

// copy/paste from backend
export const CaseTypesValues = ['task', 'infopage'] as const;
export const TaskStatusValues = ['todo', 'doing', 'done'] as const;
export const InfoPageStatusValues = ['draft', 'inReview', 'waitingToBePublished', 'published', 'waste'] as const;
export const TicketStatusValues = [...TaskStatusValues, ...InfoPageStatusValues] as const;

export type CaseType = typeof CaseTypesValues[number];
export type TaskStatus = typeof TaskStatusValues[number];
export type InfoPageStatus = typeof InfoPageStatusValues[number];
export type TicketStatus = typeof TicketStatusValues[number];

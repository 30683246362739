import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import EnreachVoice from 'src/Components/PhoneServices/EnreachVoice';
import { handleIncomingPhoneCall } from 'src/handlers/handlePhoneCall';
import { setEnreachVoiceLoggedIn, setEnreachVoiceStatus } from 'src/actions/integrationsActionsRTK';
import type { EnreachStatus } from 'src/Utilities/intergrations/callEnreachVoice';
import type { IntegrationData } from 'src/handlers/handlePhoneCall';
import type { State } from 'src/types/initialState';

const mapStateToProps = (state: State, ownProps: any) => {
  return {
    callRequests: state.callRequests,
    configurations: state.configurations,
    ticketTypes: state.ticketTypes,
    userData: state.userData,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    handleIncomingPhoneCall: (callObject: IntegrationData) => {
      return dispatch(handleIncomingPhoneCall(callObject));
    },
    setEnreachVoiceLoggedIn: (loggedIn: boolean) => {
      dispatch(setEnreachVoiceLoggedIn({ loggedIn }));
    },
    setEnreachVoiceStatus: (status: EnreachStatus) => {
      dispatch(setEnreachVoiceStatus({ status }));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EnreachVoice));

import React, { useState } from 'react';
import iziToast from 'izitoast';
import { Translation } from 'react-i18next';
import { Icon, Label, List, Popup } from 'semantic-ui-react';
import type { PopupProps } from 'semantic-ui-react';
import type { VFC } from 'react';

import UserLabel from 'src/Components/User/UserLabel';
import WeightModal from './WeightModal';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { iconName } from 'src/Utilities/iconName';
import type { Channel } from 'src/types/Channel';
import type { PersonalData } from 'src/types/User';
import type { Ticket } from 'src/types/Ticket';
import DueDatePicker from 'src/Components/generic/DueDatePicker';

interface Props {
  task: Ticket;
  userData: PersonalData;
  showWeight?: boolean;
  channels?: Channel[];

  dueDateChange: (newDueDate: number) => void;
  updateTicket(id: string, data: Partial<Ticket>): void;
}

const popupDefaultProps: PopupProps = {
  flowing: true,
  hoverable: true,
  position: 'bottom center',
  wide: true
};

const getFormattedDate = (date: number | string) => getPrettyDate(date, { format: DATE_TIME_FORMAT });

const TopBarLabelList: VFC<Props> = ({ channels, dueDateChange, task, userData, updateTicket, showWeight }) => {
  const [weight, setWeight] = useState(task.weight || 0);
  const [isEditingWeight, setIsEditingWeight] = useState(false);

  const dateCreated = getFormattedDate(task.created);
  const dateTouched = getFormattedDate(task.touched);

  return (
    <Translation ns="translations">
      {(t) => (
        <List className="topBarLabelList" horizontal divided>
          <List.Item>
            <UserLabel UID={task.createdByUser} />
          </List.Item>

          <Popup
            {...popupDefaultProps}
            content={t('TOOLTIP_CREATED')}
            header={dateCreated}
            trigger={
              <List.Item>
                <Label>
                  <Icon name="plus" />
                  {dateCreated}
                </Label>
              </List.Item>
            }
          />
          <Popup
            {...popupDefaultProps}
            content={t('TOOLTIP_TOUCHED')}
            header={dateTouched}
            trigger={
              <List.Item>
                <Label>
                  <Icon name="pencil" />
                  {dateTouched}
                </Label>
              </List.Item>
            }
          />

          <List.Item>
            <DueDatePicker
              disabled={!userData.permissions.includes('updateContent')}
              dueDate={task.dueDate}
              changeDueDate={(value: number) => {
                iziToast.success({
                  title: t('TICKET_DUEDATE_SET'),
                  message: getFormattedDate(value!),
                  icon: 'icon check'
                });
                dueDateChange(value);
              }}
            />
          </List.Item>

          {channels && 'originalDirection' in task && (
            <Popup
              disabled
              position="bottom center"
              trigger={
                <List.Item>
                  <Label>
                    <Icon
                      name={iconName(
                        channels.find((c) => c.id === task.channel),
                        task.originalDirection
                      )}
                    />
                    {t(`TICKET_DIRECTION_${task.originalDirection.toUpperCase()}`)}
                  </Label>
                </List.Item>
              }
            />
          )}

          {showWeight && (
            <WeightModal
              weight={weight}
              onChange={(value) => {
                setWeight(value);
                if (task) {
                  updateTicket(task.id, {
                    weight: value
                  });
                }
              }}
              open={isEditingWeight}
              setOpen={setIsEditingWeight}
            />
          )}
        </List>
      )}
    </Translation>
  );
};

export default TopBarLabelList;

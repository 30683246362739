import { FETCH_TICKET_TYPES_METADATA_SUCCESS } from '../actions/index';

import type { TicketTypeMetadata } from 'src/types/TicketType';

const initialState: TicketTypeMetadata[] = [];

const typesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case FETCH_TICKET_TYPES_METADATA_SUCCESS: {
      const parsedTicketTypesMetadata = action.payload.ticketTypesMetadata.map(
        (ticketTypeMetadata: TicketTypeMetadata) => {
          return {
            ...ticketTypeMetadata,
            id: typeof ticketTypeMetadata.id === 'string' ? parseInt(ticketTypeMetadata.id) : ticketTypeMetadata.id
          };
        }
      );
      return parsedTicketTypesMetadata;
    }
    default:
      return state;
  }
};

export default typesReducer;

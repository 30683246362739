import type { PersonalData } from 'src/types/User';
import {
  FETCH_PERSONAL_DATA_SUCCESS,
  REMOVE_ALL_NOTIFICATIONS,
  SWITCH_TICKET_CHAT_ANCHOR_STATUS,
  UPDATE_PERSONAL_PROFILE
} from 'src/actions';

const initialState = {
  data: {}
} as unknown as { data: PersonalData };

const userDataReducer = (state = initialState.data, action: any) => {
  switch (action.type) {
    case FETCH_PERSONAL_DATA_SUCCESS: {
      return action.payload;
    }
    case SWITCH_TICKET_CHAT_ANCHOR_STATUS: {
      return {
        ...state,
        profile: {
          ...state.profile,
          chatAnchored: action.payload.chatAnchored
        }
      };
    }

    case UPDATE_PERSONAL_PROFILE: {
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload.profile
        },
        userPreferences: {
          ...state.userPreferences,
          defaultFilterPreset: action.payload.profile.defaultFilterPreset
        }
      };
    }

    case REMOVE_ALL_NOTIFICATIONS: {
      return {
        ...state,
        userChannelNotifications: []
      };
    }
    default:
      return state;
  }
};

export default userDataReducer;

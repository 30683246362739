import React from 'react';
import { Popup } from 'semantic-ui-react';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import type { FC } from 'react';

import UserProfile from './UserProfile';
import { userToNumericalId } from '../../Utilities/users';
import type { State } from 'src/types/initialState';

interface UserPopupProps {
  UID: number | string;
  disabled?: boolean;
  botName?: string;
}

export const UserPopup: FC<UserPopupProps> = ({ UID, disabled, botName, children }) => {
  const users = useSelector((state: State) => state.usersList.usersList);
  const userData = users.find((usr) => userToNumericalId(usr.UID) === userToNumericalId(UID));

  if (!userData) {
    return <span>{t('ERROR_HAPPENED')}</span>;
  }

  const content = <UserProfile user={userData} botName={botName} />;

  return (
    <Popup
      content={content}
      disabled={disabled}
      flowing
      hoverable
      key={userData.UID}
      style={{ padding: 0 }}
      trigger={children}
      wide
    />
  );
};

export default UserPopup;

import { Store } from 'redux';

import { history } from '../reducers';
import { filterTicketList } from './ticketList';
import { soundQueue } from './soundNotifications';
import { addNotification, removeNotification } from '../actions/notificationsActions';
import type { TicketListTicket } from 'src/types/Ticket';
import type {
  NotificationRule,
  NotificationRuleSoundConfig,
  NotificationRulePopupConfig,
  PersonalData
} from 'src/types/User';
import type { State } from 'src/types/initialState';

interface NotificationConfig {
  soundConfig: NotificationRuleSoundConfig | null;
  popupConfig: NotificationRulePopupConfig | null;
  isRunOnce: number;
}

class NotificationHandler {
  private store: Store<State>;
  constructor(store: Store) {
    this.store = store;
  }

  getTicketById = (ticketId: string): TicketListTicket | undefined => {
    const tickets = this.store.getState().ticketListTabs.MAIN_VIEW.tickets;
    return tickets.find((ticket: TicketListTicket) => ticket.id === ticketId);
  };

  public handleTicket = async (ticket: TicketListTicket): Promise<void> => {
    if (!this.validTicketStatus(ticket)) return;

    const personalData: PersonalData = this.store.getState().userData;
    const notificationDelayRecord = this.store
      .getState()
      .notifications.delays.find((ticketId: string) => ticketId === ticket.id);
    const notificationDelayInSeconds = this.getNotificationDelay(ticket, personalData);

    if (Number.isInteger(notificationDelayInSeconds) && !notificationDelayRecord) {
      this.handleNotificationDelay(ticket.id, personalData, notificationDelayInSeconds!);
    }
    return;
  };

  private getNotificationDelay = (ticket: TicketListTicket, personalData: PersonalData) => {
    const matchedNotificationRule = personalData.userChannelNotifications
      .filter((notificationRule: NotificationRule) => notificationRule.active)
      .find((notificationRule: NotificationRule) => {
        if (
          filterTicketList({
            categories: this.store.getState().categories,
            tickets: [ticket],
            status: 'todo',
            personalData,
            filters: notificationRule.conditions
          }).length
        ) {
          return true;
        }
        return false;
      });

    return matchedNotificationRule?.delay;
  };

  private getNotificationConfig = (ticket: TicketListTicket, personalData: PersonalData): NotificationConfig => {
    const notificationConfig: NotificationConfig = {
      soundConfig: null,
      popupConfig: null,
      isRunOnce: 0
    };

    if (!this.validTicketStatus(ticket)) return notificationConfig;

    personalData.userChannelNotifications
      .filter((notificationRule) => notificationRule.active)
      .forEach((notificationRule) => {
        if (
          filterTicketList({
            categories: this.store.getState().categories,
            tickets: [ticket],
            status: 'todo',
            personalData,
            filters: notificationRule.conditions
          }).length
        ) {
          if (notificationRule.soundConfig.active && !notificationConfig.soundConfig) {
            notificationConfig.soundConfig = notificationRule.soundConfig;
          }

          if (notificationRule.popupConfig.active && !notificationConfig.popupConfig) {
            notificationConfig.popupConfig = notificationRule.popupConfig;
          }

          notificationConfig.isRunOnce = notificationRule.isRunOnce;
        }
      });

    return notificationConfig;
  };

  private handleNotificationDelay = (ticketId: string, personalData: PersonalData, notificationDelay: number) => {
    this.store.dispatch(addNotification(ticketId, 'delays'));
    setTimeout(() => {
      const ticket = this.getTicketById(ticketId);
      if (ticket) {
        const notificationConfig = this.getNotificationConfig(ticket, personalData);

        /**
         * Use case when notification was fired with condition isRunOnce
         * but notification configuration changed to not run it only once
         */
        if (notificationConfig.isRunOnce === 0 && this.store.getState().notifications.runOnce.includes(ticketId)) {
          this.store.dispatch(removeNotification(ticketId, 'runOnce'));
        }

        if (!this.store.getState().notifications.runOnce.includes(ticketId)) {
          if (notificationConfig.isRunOnce) this.store.dispatch(addNotification(ticketId, 'runOnce'));

          if (notificationConfig.soundConfig) {
            this.handleSoundNotificationTimeout(ticketId, notificationConfig.soundConfig.timeoutInSec);
            this.store.dispatch(addNotification(ticketId, 'soundTimeouts'));
          }

          if (notificationConfig.popupConfig) {
            this.handlePopupNotificationTimeout(ticketId, notificationConfig.popupConfig.timeoutInSec);
            this.store.dispatch(addNotification(ticketId, 'popupTimeouts'));
          }
        } else {
          this.store.dispatch(removeNotification(ticketId, 'delays'));
        }
      } else {
        this.store.dispatch(removeNotification(ticketId, 'delays'));
      }
    }, notificationDelay * 1000);
  };

  private handleSoundNotificationTimeout = (ticketId: string, timeoutInSeconds: number) => {
    return setTimeout(() => {
      const ticket = this.getTicketById(ticketId);
      const personalData = this.store.getState().userData;

      if (ticket) {
        const notificationConfig = this.getNotificationConfig(ticket, personalData);

        if (notificationConfig.soundConfig) {
          this.store.dispatch(addNotification(ticketId, 'soundTimeouts'));

          /**
           * Posibility to propagate soundConfig to fireSoundNotification
           * For future implementation with custom sound effect
           */
          this.applySoundNotification();

          if (notificationConfig.soundConfig.timeoutInSec > 0) {
            this.handleSoundNotificationTimeout(ticketId, notificationConfig.soundConfig.timeoutInSec);
          } else {
            notificationConfig.soundConfig = null;
          }
        }

        const isPopupNotificationAlreadySetUp = this.store.getState().notifications.popupTimeouts.includes(ticketId);
        if (notificationConfig.popupConfig && !isPopupNotificationAlreadySetUp) {
          if (notificationConfig.popupConfig.timeoutInSec > 0) {
            this.store.dispatch(addNotification(ticketId, 'popupTimeouts'));
            this.handlePopupNotificationTimeout(ticketId, notificationConfig.popupConfig.timeoutInSec);
          } else {
            this.store.dispatch(removeNotification(ticketId, 'popupTimeouts'));
            notificationConfig.popupConfig = null;
          }
        }

        if (!notificationConfig.soundConfig) {
          this.store.dispatch(removeNotification(ticketId, 'soundTimeouts'));

          /**
           * No more notification set up for this ticket
           */
          if (!notificationConfig.popupConfig && !isPopupNotificationAlreadySetUp) {
            this.store.dispatch(removeNotification(ticketId, 'delays'));
          }
        }
      } else {
        this.store.dispatch(removeNotification(ticketId, 'delays'));
        this.store.dispatch(removeNotification(ticketId, 'soundTimeouts'));
      }
    }, timeoutInSeconds * 1000);
  };

  private handlePopupNotificationTimeout = (ticketId: string, timeoutInSeconds: number) => {
    return setTimeout(() => {
      const ticket = this.getTicketById(ticketId);
      const personalData = this.store.getState().userData;

      if (ticket) {
        const notificationConfig = this.getNotificationConfig(ticket, personalData);

        if (notificationConfig.popupConfig) {
          this.store.dispatch(addNotification(ticketId, 'popupTimeouts'));

          this.applyPopupNotification(notificationConfig.popupConfig, ticketId);

          if (notificationConfig.popupConfig.timeoutInSec > 0) {
            this.handlePopupNotificationTimeout(ticketId, notificationConfig.popupConfig.timeoutInSec);
          } else {
            notificationConfig.popupConfig = null;
          }
        }

        const isSoundNotificationAlreadySetUp = this.store.getState().notifications.soundTimeouts.includes(ticketId);
        if (notificationConfig.soundConfig && !isSoundNotificationAlreadySetUp) {
          if (notificationConfig.soundConfig.timeoutInSec > 0) {
            this.store.dispatch(addNotification(ticketId, 'soundTimeouts'));
            this.handleSoundNotificationTimeout(ticketId, notificationConfig.soundConfig.timeoutInSec);
          } else {
            this.store.dispatch(removeNotification(ticketId, 'soundTimeouts'));
            notificationConfig.soundConfig = null;
          }
        }

        if (!notificationConfig.popupConfig) {
          this.store.dispatch(removeNotification(ticketId, 'popupTimeouts'));

          /**
           * No more notification set up for this ticket
           */
          if (!notificationConfig.soundConfig && !isSoundNotificationAlreadySetUp) {
            this.store.dispatch(removeNotification(ticketId, 'delays'));
          }
        }
      } else {
        this.store.dispatch(removeNotification(ticketId, 'delays'));
        this.store.dispatch(removeNotification(ticketId, 'popupTimeouts'));
      }
    }, timeoutInSeconds * 1000);
  };

  private validTicketStatus = (ticket: TicketListTicket) => ticket.status === 'todo';

  private applySoundNotification = () => {
    soundQueue.pushSound();
  };

  public applyPopupNotification = (config: NotificationRulePopupConfig, ticketId: string) => {
    Notification.requestPermission().then(() => {
      const notification = new Notification(config.title, {
        body: config.content
      });

      notification.onclick = (event) => {
        event.preventDefault();

        history.push(`/case/${ticketId}`);
        notification.close();
      };
    });
  };
}

export default NotificationHandler;

import { SemanticICONS } from 'semantic-ui-react';

export enum CommentsMenuItem {
  messages = 'messages',
  history = 'history'
}

export interface CommentsTab {
  id: string;
  title: string;
  key: CommentsMenuItem;
  name: SemanticICONS;
}

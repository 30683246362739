import type { ConnectedProps } from 'react-redux';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import { setActiveSettingsTab } from '../actions/settingsActions';
import { changePassword, createUser } from 'src/actions/userActions';
import { copyUserData } from 'src/reducers/usersListReducer';
import SettingsOverview from 'src/Components/Settings/SettingsOverview';
import type { User } from 'src/types/User';
import type { State } from 'src/types/initialState';

const mapStateToProps = (state: State) => {
  const userInUsersList = state.usersList.usersList.find((user: User) => user.UID === state.userData.UID);

  return {
    userData: state.userData,
    availableTicketTypes: state.ticketTypes,
    roles: state.roles,
    userRole: userInUsersList?.role.id,
    copiedUser: state.usersList.copiedUser,
    channels: state.channels
  };
};

const connector = connect(mapStateToProps, {
  createUser,
  changePassword,
  copyUserData,
  setActiveSettingsTab
});

export interface SettingsOverviewReduxProps extends RouteComponentProps, ConnectedProps<typeof connector> {}

export default withRouter(connector(SettingsOverview));

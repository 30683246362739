import type { ComponentProps, CSSProperties } from 'react';
import type { Dropdown, SemanticICONS } from 'semantic-ui-react';

import type { FormattedSearch } from './Search';
import type { Tag } from './Tag';

export interface FieldOption {
  name: string;
  value: string;
  style?: CSSProperties;
  icon?: SemanticICONS;
}

export enum FieldTypes {
  action = 'action',
  complexList = 'complexList',
  datepicker = 'datepicker',
  daterange = 'daterange',
  dateselector = 'dateselector',
  email = 'email',
  empty = 'empty',
  errorMessage = 'errorMessage',
  googleMaps = 'googleMaps',
  link = 'link',
  list = 'list',
  loadingChevron = 'loadingChevron',
  phoneNumber = 'phoneNumber',
  separator = 'separator',
  subAccordion = 'subAccordion',
  subEntity = 'subEntity',
  subEntityField = 'subEntityField',
  textarea = 'textarea',
  verifyFields = 'verifyFields'
}

type ParamsForDropdown = Partial<Pick<ComponentProps<typeof Dropdown>, 'clearable' | 'selection'>>;

export interface FieldParams extends ParamsForDropdown, Record<string, any> {
  unit?: any;
  input?: any;
  noMongo?: boolean;
  readOnly?: boolean;
  noSearch?: boolean;
  actionType?: string;
  entityType?: string;
  getByValue?: string;
  clickToSearch?: boolean;
  mongoNoRender?: boolean;
  replaceString?: string[];
  replaceStrings?: string[][];
  includeToSearch?: boolean;
  openInfoCheckbox?: boolean;
  integrationNoRender?: boolean;
  maxVisibleAccordionItems?: number;
  mandatoryForTicketClosing?: boolean;
  // can be just hardcoded string or pattern in a format of parseFieldValue utility function
  optionsURL?: string;
  // array of fields that are going to be cleared on current field change
  dependant?: string[];
}

export interface Field {
  name?: string;
  displayName?: string;
  title?: string;
  customType?: FieldTypes;
  value: string;
  options?: FieldOption[] | string;
  displayField?: string;
  props?: any;
  switch?: any[];
  object?: string;
  params?: FieldParams;
  childPrefix?: any;
  childTitle?: string;
  readOnly?: boolean;
  searchable?: boolean;
  linkText?: string;
  subField?: Field;
  selectableFields?: string[];
  minSelected?: number;
  logAutomaticComment?: boolean;
  openInIFrame?: boolean;
  iFrameTabTitle?: string;
  mustBeVerifiedIn?: { channels: number[] };
  validation?: {
    regex: string;
    flags?: string;
    errorText: string;
  };
}

export enum FieldActionType {
  addEntity = 'add_entity'
}

export interface DropdownOption {
  text: string;
  value: string;
}

export type OnInfoSave = (
  param: keyof FormattedSearch['basic'],
  value: string | boolean | Tag[] | null | undefined,
  object: any,
  partial: boolean,
  name: string,
  text: string,
  datagroup?: string,
  entityType?: string,
  id?: any,
  entityTypes?: string[]
) => void;

export type EntitySearchFields = Partial<{ [key in Parameters<OnInfoSave>[0]]: Parameters<OnInfoSave>[1] }>;

import { Cookies } from 'react-cookie';

import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE_CREDENTIALS,
  LOGIN_FAILURE_GENERAL,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  ACTIVATION_SUCCESS
} from '../actions/index';
import { getParameterByName } from '../Utilities/url';
import type { AuthState } from 'src/types/initialState';

interface External {
  username: string | false;
  urlToken: string | false;
  isBackendToken: boolean;
}

const bknd = decodeURIComponent(getParameterByName('bknd', window.location.href) || '');
const urlHasBackendToken = bknd !== '' && bknd !== null && bknd !== undefined;

const external: External = {
  username: false,
  urlToken: false,
  isBackendToken: false
};

const cookies = new Cookies();
const cookie = cookies.get('eeedo');

let token = null;

if (urlHasBackendToken) {
  localStorage.setItem('token', bknd);
  external.isBackendToken = true;
  external.username = decodeURIComponent(getParameterByName('username', window.location.href) || '');
  external.urlToken = decodeURIComponent(getParameterByName('token', window.location.href) || '');
} else if (cookie !== undefined && cookie !== null && cookie !== '') {
  external.urlToken = cookie;
  external.isBackendToken = true;
  localStorage.setItem('token', cookie);
  token = cookie;
} else if (localStorage.getItem('token')) {
  token = localStorage.getItem('token');
}

let hasTokenInLocalStorage = undefined;
hasTokenInLocalStorage = typeof token !== 'undefined' && token !== '' && token !== null;

const initialState: AuthState = {
  loggedIn: hasTokenInLocalStorage,
  external: {
    username: external.username,
    token: external.urlToken,
    isBackendToken: external.isBackendToken
  },
  hasEnteredIncorrectLoginCredentials: false,
  hasEncounteredGeneralLoginError: false,
  initialAddress: '',
  activationSuccess: false
};

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return { ...state, ...{ loggedIn: true, loginName: action.payload } };
    }
    case LOGIN_FAILURE_CREDENTIALS: {
      return { ...state, ...{ loggedIn: false, hasEnteredIncorrectLoginCredentials: true } };
    }
    case LOGIN_FAILURE_GENERAL: {
      return { ...state, ...{ loggedIn: false, hasEncounteredGeneralLoginError: true } };
    }
    case LOGOUT_SUCCESS: {
      return { ...initialState, loggedIn: false };
    }
    case LOGOUT_FAILURE: {
      return { ...state, ...{ loggedIn: true } };
    }
    case ACTIVATION_SUCCESS: {
      return { ...state, ...{ activationSuccess: true } };
    }
    default:
      return state;
  }
};

export default authReducer;

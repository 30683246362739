import React from 'react';
import { Dropdown, Label } from 'semantic-ui-react';
import { orderBy, partition } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { DropdownProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/Dropdown';
import type { VFC } from 'react';

import AccordionHeader from 'src/Components/Case/AccordionHeader';
import FeatureFlags from 'src/api/FeatureFlags';
import TagPopup from 'src/Components/generic/TagPopup';
import { Roles } from 'src/types/User';
import { addTagToContent, removeTagFromContent } from 'src/actions/ticketsActions';
import { filterNonDefaultTags, filterTagsByCategoryIds } from 'src/types/Tag';
import type { State } from 'src/types/initialState';
import type { Widget } from 'src/types/TicketType';

interface Props {
  displayName: string;
  widget?: Widget;
  widgetOpen: boolean;
}

const TagsWidget: VFC<Props> = ({ displayName, widget, widgetOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userData = useSelector(({ userData }: State) => userData);
  const user = useSelector(({ usersList: { usersList } }: State) => usersList.find(({ UID }) => userData.UID === UID));
  const tags = useSelector(({ tags }: State) => tags);
  const ticketTypes = useSelector(({ ticketTypes }: State) => ticketTypes);
  const ticket = useSelector(({ detailedTickets, activeTicketTab }: State) =>
    detailedTickets.find(({ id }) => activeTicketTab === id)
  );

  const ticketType = ticketTypes.find(({ name }) => ticket?.taskType === name);
  const categoryIds = widget?.options?.categoryIds || [];
  const tagsFiltered = categoryIds.length ? filterTagsByCategoryIds(tags, categoryIds) : filterNonDefaultTags(tags);
  const tagsSorted = orderBy(tagsFiltered, ['weight', 'name'], ['desc', 'asc']);
  const [selectedTags, unselectedTags] = partition(tagsSorted, (tag) => ticket?.tags.includes(tag.id));

  const tagOptions = unselectedTags
    .filter((tag) => !tag.deprecated)
    .filter((tag) => !tag.ticketTypes.length || !ticketType?.id || tag.ticketTypes.includes(ticketType?.id))
    .map((tag) => ({ text: tag.name, value: tag.id }));

  const isAdmin = user && Roles.isAdminLike(user.role.id);
  const allowAdditions = isAdmin && FeatureFlags.isFlagOn('ALLOW_TAG_ADDITION_FROM_TASK') && !categoryIds.length;

  const onAddTag = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps, addNew: boolean) => {
    const tagId = data.value && data.value.toString();

    if (tagId && ticket && (tagId.slice(0, 3) === 'TAG' || addNew)) {
      dispatch(addTagToContent(ticket.id, tagId.toString(), addNew));
    }
  };

  const onRemoveTag = (tagId: string) => {
    if (ticket && tagId) {
      dispatch(removeTagFromContent(ticket.id, tagId));
    }
  };

  return (
    <AccordionHeader
      active={widgetOpen}
      as="h4"
      icon="tags"
      id="widget-tags"
      title={`${displayName} (${selectedTags.length})`}
    >
      <Label.Group tag data-test="tagsWidgetLabels">
        {selectedTags.map((tag) => (
          <TagPopup key={tag.id} tag={tag} onClose={() => onRemoveTag(tag.id)} />
        ))}
      </Label.Group>
      <Dropdown
        additionLabel={t('ADD')}
        allowAdditions={allowAdditions}
        data-test="tagsWidgetDropdown"
        disabled={!userData.permissions.includes('updateContent')}
        onAddItem={(event, data) => onAddTag(event, data, true)}
        onChange={(event, data) => onAddTag(event, data, false)}
        options={tagOptions}
        placeholder={t('PLACEHOLDER_WRITE_TO_SEARCH')}
        search
        selectOnBlur={false}
        selectOnNavigation={false}
        selection
        size="mini"
        value=""
      />
    </AccordionHeader>
  );
};

export default TagsWidget;

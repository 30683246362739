import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { FC } from 'react';
import type { ContentTypesFields } from 'src/types/Ticket';
import type { Direction, SortBy } from 'src/types/Sorting';
import type { IFilterItem, TabFilter } from 'src/types/Filter';
import Filter from 'src/Components/ticketList/Filter';
import type { LocalFiltersState } from 'src/Components/ticketList/Filter';
import type { State } from 'src/types/initialState';
import Sorting from 'src/Components/Filter/Sorting';

import { filterComponent, mainControlsWrapper } from 'src/Components/ticketList/Filter.style';

export interface IProps {
  setFilter(args: IFilterItem): void;
  setSorting(sorting: SortBy, direction: Direction): void;
  contentType: ContentTypesFields;
  filters: TabFilter;
  sorting: SortBy;
  direction: Direction;
}

type FilterParams = 'title' | 'originalContact' | 'lastContactAddress';

const FilterPresetsFiltersEditor: FC<IProps> = (props) => {
  const reduxProps = useSelector((state: State) => ({
    categories: state.categories,
    ticketTypesMetadata: state.ticketTypesMetadata,
    personalData: state.userData,
    ticketTypes: state.ticketTypes,
    channels: state.channels,
    tags: state.tags,
    usersList: state.usersList.usersList,
    priorities: state.ticketPriorities
  }));

  const getParam = (param: FilterParams) => {
    return props.filters[param];
  };

  const [localFiltersState, setLocalFiltersState] = useState<LocalFiltersState>({
    titleFilter: getParam('title'),
    originalContactFilter: getParam('originalContact'),
    lastContactAddressFilter: getParam('lastContactAddress')
  });

  const changeFilter = (value: any, type: keyof TabFilter) => {
    props.setFilter({
      id: '',
      parameter: type,
      value: value
    });
  };

  const onChangeFilter = (filter: FilterParams, prop: keyof LocalFiltersState, value: string) => {
    setLocalFiltersState({ ...localFiltersState, [prop]: value });
    props.setFilter({
      id: '',
      parameter: filter as keyof TabFilter,
      value: value
    });
  };

  useEffect(() => {
    setLocalFiltersState({
      titleFilter: getParam('title'),
      originalContactFilter: getParam('originalContact'),
      lastContactAddressFilter: getParam('lastContactAddress')
    })
  }, [props.filters.title, props.filters.originalContact, props.filters.lastContactAddress])

  return (

    <div className="filterComponent" style={{ ...filterComponent(true), margin: 0 }}>
      {props.contentType === 'tickets' && (
        <div style={mainControlsWrapper}>
          <Sorting sorting={props.sorting} direction={props.direction} onChange={props.setSorting} />
        </div>
      )}
      <Filter
        ticketTypesMetadata={reduxProps.ticketTypesMetadata}
        categories={reduxProps.categories}
        personalData={reduxProps.personalData}
        ticketTypes={reduxProps.ticketTypes}
        channels={reduxProps.channels}
        tags={reduxProps.tags}
        usersList={reduxProps.usersList}
        priorities={reduxProps.priorities}
        contentType={props.contentType}
        localFiltersState={localFiltersState}
        filters={props.filters}
        toggleExtraOptions={() => undefined}
        changeFilter={changeFilter}
        onChangeFilter={onChangeFilter}
        isExtraOptionsOpen
        asEditor
      />
    </div>
  );
};

export default FilterPresetsFiltersEditor;

import React, { useState } from 'react';
import { Translation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Button } from 'semantic-ui-react';

import TicketsApi from 'src/api/TicketsApi';
import openConfirmationToast from './ConfirmationPopup';
import { Roles } from 'src/types/User';

import type { ConnectedProps } from 'react-redux';
import type { State } from 'src/types/initialState';

interface MergeTicketsProps extends MergeTickersReduxProps {}

const MergeTickets: React.VFC<MergeTicketsProps> = ({ openTicketsIds, activeTicketTab, user }) => {
  const [selected, setSelected] = useState<string[]>([]);
  const mergeTickets = () => {
    if (activeTicketTab && selected?.length) {
      TicketsApi.mergeTicketsInto(activeTicketTab, selected).then(() => {
        setSelected([]);
      });
    }
  };
  const isAllowed = user?.role.id && !Roles.isAnyOf(user.role.id, Roles.ReadOnly, Roles.ReadOnlySingleTicker);

  return (
    <Translation>
      {(t) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Select
            styles={{ container: () => ({ width: '100%', paddingRight: '5px' }) }}
            onChange={(value) => {
              const option = value ?? [];
              setSelected(option.map((v) => v.value));
            }}
            options={openTicketsIds.filter((id) => id !== activeTicketTab).map((id) => ({ label: id, value: id }))}
            isMulti
            closeMenuOnSelect={false}
            isDisabled={!isAllowed}
            value={selected.map((id) => ({ label: id, value: id }))}
          />
          <Button
            style={{ minWidth: '170px' }}
            disabled={!isAllowed || !selected?.length}
            onClick={() => {
              openConfirmationToast({
                t,
                onConfirm: mergeTickets,
                mergeTickets: selected.join(', '),
                mainTicket: activeTicketTab as string
              });
            }}
          >
            {t('top_bar.advanced_actions.merge_tickets.button')}
          </Button>
        </div>
      )}
    </Translation>
  );
};

const mapToStateProps = (state: State) => ({
  openTicketsIds: state.ticketTabs.map((t) => t.id),
  activeTicketTab: state.activeTicketTab,
  user: state.usersList.usersList.find((u) => state.userData.UID === u.UID)
});

const connector = connect(mapToStateProps);

interface MergeTickersReduxProps extends ConnectedProps<typeof connector> {}

export default connector(MergeTickets);

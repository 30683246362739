import { Input } from 'semantic-ui-react';
import * as React from 'react';
import { t } from 'i18next';
import { CSSProperties } from 'react';

interface LastContactAddressProps {
  lastContactAddressFilter?: string;
  onChangeFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: CSSProperties;
}

export default function LastContactAddressFilter(props: LastContactAddressProps) {
  return (
    <Input
      id="FILTER_BY_LAST_CONTACT_ADDRESS"
      placeholder={t('FILTER_BY_LAST_CONTACT_ADDRESS')}
      icon="search"
      value={props.lastContactAddressFilter || ''}
      onChange={props.onChangeFilter}
      fluid
      className="filterInputHover"
      style={props.style}
    />
  );
}

import type { Action, ActionCreator, Dispatch } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import { startAjaxCall } from './ajaxStatusActions';
import { FETCH_TEMPLATES_SUCCESS, FETCH_TEMPLATES_FAILURE } from './index';
import TemplatesApi from '../api/TemplatesApi';

export const loadTemplatesSuccess = (templates: any[]) => {
  return { type: FETCH_TEMPLATES_SUCCESS, payload: {templates} };
};
export const loadTemplatesFailure = (templates: any[]) => {
  return { type: FETCH_TEMPLATES_FAILURE, payload: {templates} };
};

export const fetchTemplates: ActionCreator<ThunkAction<Promise<any>, any, any, Action<any>>> = () => {
  return (dispatch: Dispatch) => {
    dispatch(startAjaxCall({ name: 'FETCH_TEMPLATES' }));
    return TemplatesApi.getTemplates()
      .then((templates) => {
        dispatch(loadTemplatesSuccess(templates));
      })
      .catch((error) => {
        console.error('Failed to fetch all templates', error);
        dispatch(loadTemplatesFailure(error));
      });
  };
};

import { createSelector } from '@reduxjs/toolkit';

import { TemplateType } from 'src/types/Template';

import type { State } from 'src/types/initialState';
import type { Widget } from 'src/types/TicketType';

export const selectActiveTicket = createSelector(
  (state: State) => state.detailedTickets,
  (state: State) => state.activeTicketTab,
  (detailedTickets, acitveTicketTab) => detailedTickets.find((ticket) => ticket.id === acitveTicketTab)
);

export const selectActiveTicketType = createSelector(
  (state: State) => state.detailedTickets,
  (state: State) => state.activeTicketTab,
  (state: State) => state.ticketTypes,
  (detailedTickets, activeTicketTab, ticketTypes) => {
    const activeTicket = detailedTickets.find((ticket) => activeTicketTab === ticket.id);
    const taskTypeName = activeTicket?.taskType;
    return ticketTypes.find((tt) => tt.name === taskTypeName);
  }
);

export const selectRescheduleWidgetOptions = createSelector(
  selectActiveTicketType,
  (ticketType) =>
    (ticketType?.widgetOrder.find((widget) => (widget as Widget).name === 'RescheduleCallback') as Widget)?.options
      ?.values
);

export const selectContentTemplates = createSelector(
  (state: State) => state.templates.templates,
  (allTemplates) => allTemplates.filter((template) => template.type === TemplateType.CONTENT)
);

export const selectActiveContentTemplates = createSelector(selectContentTemplates, (allTemplates) =>
  allTemplates.filter((template) => template.active)
);

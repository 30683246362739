import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import type { CSSProperties } from 'react';

import { iconName } from 'src/Utilities/iconName';
import type { TicketDirection } from 'src/types/Ticket';
import type { Channel } from 'src/types/Channel';

interface CommentProps {
  channel: Channel | undefined;
  style: CSSProperties;
  originalDirection: TicketDirection;
}

const CommentIcon = (props: CommentProps) => {
  const name = iconName(props.channel, props.originalDirection);

  return <Icon name={name} className={'icon-medium'} style={props.style} />;
};

export default CommentIcon;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';

import type { VFC } from 'react';
import type { ConnectedProps } from 'react-redux';

import FloatingWindowWrapper from '../FloatingWindowWrapper';
import { updateTicketTabState } from 'src/actions/draftActions';
import { roomSubscriberClosed } from 'src/actions/middlewareActions';
import {
  onCommentSubmit,
  updateChatTypingStatusToCustomer,
  updateGiosgWritingStatus,
  updateTicket
} from 'src/actions/ticketsActions';
import { setUserChatAnchorStatus } from 'src/actions/userActions';
import { ticketStopWorkingOn } from 'src/actions/workStatusActions';
import TicketsApi from 'src/api/TicketsApi';
import { channelTypeToName } from 'src/Components/CommentIconContent/ChannelType';
import Comments from 'src/Components/Comments/Comments';
import { TICKET_WINDOW_WIDTH } from 'src/Components/generic/FloatingWindow/constants';
import ReplyChat from 'src/Components/ReplyMethods/ReplyChat';
import ReplyGiosg from 'src/Components/ReplyMethods/ReplyGiosg';
import ErrorBoundary from 'src/ErrorBoundary';
import useDeepCompareEffect from 'src/hooks/useDeepCompareEffect';
import SocketInstance from 'src/realTimeNotifications';
import { updateFloatingWindow } from 'src/reducers/floatingWindowsReducer';
import { ChannelType } from 'src/types/Channel';
import { getPrefixByType } from 'src/Utilities/helper';
import { checkMandatoryFieldsForTicketClosing } from 'src/Utilities/restrictions';
import { stopWorkingOn } from 'src/Utilities/workStatusParser';

import type { Draft } from 'src/reducers/draftsReducer';
import type { FloatingWindowState } from 'src/types/FloatingWindow';
import type { State } from 'src/types/initialState';
import type { TicketStatusAfterCommentSubmit } from 'src/types/Salesforce';
import type { Ticket } from 'src/types/Ticket';

interface TicketWindowProps extends ConnectedProps<typeof connector> {
  index: number;
  window: FloatingWindowState;
}

const TicketWindow: VFC<TicketWindowProps> = ({
  tags,
  index,
  users,
  userData,
  templates,
  mobileMode,
  window: win,
  ticketTypes,
  draftsState,
  detailedTickets,

  updateTicket,
  ticketStopWorkingOn,
  updateGiosgWritingStatus,
  setUserChatAnchorStatus,
  updateChatTypingStatusToCustomer
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [ticket, setTicket] = useState<Ticket | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    SocketInstance.joinRoom(win.id);
    if (typeof win.data?.id === 'string') {
      setTicket(win.data);
      return;
    }

    setIsLoading(true);
    TicketsApi.getTicket(win.id, { type: getPrefixByType('task') })
      .then((t) => {
        setTicket(t);
        dispatch(updateFloatingWindow({ id: win.id, data: t }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [win.id]);

  const detailedTicket = detailedTickets.find((t) => t.id === ticket?.id);
  useDeepCompareEffect(() => {
    if (ticket && detailedTicket?.comments) {
      setTicket({ ...ticket, comments: detailedTicket.comments });
    }
  }, [detailedTicket?.comments]);

  const activeTicketType = ticketTypes.find((t) => t.name === ticket?.taskType);
  const drafts = (draftsState[ticket?.id ?? ''] as Draft | undefined)?.chat;
  const canCloseAsDone = useMemo(() => {
    if (!ticket || ticket?.id === 'NEW') {
      return false;
    }
    const { status } = checkMandatoryFieldsForTicketClosing(ticket, ticketTypes, tags);

    return status === 'OK';
  }, [ticket, ticketTypes, tags]);

  const changeTicketStatus = useCallback(
    async (id: string, UID: string | undefined, status: TicketStatusAfterCommentSubmit) => {
      await updateTicket(id, { status }, true);

      if (UID !== undefined) {
        await ticketStopWorkingOn(...stopWorkingOn(UID, id));
      }
      dispatch(roomSubscriberClosed(id));
    },
    []
  );

  const onSubmit = useCallback(
    (body) => {
      if (ticket) {
        return dispatch(onCommentSubmit(ticket, body));
      }

      return Promise.resolve();
    },
    [ticket]
  );
  const updateState = useCallback(
    (_taskId, _channel, state) => {
      if (ticket) {
        const channelType = channelTypeToName(ticket.channel);
        dispatch(updateTicketTabState(ticket?.id, { [channelType]: state }));
      }
    },
    [ticket?.id, ticket?.channel]
  );
  const onClose = useCallback(() => {
    dispatch(roomSubscriberClosed(win.id));
  }, [win.id]);

  const smallButtons = (win.width ?? 0) < 700;
  const extraContent = activeTicketType ? (
    <div style={{ maxHeight: '350px', overflowY: 'auto' }}>
      {ticket?.channel === ChannelType.Chat && (
        <ReplyChat
          smallButtons={smallButtons}
          task={ticket}
          drafts={drafts}
          taskId={ticket?.id}
          userData={userData}
          templates={templates}
          ticketTypes={ticketTypes}
          entities={ticket?.entities}
          ticketType={activeTicketType}
          onSubmit={onSubmit}
          changeTicketStatus={changeTicketStatus}
          updateState={updateState}
          canCloseAsDone={canCloseAsDone}
          setChatAnchor={setUserChatAnchorStatus}
          updateTypingStatusToCustomer={updateChatTypingStatusToCustomer}
        />
      )}
      {ticket?.channel === ChannelType.Giosg && (
        <ReplyGiosg
          smallButtons={smallButtons}
          task={ticket}
          users={users}
          drafts={drafts}
          taskId={ticket?.id}
          userData={userData}
          templates={templates}
          ticketTypes={ticketTypes}
          entities={ticket?.entities}
          ticketType={activeTicketType}
          canCloseAsDone={canCloseAsDone}
          onSubmit={onSubmit}
          changeTicketStatus={changeTicketStatus}
          updateState={updateState}
          switchChatAnchor={setUserChatAnchorStatus}
          updateGiosgWritingStatus={updateGiosgWritingStatus}
        />
      )}
    </div>
  ) : undefined;

  return (
    <FloatingWindowWrapper
      index={index}
      window={win}
      onClose={onClose}
      minConstraints={[TICKET_WINDOW_WIDTH, 480]}
      extraContent={extraContent}
    >
      <ErrorBoundary>
        {isLoading && !ticket && (
          <Dimmer active>
            <Loader>{t('LOADING')}</Loader>
          </Dimmer>
        )}
        {ticket && !isLoading && activeTicketType && (
          <Comments ticket={ticket} senderEmails={ticketTypes?.map((tt) => tt.senderemail)} mobileMode={mobileMode} />
        )}
      </ErrorBoundary>
    </FloatingWindowWrapper>
  );
};

const connector = connect(
  (state: State) => ({
    tags: state.tags,
    userData: state.userData,
    draftsState: state.drafts,
    ticketTypes: state.ticketTypes,
    users: state.usersList.usersList,
    detailedTickets: state.detailedTickets,
    mobileMode: state.mobileReducer.mobileMode,
    templates: state.templates.responseTemplates
  }),
  {
    setUserChatAnchorStatus,
    updateChatTypingStatusToCustomer,
    updateGiosgWritingStatus,
    updateTicket,
    ticketStopWorkingOn
  }
);

export default connector(TicketWindow);

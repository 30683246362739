import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import type { VFC } from 'react';
import type { ConnectedProps } from 'react-redux';

import { updateTicketTabState } from 'src/actions/draftActions';
import AttachmentsView from 'src/Components/Attachments/AttachmentsView';
import AccordionHeader from 'src/Components/Case/AccordionHeader';
import { channelTypeToName } from 'src/Components/CommentIconContent/ChannelType';
import { Channels } from 'src/types/Channel';
import { parseContent } from 'src/Utilities/parseUtils';
import { onResponseTemplatePrompt } from 'src/Utilities/replyContent';
import { removeHTMLTags, sanitizeHTML } from 'src/Utilities/sanitize';
import { nonHTMLChannels } from 'src/Utilities/templates';
import FloatingWindowWrapper from './FloatingWindowWrapper';
import type { FloatingWindowState } from 'src/types/FloatingWindow';
import type { State } from 'src/types/initialState';
import type { Draft } from 'src/reducers/draftsReducer';

interface SuggestedArticleWindowProps extends ConnectedProps<typeof connector> {
  index: number;
  window: FloatingWindowState;
}

const SuggestedArticleWindow: VFC<SuggestedArticleWindowProps> = ({
  index,
  window: win,
  user,
  userData,
  contentId,
  draftContent,
  selectedChannelTab
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const attachmentsCount = win.data?.attachments.filter((att) => !att['deprecated']).length ?? 0;

  const useForReply = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();

      let newContent: string = parseContent(win.content ?? '', win.data?.attachments ?? [], true);

      if (draftContent) {
        const update = await onResponseTemplatePrompt({
          update: { content: newContent },
          originalContent: draftContent,
          insertAtCursor: false,
          tr: t
        });

        if (update?.content) {
          newContent = update.content;
        }
      }

      newContent = nonHTMLChannels.includes(selectedChannelTab) ? removeHTMLTags(newContent) : sanitizeHTML(newContent);
      dispatch(updateTicketTabState(contentId, { [selectedChannelTab]: { content: newContent } }));
    },
    [draftContent, selectedChannelTab, contentId]
  );

  return (
    <FloatingWindowWrapper
      index={index}
      window={win}
      extraContent={
        <>
          <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <AccordionHeader
              as="h4"
              active={true}
              title={`${t('CASE_TITLE_ATTACHMENTS')} (${attachmentsCount})`}
              icon="attach"
            >
              <AttachmentsView
                user={user}
                attachments={win.data?.attachments ?? []}
                personalData={userData}
                dropZoneEnabled={false}
              />
            </AccordionHeader>
          </div>
          <div style={{ paddingTop: '10px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button icon labelPosition="left" onClick={useForReply}>
              <Icon name="reply" />
              {t('USE_FOR_REPLY')}
            </Button>
          </div>
        </>
      }
    >
      <div
        dangerouslySetInnerHTML={{
          __html: sanitizeHTML(win.content || '')
        }}
      />
    </FloatingWindowWrapper>
  );
};

const connector = connect((state: State) => {
  const contentId = state.activeTicketTab!;

  const draft = state.drafts[contentId] as Draft | undefined;
  let selectedChannelTab = channelTypeToName(
    draft?.tabIndex ?? state.detailedTickets.find((t) => t.id === state.activeTicketTab)!.channel
  );
  if (selectedChannelTab === Channels.secureMail) {
    selectedChannelTab = Channels.email;
  }
  const draftContent: string = draft?.[selectedChannelTab]?.content ?? '';

  return {
    contentId,
    draftContent,
    selectedChannelTab,
    userData: state.userData,
    user: state.usersList.usersList.find((u) => u.UID === state.userData.UID)!
  };
});

export default connector(SuggestedArticleWindow);

import { Category } from '../types/Category';
import {
  FETCH_CATEGORIES_SUCCESS,
  PATCH_CATEGORY_SUCCESS,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS
} from '../actions/index';

const initialState: { categories: Category[] } = {
  categories: []
};

const categoriesReducer = (
  state = initialState.categories,
  action: { type: string; categories: Category[]; category: Category; categoryId: number }
) => {
  switch (action.type) {
    case FETCH_CATEGORIES_SUCCESS: {
      return action.categories;
    }
    case PATCH_CATEGORY_SUCCESS: {
      return [...state.filter((category: Category) => category.id !== action.category.id), action.category];
    }
    case CREATE_CATEGORY_SUCCESS: {
      return [...state, action.category];
    }
    case DELETE_CATEGORY_SUCCESS: {
      return [...state.filter((category: Category) => category.id !== action.categoryId)];
    }
    default:
      return state;
  }
};

export default categoriesReducer;

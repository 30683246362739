import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppThunkDispatch } from 'src/store';
import { Icon, Button } from 'semantic-ui-react';

import { generateTranscriptionsSummary } from 'src/actions/openAIActions';

interface GenerateTagsProps {
  id: number;
}

export default (props: GenerateTagsProps) => {
  const { t } = useTranslation();
  const dispatchThunk = useAppThunkDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerateSummary = async () => {
    if (!isLoading) {
      setIsLoading(true);
      await dispatchThunk(
        generateTranscriptionsSummary({
          contentId: props.id
        })
      );
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Button
        disabled={isLoading}
        onClick={() => handleGenerateSummary()}
        primary
        icon
        labelPosition="left"
        style={{ display: 'block', marginTop: '10px' }}
      >
        {isLoading ? <Icon name="circle notch" loading /> : <Icon name="phone" />}
        {t('widgets.chatGPT.buttons.generate_transcriptions_summary')}
      </Button>
    </div>
  );
};

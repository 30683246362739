import React from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import AccordionHeader from '../../AccordionHeader';
import SurveyAnswer from './SurveyAnswer';
import UserLabel from 'src/Components/User/UserLabel';
import { DATE_TIME_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import type { SurveyQuestionAnswer } from 'src/types/Survey';

import './Survey.css';

interface SurveyProps {
  surveyIndex: number;
  quote: string;
  answers: SurveyQuestionAnswer[];
  sendAt: number;
  sendTo: string | null;
  answeredAt: number;
  complete: boolean;
  handledByUserId: number | null;
  originalContactChannel: number;
  sendMethod: 'sms' | 'email' | 'external' | 'unknown';
}

const Survey = ({
  surveyIndex,
  quote,
  answers,
  sendAt,
  sendTo,
  answeredAt,
  handledByUserId,
  complete
}: SurveyProps) => {
  const { t } = useTranslation();

  return (
    <AccordionHeader
      key={surveyIndex}
      as="h4"
      active={true}
      title={`${t('CASE_SURVEY')} ${surveyIndex}`}
      icon="envelope"
    >
      {handledByUserId !== null && (
        <div>
          <strong>{t('SURVEY_WIDGET_HANDLED_BY_UID')}:</strong>
          <br />
          <UserLabel UID={handledByUserId} />
        </div>
      )}
      <div className={`${complete ? 'hidden' : 'info'}`}>
        <Icon name="hourglass half" size="small" />
        {t('CASE_SURVEY_PENDING')}
      </div>
      <div className={`${complete ? '' : 'hidden'}`}>
        {answers.map((a, index) => (
          <SurveyAnswer key={index} question={a.question} answer={a.answer} />
        ))}
      </div>
      <div className={`${complete && quote ? 'quote' : 'hidden'}`}>
        <Icon name="quote right" size="small" />
        {quote}
      </div>
      <div className={`${complete ? '' : 'hidden'}`}>
        <strong>{t('CASE_SURVEY_ANSWERED_AT')}:</strong> {formatDate(answeredAt)}
      </div>
      <div>
        <strong>{t('CASE_SURVEY_SENT_AT')}:</strong> {formatDate(sendAt)}
      </div>
      <div>
        {sendTo && (
          <span>
            <strong>{t('CASE_SURVEY_SENT_TO')}:</strong> {sendTo}
          </span>
        )}
      </div>
    </AccordionHeader>
  );
};

const formatDate = (date: number): string => getPrettyDate(date, { format: DATE_TIME_FORMAT });

export default Survey;

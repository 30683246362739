import { get, isEmpty, map } from 'lodash';

import { Direction } from 'src/types/Sorting';
import type { Comment, CommentMetadata, Ticket, TicketDirection } from 'src/types/Ticket';
import type { Channel } from 'src/types/Channel';
import type { User } from 'src/types/User';

export const getCommentAttachmentsIds = (
  commentMetadata: CommentMetadata | null | undefined,
  task: Ticket
): string[] => {
  const source = !isEmpty(get(commentMetadata, ['originalTicketData', 'data', 'attachments']))
    ? get(commentMetadata, ['originalTicketData', 'data', 'attachments'])
    : get(commentMetadata, ['attachments']);
  const commentAttachments = map(source, (att) => att.filename || att.fileName);

  return task.attachments
    .filter((attachment) => !attachment.deprecated && commentAttachments.includes(attachment.fileName))
    .map((attObj) => attObj.id);
};

export const getCommentSender = ({
  entityData,
  channelData,
  user,
  metaData,
  direction
}: {
  entityData: any;
  channelData: Channel | undefined;
  user: User;
  metaData: CommentMetadata | null | undefined;
  direction: TicketDirection | undefined | null;
}): string => {
  if (metaData?.bot?.name) {
    return metaData.bot.name;
  }

  switch (channelData?.channel) {
    case 'email':
      if (entityData && direction === 'in') {
        return entityData.email;
      } else if (!entityData && direction === 'in') {
        return get(metaData, ['from', 0]);
      } else {
        return `${((user || {}).profile || {}).firstName || ''} ${((user || {}).profile || {}).lastName || ''}`;
      }
    case 'webform':
      if (entityData && direction === 'in') {
        return entityData.email;
      } else {
        return `${((user || {}).profile || {}).firstName || ''} ${((user || {}).profile || {}).lastName || ''}`;
      }
    case 'whatsapp':
      if (metaData && metaData.name) {
        return `${metaData.name}`;
      }
  }
  return `${((user || {}).profile || {}).firstName || ''} ${((user || {}).profile || {}).lastName || ''}`;
};

export const getLastComment = (
  comments: Comment[] = [],
  onlyExternal = false,
  direction: Direction = Direction.DESC
): Comment | null => {
  const sortedComments = direction === Direction.DESC ? [...comments].reverse() : comments;

  if (onlyExternal) {
    return sortedComments.find((comment) => comment.direction === 'in') || null;
  }

  return sortedComments[0] || null;
};

import React from 'react';
import { useTranslation } from 'react-i18next';

import DataToContent from './DisplayTicketData';
import EmptyContent from './EmptyContent';
import type ChannelType from './ChannelType';
import type { Channel } from 'src/types/Channel';
import type { FieldSet, TicketType } from 'src/types/TicketType';

export interface InternalContentProps {
  channel: ChannelType;
  metaData: null | { [x: string]: any };
  ticketTypes: TicketType[];
  channels: Channel[];
}

function InternalContent(props: InternalContentProps) {
  const { t } = useTranslation();

  if (props?.metaData == null) {
    return EmptyContent(props);
  }

  const data = props.metaData?.originalTicketData?.data;

  const getter = (type: TicketType, fields: FieldSet[]) => {
    if (data?.entitiesData?._type) {
      return fields.find((fSet: any) => {
        return fSet.id === 'customerInfo' && fSet?.entityTypes?.includes(data.entitiesData._type);
      });
    }

    return fields.find((fSet: any) => {
      return fSet.id === 'customerInfo';
    });
  };

  return (
    <div>
      <b>{t('COMMENT_METADATA_ORIGINAL_DATA')}</b>
      <hr />
      <hr />
      <br />
      <div>
        {data ? (
          <div className={'comment-icon-original-ticket-content-container'}>
            <DataToContent
              taskType={data.taskData?.taskType}
              origTicketData={data}
              ticketTypes={props.ticketTypes}
              channels={props.channels}
              fieldGetter={getter}
            />
          </div>
        ) : (
          <div>...</div>
        )}
      </div>
    </div>
  );
}

export default InternalContent;

import { connect } from 'react-redux';
import type { AnyAction } from 'redux';
import type { History } from 'history';
import type { ThunkDispatch } from 'redux-thunk';

import TabBar from '../TabBar';
import { defaultTask } from '../api/Task';
import { activateTab, closeTab } from '../actions/tabActionsRTK';
import {
  fetchTickets,
  createContent,
  activateTicket,
  deActivateTicket,
  scrollCaseBottomInstantly
} from '../actions/ticketsActions';
import { CREATE_INFO_PAGE, CREATE_TICKET } from 'src/actions';
import { StaticTabs } from 'src/types/TicketList';
import { defaultInfoPage } from 'src/api/InfoPage';
import { roomSubscriberClosed } from 'src/actions/middlewareActions';
import type { ContentTypesFields } from 'src/types/Ticket';
import type { State } from 'src/types/initialState';
import type { Template, TemplateContentJson } from 'src/types/Template';

interface IProps {
  contentType: ContentTypesFields;
}

const mapStateToProps = (state: State, ownProps: IProps) => {
  return {
    user: state.usersList.usersList.find((user) => user.UID === state.userData.UID),
    tabs: state.ticketTabs.filter((tab) => tab.type === ownProps.contentType),
    personalData: state.userData,
    ticketTypes: state.ticketTypes,
    tickets: state.detailedTickets,
    isCreateTabInProgress: state.ajaxCallsInProgress.some((ajaxCall) =>
      [CREATE_TICKET, CREATE_INFO_PAGE].includes(ajaxCall.name)
    )
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: IProps) => {
  const onCreateNewContent: (
    defaultTaskType: string,
    history: History,
    template?: Template<TemplateContentJson>
  ) => void = async (defaultTaskType: string, history: History, template?: Template<TemplateContentJson>) => {
    let newContent =
      ownProps.contentType === 'tickets' ? defaultTask(defaultTaskType) : defaultInfoPage(defaultTaskType);
    newContent.direction = null as unknown as string;

    if (template?.template) {
      const { title, content, ticketType, priority, channel, tags } = template.template;

      newContent = { ...newContent, title, content, tags };
      newContent.taskType = ticketType;
      if (typeof channel === 'number' && !isNaN(channel)) {
        newContent.channel = channel;
      }
      if (typeof priority === 'number' && !isNaN(priority)) {
        newContent['priority'] = priority;
      }
    }

    await dispatch(createContent(newContent, true, ownProps.contentType));
  };

  return {
    method: ownProps.contentType,
    onCreateNewContent,
    onTabClose: (id: string, activeId?: string) => {
      dispatch(deActivateTicket(id));
      if (activeId) {
        dispatch(activateTicket(activeId));
        dispatch(activateTab(activeId));
      }
      dispatch(closeTab(id));
      dispatch(roomSubscriberClosed(id));
    },
    onActivateTab: (id: string) => {
      dispatch(activateTicket(id));
      dispatch(activateTab(id));
    },
    scrollCaseBottomInstantly: () => {
      dispatch(scrollCaseBottomInstantly());
    },
    refreshMainView: () => {
      // TODO typing
      (dispatch as ThunkDispatch<Promise<any>, any, AnyAction>)(fetchTickets({}, StaticTabs.MAIN_VIEW, false));
    }
  };
};

const TicketTabBarContainer = connect(mapStateToProps, mapDispatchToProps)(TabBar);

export default TicketTabBarContainer;

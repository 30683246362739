import * as React from 'react';
import { List } from 'semantic-ui-react';
import { Ticket } from 'src/types/Ticket';
import { Link } from 'src/types/Widgets';

interface ExternalLinksListProps {
  links: Link[];
  task: Ticket;
}

class ExternalLinksList extends React.Component<ExternalLinksListProps> {
  searchValueFromObject(searchedObject: any, parameterArray: string[]) {
    parameterArray.forEach((parameterKey: string) => {
      if (searchedObject !== undefined) {
        searchedObject = searchedObject[parameterKey];
      } else {
        return searchedObject;
      }
    });
    return searchedObject;
  }

  checkForAndAssignParameters(href: string, params: object | undefined) {
    if (params !== undefined) {
      const paramsKeys = Object.keys(params);
      paramsKeys.forEach((paramKey) => {
        const regexString = '{{' + paramKey + '}}';
        href = href.replace(
          new RegExp(regexString, 'gi'),
          this.searchValueFromObject(this.props.task, params[paramKey].split('.'))
        );
      });
    }
    return href;
  }

  render() {
    return (
      <List divided={true} selection={true} relaxed={true}>
        {this.props.links.map((link, index) => {
          return (
            <List.Item
              key={index}
              icon="external"
              content={link.text}
              onClick={() => {
                window.open(this.checkForAndAssignParameters(link.href, link.params), '_blank');
              }}
            />
          );
        })}
      </List>
    );
  }
}

export default ExternalLinksList;

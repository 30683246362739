import FeatureFlags from 'src/api/FeatureFlags';
import type { TabFilter } from './Filter';
import type { LanguageType } from './Language';

export type VisibilityTypes = 'available' | 'away' | 'busy' | 'offline' | 'online';

export type SignInType = 'password' | 'SSO' | 'link';
export interface Profile {
  id: string;
  profileImage: string;
  avatarColor?: string;
  email: string;
  firstName: string;
  lastName: string;
  chatAlias?: string;
  phoneWork: string;
  phoneHome: string;
  title: string;
  unit: string;
  description: string;
  skills: string;
  defaultTicketType: number;
  defaultFilterPreset?: number | null;
  defaultLanguage: LanguageType;
  hideFromDelegation: 0 | 1;
  chatAnchored: boolean | null;
}

export interface User {
  showPersonalQuery: boolean;
  UID: string;
  loginName: string;
  visibility: VisibilityTypes;
  loginAlias: string | null;
  skillTags: any[];
  profile: Profile;
  role: Role;
  ticketTypes: number[];
  relationCount: RelationCount;
  organisationalRoleTags: any[];
  organisationalUnitTags: any[];
  usesMobile: boolean;
  channels: number[];
  aliasName: string | null;
  forbiddenLoginStrategies: LoginStrategy[];
  type: 'normal' | 'bot';
}

export const LoginStrategyValues = ['local'] as const;
export type LoginStrategy = typeof LoginStrategyValues[number];

export type UserCustomTicketOrdering = {
  fixPerTicketType: {
    id: number;
    ticketTypeId: number;
    ticketTypeName: string;
    UID: number;
    duedate_fix: number;
  }[];
};

export interface PersonalData {
  UID: string;
  visibility: VisibilityTypes;
  language: string;
  profile: Profile;
  environments: any;
  channels: number[];
  ticketTypes: number[];
  chatAnchored: boolean;
  permissions: string[];
  userPreferences: UserPreferences;
  userChannelNotifications: NotificationRule[];
  userCustomTicketOrdering: UserCustomTicketOrdering;
}

export interface NotificationRule {
  id: number;
  user: number;
  name: string;
  delay: number;
  created: number;
  modified: number;
  active: number;
  conditions: TabFilter;
  soundConfig: NotificationRuleSoundConfig;
  popupConfig: NotificationRulePopupConfig;
  isRunOnce: number;
}

export interface NotificationRuleSoundConfig {
  active: number;
  timeoutInSec: number;
}

export interface NotificationRulePopupConfig {
  active: number;
  timeoutInSec: number;
  title: string;
  content: string;
}

export interface UserPreferences {
  defaultTicketType: number;
  defaultFilterPreset?: number | null;
  defaultLanguage: string;
  accordionsClosed?: boolean;
}

export interface RelationCount {
  all: number;
  isDelegatedTo: number;
  edited: number;
  created: number;
  commented: number;
}

export interface Role {
  id: string;
  name: string;
  type: string;
}

export class Roles {
  static readonly Admin = new Roles(1, 'Admin', 'ROL1');
  static readonly Supervisor = new Roles(2, 'Supervisor', 'ROL2');
  static readonly Blocked = new Roles(3, 'Blocked', 'ROL3');
  static readonly User = new Roles(4, 'User', 'ROL4');
  static readonly Limited = new Roles(5, 'Limited', 'ROL5');
  static readonly Delegated = new Roles(6, 'Delegated', 'ROL6');
  static readonly DelegatedStrict = new Roles(7, 'DelegatedStrict', 'ROL7');
  static readonly ReadOnly = new Roles(8, 'ReadOnly', 'ROL8');
  static readonly ReadOnlySingleTicker = new Roles(9, 'ReadOnlySingleTicket', 'ROL9');
  static readonly SuperAdmin = new Roles(10, 'superAdmin', 'ROL10');

  private constructor(readonly id: number, readonly name: string, readonly rolName: string) {
    this.dbName = name.charAt(0).toLowerCase() + name.slice(1);
  }

  dbName: string;

  static isChatAnchored(user: any): boolean {
    if (user.profile.chatAnchored === null) {
      return FeatureFlags.isFlagOn('CHAT_ANCHOR_TICKET_BY_DEFAULT');
    } else {
      return user.profile.chatAnchored;
    }
  }

  static idToNumber(id: number | string): number {
    if (typeof id === 'number') {
      return id;
    }

    if (id.toUpperCase().startsWith('ROL')) {
      id = id.substring(3);
    }

    return Number(id);
  }

  static idsEqual(a: number | string, b: number | string) {
    a = Roles.idToNumber(a);
    b = Roles.idToNumber(b);

    return a === b;
  }

  static isAnyOf(original: number | string, ...others: (number | string | Roles)[]): boolean {
    for (let other of others) {
      if (other instanceof Roles) {
        other = other.id;
      }

      if (this.idsEqual(original, other)) {
        return true;
      }
    }

    return false;
  }

  static isAdminLike(role: number | string): boolean {
    return Roles.isAnyOf(role, Roles.Admin, Roles.Supervisor, Roles.SuperAdmin);
  }
}

export interface PasswordPolicy {
  passwordLength: [number, number];
  requirements: {
    smallLetters: boolean;
    bigLetters: boolean;
    specialChars: boolean;
    numbers: boolean;
    howManyRequirementsMustBeFulfilled: number;
  };
}

import i18n, { t } from 'i18next';
import iziToast from 'izitoast';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { GENERATE_TAGS_SUCCESS } from '.';
import OpenAIApi from 'src/api/OpenaiApi';
import { startAjaxCall } from './ajaxStatusActions';

export const generateTagsSuccess = createAction<{ contentId: number; generatedTags: string[] }>(GENERATE_TAGS_SUCCESS);
export const generateTranscriptionsSummarySuccess = createAction<{ contentId: number; summary: string }>(
  'GENERATE_TRANSCRIPTIONS_SUMMARY_SUCCESS'
);

export const generateTicketRelevantInfo = createAsyncThunk<
  void,
  { contentId: number; content: string; isHtml?: boolean; commentId?: number },
  { rejectValue: Error }
>('generateTicketRelevantInfo', async ({ contentId, content, isHtml, commentId }, { dispatch }) => {
  try {
    await OpenAIApi.generateTicketRelevantInfo({
      contentId,
      content,
      language: i18n.language,
      isHtml,
      ...(commentId && { commentId })
    });
    iziToast.success({
      title: t('OK'),
      icon: 'icon check',
      message: t('widgets.chatGPT.toasts.rel_info_generated'),
      timeout: 2500
    });
  } catch (error) {
    iziToast.error({
      title: t('ERROR_HAPPENED'),
      message: t('widgets.chatGPT.toasts.rel_info_failed'),
      position: 'bottomRight',
      timeout: 2500,
      icon: 'delete'
    });
  }
});

export const generateTags = createAsyncThunk<
  void,
  { contentId: number; content: string; isHtml?: boolean; commentId?: number },
  { rejectValue: Error }
>('generateTags', async ({ contentId, content, isHtml, commentId }, { dispatch }) => {
  dispatch(startAjaxCall({ name: 'GENERATE_TAGS', id: contentId }));
  try {
    const response = await OpenAIApi.generateTags({
      contentId: contentId,
      content: content,
      isHtml: isHtml,
      ...(commentId && { commentId })
    });
    dispatch(generateTagsSuccess({ contentId, generatedTags: response.tags }));
    iziToast.success({
      title: t('OK'),
      icon: 'icon check',
      message: t('widgets.chatGPT.toasts.tags_generated'),
      timeout: 2500
    });
  } catch (error) {
    iziToast.error({
      title: t('ERROR_HAPPENED'),
      message: t('widgets.chatGPT.toasts.tags_failed'),
      position: 'bottomRight',
      timeout: 2500,
      icon: 'delete'
    });
  }
});

export const generateTranscriptionsSummary = createAsyncThunk<void, { contentId: number }, { rejectValue: Error }>(
  'generateTranscriptionsSummary',
  async ({ contentId }, { dispatch }) => {
    dispatch(startAjaxCall({ name: 'GENERATE_TRANSCRIPTIONS_SUMMARY', id: contentId }));
    try {
      const response = await OpenAIApi.generateTranscriptionsSummary({
        contentId: contentId
      });
      dispatch(generateTranscriptionsSummarySuccess({ contentId, summary: response.summary }));
      iziToast.success({
        title: t('OK'),
        icon: 'icon check',
        message: t('widgets.chatGPT.toasts.transcriptions_summary_generated.success'),
        timeout: 2500
      });
    } catch (error) {
      iziToast.error({
        title: t('ERROR_HAPPENED'),
        message: t('widgets.chatGPT.toasts.transcriptions_summary_generated.fail'),
        position: 'bottomRight',
        timeout: 2500,
        icon: 'delete'
      });
    }
  }
);

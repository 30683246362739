import React from 'react';
import { useTranslation } from 'react-i18next';

import EmptyContent from './EmptyContent';
import type ChannelType from './ChannelType';

// FIXME: should extend generic interface together with all other prosp
export interface PhoneContentProps {
  channel: ChannelType;
  metaData: null | { [x: string]: any };
  showSender?: boolean;
}

function PhoneContent(props: PhoneContentProps) {
  const { t } = useTranslation();

  if (props?.metaData == null) {
    return EmptyContent(props);
  }

  return (
    <div>
      <b>{t('PHONE')}</b>
      <hr />
      <div>
        <b>{t('COMMENT_METADATA_TARGETED_TO')}</b>
        {Array.isArray(props.metaData.to) ? (
          <ol>
            {props.metaData.to.map((to: string, index: number) => (
              <li key={`phone-content-to-${index}`}>{to}</li>
            ))}
          </ol>
        ) : (
          <div>...</div>
        )}
      </div>
      {props.showSender && (
        <div>
          <b>{t('COMMENT_METADATA_SENDER')}</b>
          {props.metaData.sender ? <ol>{props.metaData.sender}</ol> : <div>...</div>}
        </div>
      )}
    </div>
  );
}

export default PhoneContent;

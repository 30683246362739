import iziToast from 'izitoast';
import fileDownload from 'js-file-download';
import type { VFC } from 'react';
import React from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import type { DropdownItemProps, SemanticICONS } from 'semantic-ui-react';
import { Dropdown, Icon, List, Popup } from 'semantic-ui-react';
import ApiConfig from 'src/api/ApiConfig';

import { openAttachmentsPreview } from 'src/reducers/attachmentsReducer';
import type { Attachment } from 'src/types/Ticket';
import type { PersonalData } from 'src/types/User';
import { DATE_TIME_SECONDS_FORMAT, getPrettyDate } from 'src/Utilities/dates';
import { getAttachmentSize } from 'src/Utilities/getAttachmentSize';
import { apiClient } from 'src/Utilities/httpClients';
import { isLightboxType } from 'src/Utilities/lightbox';

interface AttachmentProps
  extends Pick<
    Attachment,
    'id' | 'uri' | 'size' | 'uploaded' | 'fileName' | 'deprecated' | 'previewUri' | 'isQuarantined'
  > {
  attachments: Attachment[];
  personalData: PersonalData;

  onEdit?(id: string, body: AttachmentEdit): void;
  onDeprecate?(id: string): void;
  onUnDeprecate?(id: string): void;
  openFileDialog?(event: React.MouseEvent<HTMLDivElement>, data: DropdownItemProps): void;
}

export interface AttachmentEdit {
  isQuarantined: boolean;
}

const AttachmentItem: VFC<AttachmentProps> = ({
  id,
  uri,
  size,
  uploaded,
  fileName,
  deprecated,
  previewUri,
  isQuarantined,

  attachments,
  personalData,

  onEdit,
  onDeprecate,
  onUnDeprecate,
  openFileDialog
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const editAttachment = () => {
    onEdit?.(id, { isQuarantined: !isQuarantined });
  };

  const deprecateAttachment = () => {
    onDeprecate?.(id);
  };

  const unDeprecateAttachment = () => {
    onUnDeprecate?.(id);
  };

  const getAttachmentLink = () => ApiConfig.getConfig().API_URL + '/file/' + uri;

  const getPreviewLink = () => ApiConfig.getConfig().API_URL + '/file/' + previewUri;

  const downloadAttachment = () => {
    apiClient.get(getAttachmentLink(), { responseType: 'blob', timeout: 0 }).then((res) => {
      fileDownload(res.data, fileName);
    });
  };

  const openInNewWindow = () => {
    window.open(getAttachmentLink(), '_blank')?.focus();
  };

  const proceedAttachment = () => {
    isLightboxType({ isQuarantined, uri })
      ? dispatch(openAttachmentsPreview({ firstAttachmentId: id, attachments }))
      : openInNewWindow();
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (isQuarantined && personalData.permissions.includes('getQuarantinedAttachments')) {
      iziToast.question({
        timeout: 20000,
        close: false,
        overlay: true,
        id: 'question',
        zindex: 999,
        backgroundColor: 'salmon',
        overlayClose: true,
        title: t('OPEN_ATTACHMENT_PROMPT_TITLE'),
        message: t('OPEN_ATTACHMENT_PROMPT_MESSAGE'),
        position: 'center',
        buttons: [
          [
            `<button><b>${t('YES')}</b></button>`,
            (instance, toast) => {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
              proceedAttachment();
            },
            true
          ],
          [
            `<button>${t('GENERAL_CANCEL')}</button>`,
            function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            },
            false
          ]
        ]
      });
    } else if (isQuarantined && !personalData.permissions.includes('accessQuarantineFiles')) {
      return iziToast.error({
        message: t('OPEN_ATTACHMENT_PROMPT_NO_ACCESS')
      });
    } else if (!isQuarantined) {
      proceedAttachment();
    }
  };

  const getFileIcon = (): SemanticICONS => {
    if (isQuarantined) {
      return 'ban';
    } else {
      let fileCategory = '';
      let fileType = '';
      if (fileType) {
        [fileCategory, fileType] = fileType.split('/');
      }
      switch (fileCategory.toUpperCase()) {
        case 'IMAGE':
          return 'file image outline';
        case 'TEXT':
          switch (fileType.toUpperCase()) {
            case 'PLAIN':
              return 'file text outline';
            default:
              return 'file code outline';
          }
        case 'ARCHIVE':
          return 'file archive outline';
        case 'AUDIO':
          return 'file audio outline';
        case 'VIDEO':
          return 'file video outline';
        case 'APPLICATION':
          switch (fileType.toUpperCase()) {
            case 'PDF':
              return 'file pdf outline';
            case 'VND.OPENXMLFORMATS-OFFICEDOCUMENT':
              return 'file word outline';
            default:
              return 'file outline';
          }
        default:
          return 'file outline';
      }
    }
  };

  const attachmentStatusIcon = deprecated ? 'add' : 'delete';
  const attachmentStatusLabelText = deprecated ? t('ATTACHMENT_ACTIVATE') : t('ATTACHMENT_DEPRECATE');
  const attachmentActiveOnClick = deprecated ? unDeprecateAttachment : deprecateAttachment;

  const attachmentQuarantineIcon = isQuarantined ? 'check circle' : 'dont';
  const attachmentQuarantineTextKey = isQuarantined ? 'ATTACHMENT_RELEASE_QUARANTINE' : 'ATTACHMENT_SET_QUARANTINE';

  return (
    <Translation ns="translations">
      {(tr) => (
        <List.Item key={id}>
          <List.Content floated="right">
            <Dropdown icon="chevron down" selectOnBlur={false} floating={true} button={true} className="icon">
              <Dropdown.Menu direction="left">
                <Dropdown.Item disabled={true} icon="edit" text={tr('ATTACHMENT_EDIT')} />
                {isLightboxType({ uri, isQuarantined }) && (
                  <Dropdown.Item
                    icon="window restore"
                    text={tr('ATTACHMENT_OPEN_IN_NEW_TAB')}
                    onClick={openInNewWindow}
                  />
                )}
                <Dropdown.Item icon="download" text="Download" onClick={downloadAttachment} />
                {typeof onDeprecate === 'function' && typeof onUnDeprecate === 'function' && (
                  <Dropdown.Item
                    icon={attachmentStatusIcon}
                    text={attachmentStatusLabelText}
                    onClick={attachmentActiveOnClick}
                  />
                )}
                {typeof onEdit === 'function' && (
                  <Dropdown.Item
                    icon={attachmentQuarantineIcon}
                    disabled={!personalData.permissions.includes('modifyQuarantineAttachments')}
                    text={tr(attachmentQuarantineTextKey)}
                    onClick={editAttachment}
                  />
                )}
                <Dropdown.Item
                  disabled={true}
                  icon="upload"
                  text={tr('ATTACHMENT_UPLOAD_NEW')}
                  onClick={openFileDialog}
                />
              </Dropdown.Menu>
            </Dropdown>
          </List.Content>

          <List.Icon
            name={getFileIcon()}
            style={{ color: isQuarantined ? 'red' : '' }}
            verticalAlign="middle"
            size="large"
          />

          <List.Content style={{ maxWidth: '50px' }}>
            <List.Header>
              <a
                style={{ wordWrap: 'break-word', color: isQuarantined ? 'red' : '' }}
                rel="noopener noreferrer"
                target="_blank"
                onClick={(e) => handleLinkClick(e)}
                href="#"
              >
                {`${fileName} `}
                <Icon size="small" name="external" />
              </a>

              <Popup
                on="hover"
                disabled={!previewUri}
                trigger={<Icon name="magnify" size="small" />}
                content={<img src={getPreviewLink()} />}
              />
            </List.Header>

            <List.Description>
              {getPrettyDate(uploaded, { format: DATE_TIME_SECONDS_FORMAT })}
              <span style={{ float: 'right', marginLeft: '10px' }}>{getAttachmentSize(size)}</span>
            </List.Description>
          </List.Content>
        </List.Item>
      )}
    </Translation>
  );
};

export default React.memo(AttachmentItem);
